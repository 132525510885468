/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { useMutation } from '@tanstack/react-query';

import { Node, UpdateBaselineResponse2 } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';

export type UpdateBaselineMutationConfig = {
  onSuccess?: (data: UpdateBaselineResponse2) => void;
  onError?: (error: unknown) => void;
  onSettled?: (data: unknown, error: unknown, variables: unknown) => void;
};

export function useUpdateBaselineMutation(tenantId: string, node: Node, config?: UpdateBaselineMutationConfig) {
  return useMutation<UpdateBaselineResponse2>({
    mutationFn: () => {
      return mgmtPlaneAPIClient.nodes.updateBaseline({ tenantId, id: node.id, requestBody: {} });
    },
    onSuccess: config?.onSuccess,
    onError: config?.onError,
    onSettled: config?.onSettled,
  });
}
