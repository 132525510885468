/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import { configSentry } from './sentry';

configSentry(
  window.mimicConfig.MIMIC_MGMT_PLANE_SENTRY_ENABLED === 'true',
  window.mimicConfig.MIMIC_MGMT_PLANE_SENTRY_DSN as string,
  window.mimicConfig.MIMIC_MGMT_PLANE_ENVIRONMENT as string,
  window.mimicConfig.MIMIC_MGMT_PLANE_RELEASE_NAME as string,
);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
