/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import * as Sentry from '@sentry/react';
import { Form, Input } from 'antd';
import { FocusEventHandler, useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { ConfigRevision, Node, NodeConfig } from '@/client';
import { BackButton } from '@/components/Buttons/BackButton';
import { ConfigWizardOverlay } from '@/components/ConfigWizardOverlay/ConfigWizardOverlay';
import { ContentContainer } from '@/components/ContentContainer';
import { Hallmarks } from '@/components/Node/Hallmarks';
import { IncomingData } from '@/components/Node/IncomingData';
import { LifecycleEvents } from '@/components/Node/LifecycleEvents';
import { NodeCredentials } from '@/components/Node/NodeCredentials';
import { NodeHeartbeats } from '@/components/Node/NodeHeartbeats';
import { NodeState } from '@/components/Node/NodeState';
import { NodeViewPageCard } from '@/components/Node/NodeViewPageCard';
import { NodeConfigState } from '@/components/NodeConfigState/NodeConfigState';
import { QueryWrapper } from '@/components/QueryWrapper';
import { useMessage } from '@/hooks/message';
import { useAssignConfigRevisionMutation } from '@/hooks/mutation/useAssignConfigRevisionMutation';
import { useUpdateNodeMutation } from '@/hooks/mutation/useUpdateNodeMutation';
import { useNodeByIdQuery } from '@/hooks/query/useNodeByIdQuery';
import { useAuthorization } from '@/hooks/useAuthorization';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { NodeDetailsHeader } from '@/pages/NodeDetails/NodeDetailsHeader';
import { Col } from '@/primitives/Col';
import { Collapse } from '@/primitives/Collapse';
import { Container } from '@/primitives/Container';
import { Flex } from '@/primitives/Flex';
import { Row } from '@/primitives/Row';
import { Skeleton } from '@/primitives/Skeleton';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { tokens } from '@/theme';

export type NodeDetailsPageProps = {
  nodeId: string;
};

export function NodeDetailsPage({ nodeId }: NodeDetailsPageProps) {
  const { t } = useMimicTranslation('node');
  const tenantID = useMgmtPlaneStateStore((state) => state.selectedTenantID);
  const [form] = Form.useForm();
  const [message] = useMessage();
  const [isDescriptionFocused, setIsDescriptionFocused] = useState(false);
  const [nodeToShow, setNodeToShow] = useState<Node | undefined>();
  const [selectedNodeConfig, setSelectedNodeConfig] = useState<NodeConfig | undefined>();
  const [selectedConfigRevision, setSelectedConfigRevision] = useState<ConfigRevision | undefined>();
  const nodeQuery = useNodeByIdQuery({ nodeId });

  const { mutate: assignConfigRevision } = useAssignConfigRevisionMutation({
    onError: (err) => {
      message.error(t('feedback.assignNodeConfigError'));
      Sentry.captureException(err);
    },
  });

  const canUpdateNodeDescription = useAuthorization('editor');

  useEffect(() => {
    if (isDescriptionFocused) return;
    form.setFieldValue('description', nodeQuery.data?.data.description);
  }, [isDescriptionFocused, nodeQuery.data?.data, form]);

  const { mutate } = useUpdateNodeMutation({
    nodeId,
    onSuccess: () => message.success(t('feedback.nodeUpdated')),
    onError: () => message.error(t('feedback.updateFailed')),
  });

  if (nodeQuery.isError) {
    if (nodeQuery.error.status === 404) {
      return <Navigate to={`/tenants/${tenantID}/nodes`} replace />;
    }
    throw nodeQuery.error;
  }

  if (!nodeQuery.data?.data) {
    return <Skeleton loading />;
  }

  const nodeTags = nodeQuery.data!.data.tags || [];

  const onDescriptionBlur: FocusEventHandler<HTMLTextAreaElement> = (event) => {
    setIsDescriptionFocused(false);
    if (event.target.value === null || event.target.value === undefined) return;
    const newDescription = event.target.value.trim();
    if (newDescription === nodeQuery.data!.data.description) return;
    mutate({ description: newDescription });
  };

  const onDescriptionFocus: FocusEventHandler<HTMLTextAreaElement> = () => {
    setIsDescriptionFocused(true);
  };

  const onNameChange = (newNodeName: string) => {
    mutate({ name: newNodeName });
  };

  if (!tenantID) return null;

  const onCloseConfigWizard = () => {
    setNodeToShow(undefined);
  };

  const onAssignConfigRevision = async (node: Node, configRevision: ConfigRevision) => {
    const currentConfigInUse =
      node.appliedRevisionState?.nodeConfig?.id === configRevision.nodeConfigId &&
      node.appliedRevisionState?.configRevision?.revisionNumber === configRevision.revisionNumber;

    if (currentConfigInUse) {
      message.error(t('feedback.currentConfigInUse'));
      return;
    }

    assignConfigRevision({
      nodeIDs: [node.id],
      configID: configRevision.nodeConfigId!,
      revisionNumber: configRevision.revisionNumber,
    });

    setNodeToShow(undefined);
  };

  const operatingSystemName = nodeQuery.data!.data.operatingSystem || '-';
  const operatingSystemVersion =
    nodeQuery.data!.data.systemProfile && nodeQuery.data!.data.systemProfile['os.version']
      ? nodeQuery.data!.data.systemProfile['os.version']
      : '';
  const operatingSystemDescription = `${operatingSystemName} ${operatingSystemVersion}`.trim();

  return (
    <ContentContainer width="100%">
      <Container>
        <BackButton to={`/tenants/${tenantID}/nodes`} />
        <QueryWrapper queryResult={nodeQuery}>
          {(nodeData) => (
            <>
              <NodeDetailsHeader
                nodeQuery={nodeQuery}
                nodeTags={nodeTags}
                tenantID={tenantID}
                onNameChange={onNameChange}
                nodeId={nodeId}
              />
              <Row gutter={16}>
                <Col span={24}>
                  <NodeState isLoading={nodeQuery.isPending} node={nodeData.data} />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <Collapse
                    data-testid="hallmark-collapse"
                    items={[
                      {
                        key: '1',
                        label: (
                          <div
                            style={{
                              fontSize: '1.125rem',
                              fontWeight: 700,
                              backgroundColor: tokens.color.surface.surfaceHigh,
                            }}
                          >
                            {t('hallmarks')}
                          </div>
                        ),
                        children: (
                          <div data-testid="hallmarks-section">
                            <Hallmarks nodeId={nodeId} />
                          </div>
                        ),
                      },
                    ]}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Flex vertical>
                    <NodeViewPageCard title={t('lifecycle_events')} dataTestId="lifecycle-events-section">
                      <LifecycleEvents nodeId={nodeId} />
                    </NodeViewPageCard>
                    <NodeViewPageCard title={t('node_description')}>
                      <Form form={form} initialValues={nodeData.data} layout="vertical">
                        <Form.Item name="description">
                          <Input.TextArea
                            allowClear
                            rows={4}
                            onBlur={onDescriptionBlur}
                            onFocus={onDescriptionFocus}
                            placeholder={t('node_description_placeholder')}
                            count={{ show: true, max: 280 }}
                            maxLength={280}
                            disabled={!canUpdateNodeDescription}
                            style={{
                              backgroundColor: tokens.color.surface.surfaceHigh,
                            }}
                          />
                        </Form.Item>
                      </Form>
                    </NodeViewPageCard>
                  </Flex>
                </Col>
                <Col span={12}>
                  <Flex vertical>
                    <NodeConfigState
                      node={nodeData.data}
                      assignNewNodeConfig={() => setNodeToShow(nodeData.data)}
                      assignNewConfigRevision={() => {
                        setSelectedNodeConfig(nodeData.data.appliedRevisionState?.nodeConfig as unknown as NodeConfig);

                        setSelectedConfigRevision({
                          ...nodeData.data.appliedRevisionState?.configRevision,
                          nodeConfigId: nodeData.data.appliedRevisionState?.nodeConfig?.id,
                        } as ConfigRevision);

                        setNodeToShow(nodeData.data);
                      }}
                      getNodeConfigPath={(node: Node) =>
                        `/tenants/${tenantID}/node-configs/${node.appliedRevisionState?.nodeConfig?.id}`
                      }
                      getNodeConfigRevisionPath={(node: Node) =>
                        `/tenants/${tenantID}/node-configs/${node.appliedRevisionState?.nodeConfig?.id}/revisions/${node.appliedRevisionState?.configRevision?.revisionNumber}`
                      }
                    />
                    <NodeViewPageCard title={t('server_configuration')}>
                      <Form layout="vertical">
                        <Row>
                          <Col span={12}>
                            <IncomingData
                              label={t('ip_address')}
                              loading={nodeQuery.isPending}
                              text={nodeData.data.systemProfile?.['host.ip']}
                              mono
                              copyable
                            />
                          </Col>
                          <Col span={12}>
                            <IncomingData
                              dataTestId="node-operating-system"
                              label={t('os')}
                              loading={nodeQuery.isPending}
                              text={operatingSystemDescription}
                              mono
                              copyable
                            />
                          </Col>
                        </Row>
                      </Form>
                    </NodeViewPageCard>
                    <NodeCredentials node={nodeData.data} isPending={nodeQuery.isPending} />
                    <NodeHeartbeats data={nodeData.data} isPending={nodeQuery.isPending} />
                  </Flex>
                </Col>
              </Row>
            </>
          )}
        </QueryWrapper>
        {nodeToShow ? (
          <ConfigWizardOverlay
            node={nodeToShow}
            defaultNodeConfig={selectedNodeConfig}
            defaultConfigRevision={selectedConfigRevision}
            onCancel={onCloseConfigWizard}
            onAssign={onAssignConfigRevision}
          />
        ) : null}
      </Container>

      <Outlet />
    </ContentContainer>
  );
}
