/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useMutation } from '@tanstack/react-query';

import { ApiError, CreateTag, TagResponse } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export type NewTagMutationConfig = {
  onSuccess?: (data: TagResponse, variables: CreateTag) => void;
  onError?: (error: unknown, variables: CreateTag) => void;
  onSettled?: (data: unknown, error: unknown, variables: unknown) => void;
  onMutate?: (variables: CreateTag) => Promise<unknown> | void;
};

/**
 * Hook for creating new tag through the management plane API.
 *
 * @param config.name - String of the name of the new tag
 * @param config.onSuccess - Called after successful tag creation
 * @param config.onError - Called if tag creation fails
 * @param config.onSettled - Called after mutation completes (success or failure)
 *
 * @returns React Query mutation result for creating new tag
 *
 * @example
 * ```tsx
 * const { mutate } = useNewTagMutation({
 *   onSuccess: (data) => console.log('Tag Created:', data),
 *   onError: (error) => console.error('Tag Created failed:', error)
 * });
 *
 * // Create Tag
 * mutate({ name: 'test-tag-0001' });
 * ```
 */

export function useNewTagMutation({ onSuccess, onError, onSettled, onMutate }: NewTagMutationConfig) {
  const tenantID = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  const { mutateAsync, mutate, isError, error } = useMutation<TagResponse, ApiError, CreateTag>({
    mutationFn: (newTag: CreateTag) => {
      return mgmtPlaneAPIClient.tags.createTag({ tenantId: tenantID!, requestBody: newTag });
    },
    onSuccess,
    onError,
    onSettled,
    onMutate,
  });

  return { mutateAsync, mutate, isError, error };
}
