/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import {
  CodeOutlined,
  DeploymentUnitOutlined,
  OneToOneOutlined,
  BookOutlined,
  BuildOutlined,
  ApiOutlined,
  BellOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';

import { buildMenu, MenuItemConfig, MenuProps } from '@/components/menus/builder';
import { canPerformAction } from '@/hooks/useAuthorization';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Menu } from '@/primitives/Menu';

export function MainMenu({ tenantID, currentUser, navigate, currentPath }: MenuProps) {
  const { t } = useMimicTranslation('menu');

  if (!currentUser) return null;

  const allMenuItems: MenuItemConfig[] = [
    {
      name: 'activity',
      icon: <OneToOneOutlined />,
      label: t('activity'),
      path: `/tenants/${tenantID}/activity`,
      dataTestId: 'activity-menu',
      requiredRole: 'viewer',
    },
    {
      name: 'nodes',
      icon: <DeploymentUnitOutlined />,
      label: t('nodes'),
      path: `/tenants/${tenantID}/nodes`,
      dataTestId: 'nodes-menu',
      requiredRole: 'viewer',
    },
    {
      name: 'configurations',
      icon: <BuildOutlined />,
      label: t('configurations'),
      path: `/tenants/${tenantID}/node-configs`,
      dataTestId: 'configurations-menu',
      requiredRole: 'superadmin',
    },
    {
      name: 'jobs',
      icon: <UnorderedListOutlined />,
      label: t('jobs'),
      path: `/tenants/${tenantID}/jobs`,
      dataTestId: 'jobs-menu',
      requiredRole: 'viewer',
    },
    {
      name: 'notifications',
      icon: <BellOutlined />,
      label: t('notifications'),
      path: `/tenants/${tenantID}/notifications`,
      dataTestId: 'notifications-menu',
      requiredRole: 'viewer',
    },
    {
      name: 'mimicCLI',
      icon: <CodeOutlined />,
      label: t('mimicCli'),
      dataTestId: 'mimic-cli-menu',
      items: [
        {
          name: 'download',
          label: t('download'),
          dataTestId: 'mimic-cli-download-menu',
          path: `/tenants/${tenantID}/mimic-cli/download`,
        },
        {
          name: 'authenticate',
          label: t('authenticate'),
          dataTestId: 'mimic-cli-authenticate-menu',
          path: `/tenants/${tenantID}/mimic-cli/authenticate`,
        },
      ],
      requiredRole: 'superadmin',
    },
    {
      name: 'docs',
      icon: <BookOutlined />,
      label: t('docs'),
      items: [
        {
          name: 'swagger',
          label: t('swagger'),
          icon: <ApiOutlined />,
          path: `/tenants/${tenantID}/docs/swagger`,
        },
      ],
      requiredRole: 'superadmin',
    },
  ];

  const menu = allMenuItems.filter((item) => !item.requiredRole || canPerformAction(item.requiredRole, currentUser));
  const { items, defaultSelectedKeys, defaultOpenKeys } = buildMenu(menu, currentPath, navigate);
  return (
    <Menu items={items} key={currentPath} defaultSelectedKeys={defaultSelectedKeys} defaultOpenKeys={defaultOpenKeys} />
  );
}
