/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

import { ContentContainer } from '@/components/ContentContainer';

export function SwaggerUiPage() {
  return (
    <ContentContainer>
      <SwaggerUI
        url="/openapi.json"
        withCredentials
        tryItOutEnabled
        requestInterceptor={(req) => {
          if (req.url === '/openapi.json') return req;
          req.url = req.url.replace(window.location.origin, window.mimicConfig.MIMIC_MGMT_PLANE_API);
          return req;
        }}
      />
    </ContentContainer>
  );
}
