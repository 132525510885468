/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useMutation } from '@tanstack/react-query';

import { PatchTag, ApiError, NodeTagResponse } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export type UpdateTagMutation = PatchTag & { id: string };

export type RemoveTagMutationConfig = {
  onSuccess?: (data: NodeTagResponse, params: RemoveNodeTag) => void;
  onError?: (error: ApiError, params: RemoveNodeTag) => void;
  onSettled?: (data: NodeTagResponse | undefined, error: ApiError | null, params: RemoveNodeTag) => void;
  onMutate?: (params: RemoveNodeTag) => Promise<unknown> | void;
};

export type RemoveNodeTag = {
  nodeId: string;
  tagName: string;
};

/**
 * Hook for removing tag from node by ID through the management plane API.
 *
 * @param config.onSuccess - Called after successful removing tag update
 * @param config.onError - Called if removing fails
 * @param config.onSettled - Called after mutation completes (success or failure)
 *
 * @returns React Query mutation result for removing tag from a node
 *
 * @example
 * ```tsx
 * const { mutate } = useRemoveTagMutation({
 *   onSuccess: (data) => console.log('Tag removed:', data),
 *   onError: (error) => console.error('Tag removed failed:', error)
 * });
 *
 * // Remove Tag
 * mutate({ nodeId: '9fb763f7-33de-4969-a7a0-b808f4637318', tagName: 'test-tag-0001' });
 * ```
 */

export function useRemoveTagMutation({ onSuccess, onError, onSettled, onMutate }: RemoveTagMutationConfig) {
  const tenantId = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  const { mutate, isError, error } = useMutation<NodeTagResponse, ApiError, RemoveNodeTag>({
    mutationFn: (params: RemoveNodeTag) => {
      return mgmtPlaneAPIClient.nodes.removeNodeTag({
        tenantId: tenantId!,
        id: params.nodeId,
        tagName: params.tagName,
      });
    },
    onSuccess,
    onError,
    onSettled,
    onMutate,
  });

  return { mutate, isError, error };
}
