/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { px2remTransformer, StyleProvider } from '@ant-design/cssinjs';
import { ConfigProvider } from 'antd';
import { createContext, useState } from 'react';

import { antdComponentConfig, antdComponentDarkConfig, antdTokens, antdTokensDark, tokens, tokensDark } from '@/theme';

export type ThemeProps = { children: React.ReactNode; dark?: boolean };

const px2rem = px2remTransformer({
  rootValue: 16,
});

export const ThemeProviderContext = createContext({ antdTokens, antdComponents: antdComponentConfig, tokens });

export function Theme({ children, dark }: ThemeProps) {
  const theme = dark
    ? { antdTokens: antdTokensDark, antdComponents: antdComponentDarkConfig, tokens: tokensDark }
    : { antdTokens, antdComponents: antdComponentConfig, tokens };
  const [themeState] = useState(theme);
  return (
    <ThemeProviderContext.Provider value={themeState}>
      <ConfigProvider
        theme={{
          token: themeState.antdTokens,
          components: themeState.antdComponents,
        }}
      >
        <StyleProvider transformers={[px2rem]}>{children}</StyleProvider>
      </ConfigProvider>
    </ThemeProviderContext.Provider>
  );
}
