/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { useMessage } from '@/hooks/message';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { CLIAuth } from '@/pages/CLIAuth/CLIAuth';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export function CLIAuthPage() {
  const { t } = useMimicTranslation('cliSetup');
  const [message] = useMessage({ namespace: 'bottom' });
  const profile = useMgmtPlaneStateStore((state) => state.userProfile);
  const tenantID = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  const generateToken = async () => {
    try {
      const { data: tokenData } = await mgmtPlaneAPIClient.auth.generateToken();
      return tokenData.token;
    } catch (err) {
      message.error(t('failedToGenerate'));
      return undefined;
    }
  };

  const copyText = (text: string) => {
    navigator.clipboard.writeText(text).catch(() => {
      message.error(t('failedCopy'));
    });
  };

  const showMessage = (content: string) => {
    return message.feedback(content);
  };

  const copyCommandToClipboard = async (text: string) => {
    await copyText(text);
    await showMessage(t('step2.commandCopied'));
  };

  const copyTokenToClipboard = async (text: string) => {
    await copyText(text);
    await showMessage(t('step3.tokenCopied'));
  };

  return (
    <CLIAuth
      copyCommand={copyCommandToClipboard}
      copyToken={copyTokenToClipboard}
      generateToken={generateToken}
      email={profile?.email || ''}
      tenantID={tenantID!}
    />
  );
}
