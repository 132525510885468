/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { useState } from 'react';

import { ConfigRevision, NodeConfig, NodeConfigFilters, PaginatedNodeConfigsResponse, Tag } from '@/client';
import { PaginatedTable } from '@/components/PaginatedTable';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { useNodeConfigurationColumns } from '@/pages/Configs/useNodeConfigurationColumns';
import { Container } from '@/primitives/Container';
import { UpdateParams } from '@/utils/params';

export type SelectableNodeConfig = NodeConfig & { selected: boolean };

export type NodeConfigsListProps = {
  tags: Tag[] | undefined;
  onParamsChange: UpdateParams<NodeConfig, Partial<NodeConfigFilters>>;
  configsData: PaginatedNodeConfigsResponse;
  getNodeConfigPath: (nodeConfig: NodeConfig) => string;
  getNodeConfigurationRevisionPath: (nodeConfigRevision: ConfigRevision) => string;
  onNodeConfigSelect?: (nodeConfig: NodeConfig) => void;
  defaultNodeConfig?: NodeConfig;
};

export function NodeConfigsList({
  tags,
  onParamsChange,
  configsData,
  getNodeConfigPath,
  getNodeConfigurationRevisionPath,
  onNodeConfigSelect,
  defaultNodeConfig,
}: NodeConfigsListProps) {
  const { t } = useMimicTranslation('nodeConfigurations');

  const [selectedNodeConfig, setSelectedNodeConfig] = useState<NodeConfig | undefined>(defaultNodeConfig);

  const onSelect = onNodeConfigSelect
    ? (nodeConfig: SelectableNodeConfig) => {
        setSelectedNodeConfig(nodeConfig);
        onNodeConfigSelect(nodeConfig);
      }
    : undefined;

  const columns = useNodeConfigurationColumns(
    t,
    tags,
    configsData.meta.filters || {},
    getNodeConfigPath,
    getNodeConfigurationRevisionPath,
    onSelect,
  );

  const dataSourceMapper = (data: NodeConfig) => {
    const mappedData = {
      ...data,
      selected: selectedNodeConfig?.id === data.id,
    };
    return mappedData;
  };

  return (
    <Container full style={{ width: '100%' }}>
      <PaginatedTable
        data={configsData}
        columns={columns}
        rowKey={(config) => config.id}
        data-testid="configuration-table"
        onParamsChange={onParamsChange}
        dataSourceMapper={dataSourceMapper}
      />
    </Container>
  );
}
