/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { useQuery } from '@tanstack/react-query';

import { ApiError, GetSystemInfoResponse } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';

/**
 * Custom hook that fetches system info
 *
 * Features:
 * - Fetches system info
 * - Type-safe API response handling
 *
 * @returns React Query result containing system info
 *
 * @example
 * ```tsx
 * const { data: systemInfo } = useSystemInfoQuery();
 * ```
 */
export function useSystemInfoQuery() {
  return useQuery<GetSystemInfoResponse, ApiError>({
    queryKey: ['systemInfo'],
    queryFn: () => {
      return mgmtPlaneAPIClient.systemInfo.getSystemInfo();
    },
  });
}
