import { UserInfo } from '@/client';
import { useTheme } from '@/hooks/useTheme';
import { Avatar } from '@/primitives/Avatar';
import { Container } from '@/primitives/Container';
import { Text } from '@/primitives/Text';

function getInitial(userInfo: UserInfo) {
  const displayName = userInfo.displayName || userInfo.email;
  if (displayName) {
    return displayName[0].toLocaleUpperCase();
  }
  return '';
}

export type AvatarAndNameProps = {
  userInfo?: UserInfo;
  size?: 'sm' | 'md';
};

export function AvatarAndName({ userInfo, size }: AvatarAndNameProps) {
  const theme = useTheme();
  if (!userInfo) return null;
  const itemColor = theme.antdComponents?.Menu?.itemColor;
  const avatarBg = theme.tokens?.color.components.avatar.avatarDefaultBg;
  return (
    <Container horizontal gap="xs" style={{ height: '100%', alignItems: 'center', backgroundColor: 'transparent' }}>
      <Avatar
        dataTestId="user-avatar"
        size="xxs"
        text={getInitial(userInfo)}
        color={itemColor}
        backgroundColor={avatarBg}
      />
      <Text size={size || 'md'} ellipsis type="subtle" style={{ color: itemColor }}>
        {userInfo.displayName || userInfo.email}
      </Text>
    </Container>
  );
}
