/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { ExclamationCircleFilled, FileTextOutlined, LoadingOutlined } from '@ant-design/icons';

import { Node } from '@/client';
import { GuardedLink } from '@/components/GuardedLink';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Flex } from '@/primitives/Flex';
import { Text } from '@/primitives/Text';
import { tokens } from '@/theme';

export type StatusProps = {
  node: Node;
  getNodeConfigPath: (node: Node) => string;
  getNodeConfigRevisionPath: (node: Node) => string;
};

export function Status({ node, getNodeConfigPath, getNodeConfigRevisionPath }: StatusProps) {
  const { t } = useMimicTranslation('node');

  if (node.requestedRevisionState.status === 'pending') {
    return (
      <div
        data-testid="pending-message"
        style={{
          background: 'rgba(25, 25, 25, 0.04)',
          borderRadius: '2px',
          border: '1px solid rgba(25, 25, 25, 0.15)',
          padding: '0 16px',
          margin: '21px 0 0 0',
          height: '56px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Text type="default">
          <Flex gap="xxs">
            <LoadingOutlined style={{ marginRight: '8px' }} data-testid="loading-spinner" />
            <div>{t('status.configuringTo')}</div>
            <GuardedLink requiredRole="editor" to={getNodeConfigPath(node)} dataTestId="node-config">
              <FileTextOutlined style={{ marginRight: '4px' }} data-testid="file-icon" />
              {`${node.requestedRevisionState.nodeConfig?.name}`}
            </GuardedLink>
            ·
            <GuardedLink
              requiredRole="editor"
              to={getNodeConfigRevisionPath(node)}
              dataTestId="node-config-revision"
            >{`#${node.requestedRevisionState.configRevision?.revisionNumber}`}</GuardedLink>
          </Flex>
        </Text>
      </div>
    );
  }

  if (node.requestedRevisionState.status === 'rejected') {
    return (
      <div
        data-testid="rejected-message"
        style={{
          backgroundColor: '#FCE3E3',
          borderRadius: '2px',
          border: '1px solid #FFB3AD',
          padding: '0 16px',
          margin: '21px 20px 0 20px',
          height: '56px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Flex justify="space-between">
          <Text type="default">
            <Flex gap="xxs">
              <ExclamationCircleFilled
                style={{ color: tokens.color.error.error, fontSize: '1.5rem', marginRight: '4px' }}
                data-testid="bang-icon"
              />
              <div>{t('status.failedToConfigure')}</div>
              <GuardedLink requiredRole="editor" to={getNodeConfigRevisionPath(node)} dataTestId="node-config-revision">
                {`${t('status.revision')} #${node.requestedRevisionState.configRevision?.revisionNumber}`}
              </GuardedLink>
              <div>{t('status.of')}</div>
              <div>
                <FileTextOutlined data-testid="file-icon" style={{ marginRight: '2px' }} />
                <Text type="default" strong>
                  {node.requestedRevisionState.nodeConfig?.name}
                </Text>
              </div>
            </Flex>
          </Text>
        </Flex>
      </div>
    );
  }

  if (node.requestedRevisionState.status === 'applied') return null;
}
