/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Form } from 'antd';
import { useEffect, useState } from 'react';

import { Tag } from '@/client';
import { ContentContainer } from '@/components/ContentContainer';
import { QueryWrapper } from '@/components/QueryWrapper';
import { usePaginatedTagsQuery } from '@/hooks/query/usePaginatedTagsQuery';
import { DeleteTagModal } from '@/pages/Tags/DeleteTagModal';
import { TagFormFields } from '@/pages/Tags/TagForm';
import { TagsHeader } from '@/pages/Tags/TagsHeader';
import { TagsList } from '@/pages/Tags/TagsList';
import { UpdateTagModal } from '@/pages/Tags/UpdateTagModal';
import { Container } from '@/primitives/Container';

export function TagsPage() {
  const tagsQuery = usePaginatedTagsQuery({ type: 'queryParams' });

  const [deleteTag, setDeleteTag] = useState<Tag>();
  const [editTag, setEditTag] = useState<Tag>();
  const [deleteIsPending, setDeleteIsPending] = useState(false);
  const [editForm] = Form.useForm<TagFormFields>();

  useEffect(() => {
    if (editTag) editForm.resetFields();
  }, [editForm, editTag]);

  const closeDeleteDialog = () => {
    setDeleteTag(undefined);
    setDeleteIsPending(false);
  };

  return (
    <ContentContainer>
      <Container>
        <TagsHeader />
        <QueryWrapper queryResult={tagsQuery}>
          {(tagsData) => (
            <TagsList
              tagsData={tagsData}
              onParamsChange={tagsQuery.updateQueryParams}
              setEditTag={setEditTag}
              setDeleteTag={setDeleteTag}
            />
          )}
        </QueryWrapper>

        <DeleteTagModal
          deleteTag={deleteTag}
          deleteIsPending={deleteIsPending}
          setDeleteIsPending={setDeleteIsPending}
          onCancelTagDelete={closeDeleteDialog}
        />

        <UpdateTagModal editTag={editTag} setEditTag={setEditTag} editForm={editForm} />
      </Container>
    </ContentContainer>
  );
}
