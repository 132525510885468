/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { ApiError, GetUserByIdResponse } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { useSubscribeToEvents } from '@/hooks/useSubscribeToEvents';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export type UseUserByIdQueryProps = {
  userId: string;
};

/**
 * Custom hook that fetches and subscribes to user data updates.
 * Combines React Query for data fetching with WebSocket subscriptions for real-time updates.
 *
 * Features:
 * - Fetches user data by ID
 * - Auto-invalidates query on relevant WebSocket events
 * - Handles tenant context
 * - Type-safe API response handling
 *
 * @param props.userId - Unique identifier of the user to fetch
 * @returns React Query result containing user data and query state
 *
 * @example
 * ```tsx
 * const { data: user, isLoading } = useUserByIdQuery({ userId: '123' });
 * ```
 */
export function useUserByIdQuery({ userId }: UseUserByIdQueryProps) {
  const queryClient = useQueryClient();
  const selectedTenantId = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  useSubscribeToEvents(['user:updated'], (payload) => {
    if (!payload.entityIds.includes(userId)) return;
    queryClient.invalidateQueries({ queryKey: ['user', userId] });
  });

  return useQuery<GetUserByIdResponse, ApiError>({
    queryKey: ['user', userId],
    queryFn: () => {
      return mgmtPlaneAPIClient.users.getUserById({ id: userId, tenantId: selectedTenantId! });
    },
    enabled: !!selectedTenantId && !!userId,
  });
}
