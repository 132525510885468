/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { useQuery } from '@tanstack/react-query';

import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';

/**
 * Custom hook that job notifications
 *
 * Features:
 * - Fetches job notifications
 * - Type-safe API response handling
 *
 * @returns React Query result containing tenants
 *
 * @example
 * ```tsx
 * const { data: jobNotifications } = useJobNotificationsQuery();
 * ```
 */

export function useJobNotificationsQuery(tenantId?: string) {
  return useQuery({
    queryKey: ['job-notifications', tenantId],
    queryFn: async () => {
      if (!tenantId) return null;
      return mgmtPlaneAPIClient.jobs.getJobs({
        filters: {
          notificationDismissed: false,
          status: ['error', 'warning', 'timeout', 'running'].join(','),
        },
        page: 1,
        pageSize: 5,
        sort: ['-jobNumber'],
        tenantId,
      });
    },
    enabled: !!tenantId,
  });
}
