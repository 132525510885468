/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import dayjs from 'dayjs';
import { useState } from 'react';

import { Job, JobNode, JobStatus } from '@/client';
import { PaginatedTable } from '@/components/PaginatedTable';
import { QueryWrapper } from '@/components/QueryWrapper';
import { usePaginatedJobNodesQuery } from '@/hooks/query/usePaginatedJobNodesQuery';
import { MimicTranslationFunction, useMimicTranslation } from '@/hooks/useMimicTranslation';
import { JobNodeIssuesModal } from '@/pages/Jobs/JobNodeIssuesModal';
import { JobNodeStatus } from '@/pages/Jobs/JobNodeStatus';
import { Link } from '@/primitives/Link';
import { Text } from '@/primitives/Text';

type JobListRowExpandedProps = {
  job: Job;
};

export function JobListRowExpanded({ job }: JobListRowExpandedProps) {
  const { t } = useMimicTranslation('jobs');

  const [showWarningsModal, setShowWarningsModal] = useState<boolean>(false);
  const [warningsModalContents, setWarningsModalContent] = useState<JobNode['warnings']>();
  const [showErrorsModal, setShowErrorsModal] = useState<boolean>(false);
  const [errorsModalContents, setErrorsModalContent] = useState<JobNode['errors']>();

  const jobNodesQuery = usePaginatedJobNodesQuery({
    type: 'inMemory',
    params: {
      jobId: job.id,
      tenantId: job.tenantId,
    },
  });

  const openWarningsModalWithDetails = (details: JobNode['warnings']) => {
    setWarningsModalContent(details);
    setShowWarningsModal(true);
  };

  const closeWarningsModal = () => {
    setShowWarningsModal(false);
    setWarningsModalContent(undefined);
  };

  const openErrorsModalWithDetails = (details: JobNode['errors']) => {
    setErrorsModalContent(details);
    setShowErrorsModal(true);
  };

  const closeErrorsModal = () => {
    setShowErrorsModal(false);
    setErrorsModalContent(undefined);
  };

  const columns = getJobNodesColumn(job, t, openWarningsModalWithDetails, openErrorsModalWithDetails);

  return (
    <>
      <QueryWrapper queryResult={jobNodesQuery}>
        {(jobNodesData) => (
          <PaginatedTable
            data={jobNodesData}
            columns={columns}
            rowKey={(config) => config.id}
            data-testid="configuration-table"
            onParamsChange={jobNodesQuery.updateQueryParams}
          />
        )}
      </QueryWrapper>
      <JobNodeIssuesModal
        type="warnings"
        showModal={showWarningsModal}
        closeModal={closeWarningsModal}
        modalContents={warningsModalContents}
      />
      <JobNodeIssuesModal
        type="errors"
        showModal={showErrorsModal}
        closeModal={closeErrorsModal}
        modalContents={errorsModalContents}
      />
    </>
  );
}

function getJobNodesColumn(
  job: Job,
  t: MimicTranslationFunction<'jobs'>,
  openWarningsModalWithDetails: (details: JobNode['warnings']) => void,
  openErrorsModalWithDetails: (details: JobNode['errors']) => void,
) {
  return [
    {
      title: <span data-testid="hostName">{t('jobNodes.hostName')}</span>,
      dataIndex: 'hostname',
      sorter: true,
      render: (hostname: string, jobNode: JobNode) => {
        return (
          <Link
            to={`/tenants/${job.tenantId}/nodes/${jobNode.nodeId}`}
            style={{ fontSize: 'inherit', fontFamily: 'DM Mono' }}
            dataTestId="node-link"
          >
            <Text type="link" size="md">
              {hostname}
            </Text>
          </Link>
        );
      },
    },
    {
      title: (
        <span data-testid="nodeVersion">
          {t('jobNodes.nodeVersion', { startDate: `${dayjs(job.dateCreated).format('D MMM YYYY')}` })}
        </span>
      ),
      dataIndex: 'nodeVersion',
      sorter: true,
      render: (nodeVersion: string) => {
        return (
          <Text type="default" size="md">
            {nodeVersion}
          </Text>
        );
      },
    },
    {
      title: <span data-testid="status">{t('jobNodes.status')}</span>,
      dataIndex: 'status',
      sorter: true,
      render: (status: JobStatus, jobNode: JobNode) => {
        const showWarningsButton = !!jobNode.warnings;
        const openWarningsModal = () => {
          openWarningsModalWithDetails(jobNode.warnings);
        };
        const openErrorsModal = () => {
          openErrorsModalWithDetails(jobNode.errors);
        };
        return (
          <JobNodeStatus
            status={status}
            openWarningsModal={openWarningsModal}
            openErrorsModal={openErrorsModal}
            showWarningsButton={showWarningsButton}
          />
        );
      },
    },
  ];
}
