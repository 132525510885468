import { useEffect } from 'react';

import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export type MessageType<T = unknown> = {
  tenantId: string;
  entityIds: string[];
  data?: T;
};

/**
 * Hook for subscribing to WebSocket events through a management plane channel.
 * Handles subscription lifecycle and cleanup automatically.
 *
 * @template Entity Type of data payload received from events
 *
 * @param events Single event string or array of event strings to subscribe to
 * @param callback Function called when subscribed events are received
 *
 * @example
 * ```tsx
 * useSubscribeToEvents<User>('user:created', (event) => {
 *   console.log(message.tenantId, message.entityIds, message.data);
 * });
 * ```
 */
export function useSubscribeToEvents<Entity>(
  events: string | string[],
  callback: (payload: MessageType<Entity>) => void,
) {
  const channel = useMgmtPlaneStateStore((state) => state.channel);

  useEffect(() => {
    if (!channel) return undefined;
    const subscribedEvents = Array.isArray(events) ? events : [events];
    const subscriptions = subscribedEvents.map((event) => {
      return {
        event,
        ref: channel.on(event, callback),
      };
    });

    return () => {
      if (!channel) return;

      subscriptions.forEach((subscription) => {
        channel.off(subscription.event, subscription.ref);
      });
    };
  }, [events, callback, channel]);
}
