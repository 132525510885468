/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { useMutation } from '@tanstack/react-query';

import { ApiError, ConfigAssignmentResponseSchema } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export type AssignConfigToNodesRequest = {
  nodeIDs: string[];
  configID: string;
  revisionNumber: number;
  jobName?: string;
};

export type AssignConfigRevisionMutationConfig = {
  onSuccess?: (data: ConfigAssignmentResponseSchema, variables: AssignConfigToNodesRequest) => void;
  onError?: (error: unknown, variables: AssignConfigToNodesRequest) => void;
  onSettled?: (data: unknown, error: unknown, variables: unknown) => void;
  onMutate?: (variables: AssignConfigToNodesRequest) => void;
};

/**
 * Hook for assigning config revision to a node by ID through the management plane API.
 *
 * @param config.onSuccess - Called after successful assigning of config revision
 * @param config.onError - Called if assigning fails
 * @param config.onSettled - Called after mutation completes (success or failure)
 *
 * @returns React Query mutation result assigning config revision to a node by ID
 *
 * @example
 * ```tsx
 * const { mutate } = useAssignConfigRevisionMutation({
 *   onSuccess: (data) => console.log('Revision assigned:', data),
 *   onError: (error) => console.error('Assigning revision failed:', error)
 * });
 *
 * // Assign config revision
 * mutate({ nodeIDs: ["123"], configID: "456", revisionNumber: 1, });
 * ```
 */

export function useAssignConfigRevisionMutation({
  onSuccess,
  onError,
  onSettled,
  onMutate,
}: AssignConfigRevisionMutationConfig) {
  const tenantId = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  const { mutate, isError, error } = useMutation<ConfigAssignmentResponseSchema, ApiError, AssignConfigToNodesRequest>({
    mutationFn: (assignRequest: AssignConfigToNodesRequest) => {
      return mgmtPlaneAPIClient.nodeConfigs.assignNodeConfig({
        tenantId: tenantId!,
        configId: assignRequest.configID,
        requestBody: {
          nodeIds: assignRequest.nodeIDs,
          configRevisionNumber: assignRequest.revisionNumber,
          jobName: assignRequest.jobName,
        },
      });
    },
    onSuccess,
    onError,
    onSettled,
    onMutate,
  });

  return { mutate, isError, error };
}
