/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { Form, FormInstance, Input } from 'antd';

import { ClearHallmarks } from '@/client';
import { ValidationError } from '@/components/Forms/ValidationError';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { tokens } from '@/theme';

const requiredMarkAfter = (label: React.ReactNode) => label;

export type ClearHallmarksFormParams = {
  form: FormInstance<ClearHallmarks>;
};

export function ClearHallmarksForm({ form }: ClearHallmarksFormParams) {
  const { t } = useMimicTranslation('node');

  return (
    <Form
      validateTrigger="onBlur"
      form={form}
      layout="vertical"
      requiredMark={requiredMarkAfter}
      data-testid="clear-hallmarks-form"
    >
      <Form.Item
        name="reason"
        label={t('clearHallmarksModal.reason')}
        rules={[
          { required: true, message: <ValidationError>{t('clearHallmarksModal.enterValidReason')}</ValidationError> },
          {
            min: 10,
            message: <ValidationError>{t('clearHallmarksModal.enterValidReasonLength', { min: 10 })}</ValidationError>,
          },
        ]}
      >
        <Input.TextArea
          allowClear
          rows={4}
          placeholder={t('clearHallmarksModal.reasonPlaceholder')}
          data-testid="clear-hallmarks-reason"
          count={{ show: true, max: 280 }}
          maxLength={280}
          style={{
            backgroundColor: tokens.color.surface.surfaceHigh,
          }}
        />
      </Form.Item>
    </Form>
  );
}
