/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { ContentContainer } from '@/components/ContentContainer';
import { QueryWrapper } from '@/components/QueryWrapper';
import { usePaginatedActivitiesQuery } from '@/hooks/query/usePaginatedActivitiesQuery';
import { ActivitiesList } from '@/pages/Activity/ActivitiesList';
import { ActivityHeader } from '@/pages/Activity/ActivityHeader';
import { Container } from '@/primitives/Container';

export function ActivityPage() {
  const activitiesQuery = usePaginatedActivitiesQuery({ type: 'queryParams' });

  return (
    <ContentContainer width="100%">
      <Container>
        <ActivityHeader />
        <QueryWrapper queryResult={activitiesQuery}>
          {(activitiesData) => (
            <ActivitiesList
              activitiesData={activitiesData}
              onParamsChange={activitiesQuery.updateQueryParams}
              isLoading={activitiesQuery.isLoading}
            />
          )}
        </QueryWrapper>
      </Container>
    </ContentContainer>
  );
}
