/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';

import { GuardedButton } from '@/components/GuardedButton';
import { PageHeader } from '@/components/PageHeader';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { NewTagDialog } from '@/pages/Tags/NewTagDialog';
import { Container } from '@/primitives/Container';
import { Dropdown } from '@/primitives/Dropdown';

export function TagsHeader() {
  const { t } = useMimicTranslation('tags');

  const [newTagOpen, setNewTagOpen] = useState<boolean>();

  const onNewTagOpenChange = () => {
    if (newTagOpen) {
      // Allows background clicks to close dropdown
      setNewTagOpen(undefined);
    }
  };

  const newTagDialog = () => <NewTagDialog setNewTagOpen={setNewTagOpen} />;

  return (
    <PageHeader title={t('title')} text={t('subtitle')} level={2}>
      <Container horizontal gap="sm">
        <Dropdown open={newTagOpen} onOpenChange={onNewTagOpenChange} customDialog={newTagDialog}>
          <GuardedButton
            requiredRole="editor"
            type="primary"
            dataTestId="new-tag"
            onClick={() => setNewTagOpen(!newTagOpen)}
          >
            <PlusOutlined /> {t('newTag')}
          </GuardedButton>
        </Dropdown>
      </Container>
    </PageHeader>
  );
}
