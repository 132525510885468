/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { ConnectivityState, Node } from '@/client';
import { ConnectivityTime } from '@/components/States/ConnectivityTime';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Card } from '@/primitives/Card';
import { Flex } from '@/primitives/Flex';
import { NodeIcon } from '@/primitives/Icons';
import { Text } from '@/primitives/Text';
import { Title } from '@/primitives/Title';

export type ConfigWizardOverlayNodeDetailsProps = {
  node: Node;
};

export function ConfigWizardOverlayNodeDetails({ node }: ConfigWizardOverlayNodeDetailsProps) {
  const { t } = useMimicTranslation('configWizardOverlay');
  return (
    <Card padding="sm" bordered>
      <Flex full justify="space-between">
        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.8em' }}>
          <Text type="subtle" strong size="md">
            {t('node')}
          </Text>
          <Flex vertical full gap="xxs">
            <Title style={{ fontWeight: 500 }} level={4}>
              <NodeIcon /> {node?.name}
            </Title>
            <Text type="subtle" size="md" copyable mono>
              {node.id}
            </Text>
          </Flex>
        </div>
        <Flex align="center">
          <ConnectivityTime state={node?.connectivityState as ConnectivityState} lastSeen={node?.lastSeen} vertical />
        </Flex>
      </Flex>
    </Card>
  );
}
