/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { HTTPError } from '@/components/HTTPError';
import { useAuthorization } from '@/hooks/useAuthorization';

import { User } from './client';

export type GuardedRouteProps = {
  requiredRole: User['role'];
  children: React.ReactNode;
};

export function GuardedRoute({ children, requiredRole }: GuardedRouteProps) {
  const canView = useAuthorization(requiredRole);

  if (!canView) {
    return <HTTPError httpStatus="404" />;
  }

  return children;
}
