/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { Notification } from '@/client';
import { HallmarksClearedNotificationBody } from '@/pages/Notifications/HallmarksClearedNotificationBody';
import { Link } from '@/primitives/Link';
import { Space } from '@/primitives/Space';
import { Text } from '@/primitives/Text';

export type NotificationType =
  | 'NODE_PROVISIONED_V0'
  | 'NODE_CONFIG_APPLIED_V0'
  | 'NODE_CONFIG_REJECTED_V0'
  | 'NODE_CREDENTIAL_APPLIED_V0'
  | 'NODE_CREDENTIAL_REJECTED_V0'
  | 'CONFIG_HEARTBEAT_RESUMED_V0'
  | 'CONFIG_HEARTBEAT_STOPPED_V0'
  | 'CREDENTIAL_HEARTBEAT_RESUMED_V0'
  | 'CREDENTIAL_HEARTBEAT_STOPPED_V0'
  | 'MEMBER_EDITED_V0'
  | 'MEMBER_ADDED_V0'
  | 'NODE_LIFECYCLE_ERROR_V0'
  | 'HALLMARKS_CLEARED_V0'
  | 'HALLMARK'
  | 'DEFLECTION';

type NotificationProps = {
  notification: Notification;
};

type NotificationTemplate = {
  Title: ({ notification }: NotificationProps) => JSX.Element;
  Subtitle: ({ notification }: NotificationProps) => JSX.Element;
};

type NotificationTemplateLookup = {
  [key in NotificationType]: NotificationTemplate;
};

const HALLMARKS_CLEARED_NOTIFICATION_TEMPLATE: NotificationTemplate = {
  Title: ({ notification }: NotificationProps) => <span>{notification.title}</span>,
  Subtitle: HallmarksClearedNotificationBody,
};

const NODE_NOTIFICATION_TEMPLATE: NotificationTemplate = {
  Title: ({ notification }: NotificationProps) => <span>{notification.title}</span>,
  Subtitle: ({ notification }: NotificationProps) => {
    const data = notification.subjectData;
    const nodePath = `/tenants/${notification.tenantID}/nodes/${data.nodeId}`;
    return (
      <>
        {notification.subtitle} in{' '}
        <Link to={nodePath}>
          {data.hostname} | {data.name}
        </Link>
      </>
    );
  },
};

const MEMBER_NOTIFICATION_TEMPLATE: NotificationTemplate = {
  Title: ({ notification }: NotificationProps) => <span>{notification.title}</span>,
  Subtitle: ({ notification }: NotificationProps) => {
    return (
      <>
        {notification.subjectData.displayName} {notification.subtitle}
      </>
    );
  },
};

const FALLBACK_NOTIFICATION_CONTENT: NotificationTemplate = {
  Title: ({ notification }: NotificationProps) => <span>{notification.title}</span>,
  Subtitle: ({ notification }: NotificationProps) => <span>{notification.subtitle}</span>,
};

const NOTIFICATION_CONTENT: NotificationTemplateLookup = {
  NODE_PROVISIONED_V0: NODE_NOTIFICATION_TEMPLATE,
  NODE_CREDENTIAL_APPLIED_V0: NODE_NOTIFICATION_TEMPLATE,
  NODE_CONFIG_APPLIED_V0: NODE_NOTIFICATION_TEMPLATE,
  NODE_CONFIG_REJECTED_V0: NODE_NOTIFICATION_TEMPLATE,
  NODE_CREDENTIAL_REJECTED_V0: NODE_NOTIFICATION_TEMPLATE,
  CREDENTIAL_HEARTBEAT_STOPPED_V0: NODE_NOTIFICATION_TEMPLATE,
  CREDENTIAL_HEARTBEAT_RESUMED_V0: NODE_NOTIFICATION_TEMPLATE,
  CONFIG_HEARTBEAT_STOPPED_V0: NODE_NOTIFICATION_TEMPLATE,
  CONFIG_HEARTBEAT_RESUMED_V0: NODE_NOTIFICATION_TEMPLATE,
  NODE_LIFECYCLE_ERROR_V0: NODE_NOTIFICATION_TEMPLATE,
  HALLMARKS_CLEARED_V0: HALLMARKS_CLEARED_NOTIFICATION_TEMPLATE,
  HALLMARK: NODE_NOTIFICATION_TEMPLATE,
  DEFLECTION: NODE_NOTIFICATION_TEMPLATE,
  MEMBER_ADDED_V0: MEMBER_NOTIFICATION_TEMPLATE,
  MEMBER_EDITED_V0: MEMBER_NOTIFICATION_TEMPLATE,
};

export function NotificationContent({ notification }: NotificationProps) {
  const { type } = notification;
  const { Title, Subtitle } = NOTIFICATION_CONTENT[type as NotificationType] || FALLBACK_NOTIFICATION_CONTENT;
  return (
    <Space dir="vertical">
      <Text size="md" data-testid="title" strong>
        <Title notification={notification} />
      </Text>
      <Text size="md" data-testid="subtitle">
        <Subtitle notification={notification} />
      </Text>
    </Space>
  );
}
