/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';

import { GuardedButton } from '@/components/GuardedButton';
import { EditableNodeName } from '@/components/Node/EditableNodeName';
import { PageHeader } from '@/components/PageHeader';
import { TagsField } from '@/components/TagsField';
import { useMessage } from '@/hooks/message';
import { useAddTagMutation } from '@/hooks/mutation/useAddTagMutation';
import { useNewTagMutation } from '@/hooks/mutation/useNewTagMutation';
import { useRemoveTagMutation } from '@/hooks/mutation/useRemoveTagMutation';
import { useUpdateBaselineMutation } from '@/hooks/mutation/useUpdateBaselineMutation';
import { useNodeByIdQuery } from '@/hooks/query/useNodeByIdQuery';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Container } from '@/primitives/Container';
import { NodeIcon } from '@/primitives/Icons';
import { Row } from '@/primitives/Row';
import { Space } from '@/primitives/Space';
import { Text } from '@/primitives/Text';

export type NodeDetailsHeaderProps = {
  nodeQuery: ReturnType<typeof useNodeByIdQuery>;
  onNameChange: (newNodeName: string) => void;
  nodeTags: string[];
  tenantID: string;
  nodeId: string;
};

export function NodeDetailsHeader({ nodeQuery, onNameChange, nodeTags, tenantID, nodeId }: NodeDetailsHeaderProps) {
  const [message] = useMessage();
  const { t } = useMimicTranslation('node');
  const { t: tTags } = useMimicTranslation('tags');
  const navigate = useNavigate();

  const openClearHallmarksModal = () => {
    navigate(`/tenants/${tenantID}/nodes/${nodeQuery.data!.data.id}/clear-hallmarks`);
  };

  const { mutateAsync: updateBaseline, isPending: updateBaselinePending } = useUpdateBaselineMutation(
    tenantID!,
    nodeQuery.data!.data,
    {
      onError: (error) => {
        message.error(tTags('updateBaselineError'));
        Sentry.captureException(error);
      },
    },
  );

  const { mutateAsync: addTag } = useAddTagMutation({
    onError: (error, tag) => {
      message.error(tTags('addTagError', { name: tag.tagName }));
      Sentry.captureException(error);
    },
  });
  const { mutate: removeTag } = useRemoveTagMutation({
    onError: (error, tag) => {
      message.error(tTags('removeTagError', { name: tag.tagName }));
      Sentry.captureException(error);
    },
  });
  const { mutateAsync: newTagMutate } = useNewTagMutation({
    onError: (error, tag) => {
      message.error(tTags('newTagCreatedError', { name: tag.name }));
      Sentry.captureException(error);
    },
  });

  const onAddTag = async (tagName: string) => {
    await addTag({ nodeId, tagName });
  };

  const onRemoveTag = (tagName: string) => {
    removeTag({ nodeId, tagName });
  };

  const onCreateTag = async (name: string) => {
    await newTagMutate({ name });
  };

  return (
    <PageHeader
      icon={<NodeIcon />}
      title={<Text mono>{nodeQuery.data!.data.systemProfile?.['host.name'] || t('hostNameNotAvailable')}</Text>}
      text={
        <Space dir="vertical">
          <Row>
            <Container style={{ width: '40vw' }}>
              <EditableNodeName name={nodeQuery.data!.data.name} onNameChange={onNameChange} />
            </Container>
          </Row>
          <TagsField selectedTags={nodeTags} onAddTag={onAddTag} onRemoveTag={onRemoveTag} onCreateTag={onCreateTag} />
        </Space>
      }
      level={4}
      fontWeight="300"
    >
      <Container justify="end" full style={{ marginRight: '16px' }}>
        <Container gap="sm" justify="flex-end" horizontal style={{ flexWrap: 'wrap' }}>
          <GuardedButton
            requiredRole="admin"
            type="primary"
            dataTestId="update-baseline"
            loading={updateBaselinePending}
            onClick={() => updateBaseline()}
          >
            {t('update_baseline')}
          </GuardedButton>
          <GuardedButton
            requiredRole="superadmin"
            type="primary"
            dataTestId="clear-hallmarks"
            onClick={() => openClearHallmarksModal()}
          >
            {t('clear_hallmarks')}
          </GuardedButton>
        </Container>
      </Container>
    </PageHeader>
  );
}
