/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useParams } from 'react-router-dom';
import { validate as uuidValidate } from 'uuid';

import { HTTPError } from '@/components/HTTPError';
import { NodeDetailsPage } from '@/pages/NodeDetails/NodeDetailsPage';

export function NodeDetailsRoute() {
  const { id: nodeId } = useParams<'id'>();

  if (!nodeId || !uuidValidate(nodeId)) {
    return <HTTPError httpStatus="404" />;
  }

  return <NodeDetailsPage nodeId={nodeId} />;
}
