/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { BackButton } from '@/components/Buttons/BackButton';
import { ContentContainer } from '@/components/ContentContainer';
import { QueryWrapper } from '@/components/QueryWrapper';
import { useNodeConfigByIdQuery } from '@/hooks/query/useNodeConfigByIdQuery';
import { NodeConfigDetailsTabs } from '@/pages/NodeConfigDetails/NodeConfigDetailsTabs';
import { NodeConfigurationDetailsHeader } from '@/pages/NodeConfigDetails/NodeConfigurationDetailsHeader';
import { Container } from '@/primitives/Container';
import { Flex } from '@/primitives/Flex';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

import './NodeConfigurationDetails.css';

export type NodeConfigDetailsPageProps = {
  nodeConfigId: string;
};

export function NodeConfigDetailsPage({ nodeConfigId }: NodeConfigDetailsPageProps) {
  const tenantID = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  const nodeConfigQuery = useNodeConfigByIdQuery({ nodeConfigId });

  return (
    <ContentContainer width="100%">
      <Flex vertical gap="sm">
        <Flex vertical gap="none">
          <Container>
            <BackButton to={`/tenants/${tenantID}/node-configs`} />
          </Container>
          <QueryWrapper queryResult={nodeConfigQuery}>
            {(nodeConfigData) => (
              <>
                <NodeConfigurationDetailsHeader nodeConfig={nodeConfigData.data} />
                <NodeConfigDetailsTabs nodeConfig={nodeConfigData.data} />
              </>
            )}
          </QueryWrapper>
        </Flex>
      </Flex>
    </ContentContainer>
  );
}
