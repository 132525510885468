/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { Notification } from '@/client';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Link } from '@/primitives/Link';

export function HallmarksClearedNotificationBody({ notification }: { notification: Notification }) {
  const { t } = useMimicTranslation('notifications');

  const data = notification.subjectData;
  const nodePath = `/tenants/${notification.tenantID}/nodes/${data.nodeId}`;
  return (
    <>
      {t('clear_hallmarks.subtitle', {
        user: notification.subjectData.displayName,
      })}{' '}
      <Link to={nodePath}>
        {data.hostname} | {data.name}
      </Link>
      <div>{t('clear_hallmarks.reason', { reason: data.reason })}</div>
    </>
  );
}
