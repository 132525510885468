/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { ConfigRevision, NodeConfig } from '@/client/';
import { QueryWrapper } from '@/components/QueryWrapper';
import { RelativeTime } from '@/components/RelativeTime';
import { usePaginatedConfigRevisionsQuery } from '@/hooks/query/usePaginatedConfigRevisionsQuery';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { ConfigRevisionInfo } from '@/pages/ConfigureNodesJobWizard/ConfigureNodesJobWizardSelectConfigRevision';
import { Flex } from '@/primitives/Flex';
import { Radio } from '@/primitives/Radio';
import { Text } from '@/primitives/Text';

export type ExpandedRowProps = {
  nodeConfig: NodeConfig;
  selectedConfigRevisionInfo: ConfigRevisionInfo;
  setSelectedConfigRevisionInfo: (configRevision: ConfigRevisionInfo) => void;
  setShowConfigCode: (showConfigCode: boolean) => void;
  setError: (error: boolean) => void;
};

export function ExpandedConfigRowRender({
  nodeConfig,
  selectedConfigRevisionInfo,
  setSelectedConfigRevisionInfo,
  setShowConfigCode,
  setError,
}: ExpandedRowProps) {
  const { t } = useMimicTranslation('configureNodesJobWizard');

  const configRevisionsQuery = usePaginatedConfigRevisionsQuery({ params: { configId: nodeConfig.id } });

  const onSelectConfig = (configRevision: ConfigRevision) => {
    setError(false);

    setSelectedConfigRevisionInfo({
      configId: nodeConfig.id,
      revisionId: configRevision.id,
      revisionNumber: configRevision.revisionNumber,
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <QueryWrapper queryResult={configRevisionsQuery}>
        {(configRevisionsData) => (
          <>
            {configRevisionsData.data.map((revision) => (
              <Flex key={revision.id} full align="center">
                <div style={{ display: 'flex', width: '100%', alignItems: 'center', height: '44px' }}>
                  <div style={{ marginLeft: '44px' }}>
                    <Radio
                      dataTestId="select-config-revision"
                      onClick={() => setShowConfigCode(true)}
                      onChange={() => onSelectConfig(revision)}
                      checked={selectedConfigRevisionInfo?.revisionId === revision.id}
                    />
                  </div>
                  <Text type="subtle" size="sm" style={{ minWidth: '44px', maxWidth: '72px' }}>
                    {`${t('rev')} #${revision.revisionNumber}`}
                  </Text>
                  <Text type="default" size="sm">
                    {revision.description}
                  </Text>
                </div>
                <div style={{ width: '164px' }}>
                  <Text type="default" size="sm">
                    <RelativeTime date={revision.createdAt} />
                  </Text>
                </div>
              </Flex>
            ))}
          </>
        )}
      </QueryWrapper>
    </div>
  );
}
