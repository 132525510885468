/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { MailOutlined, PhoneOutlined } from '@ant-design/icons';

import { MimicLogo } from '@/components/MimicLogo';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Divider } from '@/primitives/Divider';
import { Flex } from '@/primitives/Flex';
import { Footer } from '@/primitives/Footer';
import { Link } from '@/primitives/Link';
import { Space } from '@/primitives/Space';
import { Text } from '@/primitives/Text';

export function SupportFooter() {
  const { t } = useMimicTranslation('footer');
  const telephone = '+1 (650) 698-2400';
  const email = 'support@mimic.com';
  return (
    <Footer>
      <Flex justify="space-between" wrap>
        <Flex justify="space-between" wrap>
          <Text type="subtle">{t('contactUs')}</Text>
          <Space>
            <Link href={`mailto:${email}`}>
              <Space size="xxs">
                <MailOutlined />
                {email}
              </Space>
            </Link>
            <Divider vertical dark />
            <Link href={`tel:${telephone}`}>
              <Space size="xxs">
                <PhoneOutlined />
                {telephone}
              </Space>
            </Link>
          </Space>
        </Flex>
        <Space>
          <MimicLogo width="3.5rem" height="1rem" />
          <Text type="subtle">{t('copyright')}</Text>
        </Space>
      </Flex>
    </Footer>
  );
}
