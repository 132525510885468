/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { BuildOutlined } from '@ant-design/icons';

import { NodeConfig } from '@/client';
import { PageHeader } from '@/components/PageHeader';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Container } from '@/primitives/Container';
import { Flex } from '@/primitives/Flex';
import { OsIcon } from '@/primitives/Icons';
import { Text } from '@/primitives/Text';
import { Title } from '@/primitives/Title';
import { VerticalDivider } from '@/primitives/VerticalDivider';

export type NodeConfigurationDetailsHeaderProps = {
  nodeConfig: NodeConfig;
};

export function NodeConfigurationDetailsHeader({ nodeConfig }: NodeConfigurationDetailsHeaderProps) {
  const { t } = useMimicTranslation('nodeConfigurationDetail');

  return (
    <Container style={{ padding: '24px 0 0 0' }}>
      <PageHeader
        icon={<BuildOutlined style={{ height: '16px', width: '16px' }} />}
        title={
          <Text type="subtle" size="md" strong style={{ lineHeight: '28px' }}>
            {t('title')}
          </Text>
        }
        text={
          <Flex vertical>
            <Container style={{ width: '40vw' }}>
              <Title level={4} style={{ fontFamily: 'DM Mono', fontWeight: 500 }}>
                {nodeConfig.name}
              </Title>
              <Flex gap="xs">
                <Text size="sm" type="subtle" style={{ textTransform: 'capitalize' }}>
                  <OsIcon name={nodeConfig.os} /> {nodeConfig.os}
                </Text>
                <VerticalDivider />
                <Text mono copyable type="subtle" size="sm">
                  {nodeConfig.id}
                </Text>
              </Flex>
            </Container>
          </Flex>
        }
        level={5}
        fontWeight="300"
      />
    </Container>
  );
}
