/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { ContentContainer } from '@/components/ContentContainer';
import { QueryWrapper } from '@/components/QueryWrapper';
import { useJobByIdQuery } from '@/hooks/query/useJobByIdQuery';
import { usePaginatedJobNodesQuery } from '@/hooks/query/usePaginatedJobNodesQuery';
import { JobDetailsConfig } from '@/pages/JobDetails/JobDetailsConfig';
import { JobDetailsHeader } from '@/pages/JobDetails/JobDetailsHeader';
import { JobDetailsTable } from '@/pages/JobDetails/JobDetailsTable';
import { Flex } from '@/primitives/Flex';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export type JobDetailsPageProps = {
  jobId: string;
};

export function JobDetailsPage({ jobId }: JobDetailsPageProps) {
  const tenantID = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  const jobQuery = useJobByIdQuery({ jobId });
  const jobNodesQuery = usePaginatedJobNodesQuery({
    type: 'queryParams',
    params: {
      jobId,
      tenantId: tenantID!,
    },
  });

  return (
    <ContentContainer width="100%">
      <Flex vertical>
        <QueryWrapper queryResult={jobQuery}>
          {(jobData) => (
            <>
              <JobDetailsHeader job={jobData.data} />
              <JobDetailsConfig job={jobData.data} />
              <QueryWrapper queryResult={jobNodesQuery}>
                {(jobNodesData) => (
                  <JobDetailsTable
                    tenantID={tenantID!}
                    jobNodesData={jobNodesData}
                    job={jobData.data}
                    onParamsChange={jobNodesQuery.updateQueryParams}
                  />
                )}
              </QueryWrapper>
            </>
          )}
        </QueryWrapper>
      </Flex>
    </ContentContainer>
  );
}
