/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { useInMemoryQueryParams } from '@/hooks/useInMemoryQueryParams';
import { useQueryParams } from '@/hooks/useQueryParams';
import { useSubscribeToEvents } from '@/hooks/useSubscribeToEvents';
import { TypeMap } from '@/utils/filters';

type PaginationParams = {
  page?: number;
  pageSize?: number;
};

type BaseQueryParams<Filter, SortableFields> = {
  filters?: Filter;
  sort?: SortableFields;
} & PaginationParams;

type PaginatedQueryConfig<
  TResponse,
  TFilters extends Record<string, unknown> | unknown,
  SortableFields,
  TParams extends BaseQueryParams<TFilters, SortableFields>,
> = {
  apiCall: (params: TParams) => Promise<TResponse>;
  params: TParams;
  queryKey: string;
  realtimeEvents: string | string[];
  filterTypes?: TypeMap<TFilters>;
  type?: 'inMemory' | 'queryParams';
  urlPrefix?: string;
};

/**
 * Hook for managing paginated API queries with filters, sorting, pagination and real-time updates.
 * Supports both URL-based and in-memory parameter persistence.
 *
 * @param config Configuration object containing:
 *   - apiCall: Function that calls the API with the provided parameters
 *   - params: Base query parameters to merge with filters/sort/pagination
 *   - queryKey: Key for React Query cache management
 *   - filterTypes: Optional type definitions for filters
 *   - type: Storage type - 'inMemory' (default) or 'queryParams' for URL persistence
 *   - urlPrefix: Optional URL prefix for query params storage
 *   - realtimeEvents: Array of event names to trigger query invalidation
 *
 * @returns Object containing:
 *   - data: API response data
 *   - isLoading: Boolean indicating initial load state
 *   - isFetching: Boolean indicating any loading state
 *   - isError: Boolean indicating error state
 *   - error: Error object if request failed
 *   - updateQueryParams: Function to update filters, sort, and pagination
 */
export function usePaginatedQuery<
  TPaginatedResponse,
  TResource,
  TFilters extends Record<string, unknown> | unknown,
  SortableFields,
  TParams extends BaseQueryParams<TFilters, SortableFields>,
>({
  apiCall,
  params,
  queryKey,
  filterTypes,
  overRideParamFilters,
  type = 'inMemory',
  urlPrefix,
  realtimeEvents,
}: PaginatedQueryConfig<TPaginatedResponse, TFilters, SortableFields, TParams> & {
  overRideParamFilters: boolean | undefined;
}) {
  // load the two query params hooks (memory-based and URL-based) and then use the one that is selected in the config (type)
  const queryParams = useQueryParams<TResource, TFilters>(filterTypes, urlPrefix, params.filters);
  const inMemoryParams = useInMemoryQueryParams<TResource, TFilters, SortableFields>(params.filters, params.sort);
  const { filters, sort, paginationParams, updateQueryParams } = type === 'queryParams' ? queryParams : inMemoryParams;

  // merge the query params with the params from the config and call the API
  const apiCallFilterParams = overRideParamFilters
    ? { ...(filters || {}), ...(params.filters || {}) }
    : { ...(params.filters || {}), ...(filters || {}) };
  const apiCallSortParams = sort || params.sort;
  const apiCallPaginationParams = { ...params, ...paginationParams };
  const apiCallParams = {
    ...params,
    filters: apiCallFilterParams,
    sort: apiCallSortParams,
    page: apiCallPaginationParams.number,
    pageSize: apiCallPaginationParams.size,
  };

  const { data, isLoading, isFetching, isError, error } = useQuery({
    queryKey: [queryKey, apiCallParams],
    queryFn: () => apiCall(apiCallParams),
    staleTime: 5 * 100,
    enabled: true,
  });

  // subscribe to events that may invalidate the query cache
  const queryClient = useQueryClient();
  useSubscribeToEvents(realtimeEvents, () => queryClient.invalidateQueries({ queryKey: [queryKey, apiCallParams] }));

  // return the data, the query state, and the function to update the query params
  return {
    data,
    isLoading,
    isFetching,
    isError,
    error,
    filters: apiCallFilterParams,
    sort: apiCallSortParams,
    pagination: apiCallPaginationParams,
    updateQueryParams,
  };
}
