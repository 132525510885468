/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { InfoCircleOutlined, LogoutOutlined, TagsOutlined, UserOutlined } from '@ant-design/icons';

import { AvatarAndName } from '@/components/AvatarAndName';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Menu } from '@/primitives/Menu';
import { Text } from '@/primitives/Text';
import { selectedTenantIDKey, useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

import { MenuItemConfig, MenuProps, buildMenu } from './builder';

export function AccountMenu({ tenantID, navigate, currentPath }: MenuProps) {
  const { t } = useMimicTranslation('menu');
  const profile = useMgmtPlaneStateStore((state) => state.userProfile);

  const userMenuItem = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        marginTop: '4px',
      }}
    >
      <AvatarAndName userInfo={profile} />
      <Text size="sm" style={{ marginLeft: '30px' }}>
        {profile?.email}
      </Text>
    </div>
  );

  const menu: MenuItemConfig[] = [
    {
      name: 'accountMenu',
      label: <AvatarAndName userInfo={profile} />,
      dataTestId: 'account-menu',

      items: [
        {
          name: 'user',
          icon: userMenuItem,
          label: '',
          dataTestId: 'user-menu',
        },
        {
          type: 'divider',
        } as unknown as MenuItemConfig,
        {
          name: 'tags',
          icon: <TagsOutlined />,
          label: t('tags'),
          path: `/tenants/${tenantID}/tags`,
          dataTestId: 'tags-menu',
        },
        {
          name: 'members',
          icon: <UserOutlined />,
          label: t('members'),
          path: `/tenants/${tenantID}/members`,
          dataTestId: 'members-menu',
        },
        {
          name: 'logout',
          icon: <LogoutOutlined />,
          label: t('logout'),
          onClick: () => {
            localStorage.removeItem(selectedTenantIDKey);
            window.location.href = `${window.mimicConfig.MIMIC_MGMT_PLANE_API}/api/auth/sign-out`;
          },
          danger: true,
          dataTestId: 'logout-menu',
        },
        {
          type: 'divider',
        } as unknown as MenuItemConfig,
        {
          name: 'system-info',
          icon: <InfoCircleOutlined />,
          label: t('systemInfo'),
          path: '/system-info',
          dataTestId: 'system-info',
        },
      ],
    },
  ];

  const { items, defaultSelectedKeys } = buildMenu(menu, currentPath, navigate);
  return <Menu key={currentPath} mode="vertical" items={items} defaultSelectedKeys={defaultSelectedKeys} />;
}
