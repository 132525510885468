/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { SegmentedValue } from 'antd/es/segmented';
import { Outlet } from 'react-router-dom';

import { User, UserFilters } from '@/client';
import { ContentContainer } from '@/components/ContentContainer';
import { QueryWrapper } from '@/components/QueryWrapper';
import { usePaginatedMembersQuery } from '@/hooks/query/usePaginatedMembersQuery';
import { useQueryParams } from '@/hooks/useQueryParams';
import { MembersHeader, UserState } from '@/pages/Members/MembersHeader';
import { MembersList } from '@/pages/Members/MembersList';
import { defineTypes } from '@/utils/filters';

const UserFilterTypes = defineTypes<UserFilters>({
  removed: 'boolean',
});

export function MembersPage() {
  const { filters, updateQueryParams } = useQueryParams<User, UserFilters>(UserFilterTypes);
  const activeMembersQuery = usePaginatedMembersQuery({
    type: 'queryParams',
    params: {
      filters: { removed: false },
    },
    overRideParamFilters: true,
  });
  const removedMembersQuery = usePaginatedMembersQuery({
    type: 'queryParams',
    params: { filters: { removed: true } },
    overRideParamFilters: true,
  });

  const membersQuery = filters.removed ? removedMembersQuery : activeMembersQuery;
  const activeFilterValue = filters.removed ? UserState.REMOVED : UserState.ACTIVE;
  const activeTotal = activeMembersQuery.data?.meta.page.totalItems;
  const removedTotal = removedMembersQuery.data?.meta.page.totalItems;

  const onChangeActiveFilter = (value: SegmentedValue) => {
    if (value === UserState.ACTIVE) {
      updateQueryParams({ filters: { removed: false } });
    } else if (value === UserState.REMOVED) {
      updateQueryParams({ filters: { removed: true } });
    }
  };

  return (
    <ContentContainer>
      <MembersHeader
        activeFilterValue={activeFilterValue}
        onChangeActiveFilter={onChangeActiveFilter}
        activeTotal={activeTotal!}
        removedTotal={removedTotal!}
        isLoading={membersQuery.isLoading}
      />
      <QueryWrapper queryResult={membersQuery}>
        {(membersData) => (
          <MembersList membersData={membersData} filters={filters} onParamsChange={updateQueryParams} />
        )}
      </QueryWrapper>
      <Outlet />
    </ContentContainer>
  );
}
