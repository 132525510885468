/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Avatar as AntDAvatar } from 'antd';

import { useTheme } from '@/hooks/useTheme';
import { FontSize, IconSize, tokens } from '@/theme';

export type AvatarProps = {
  /**
   * The icon size category
   * @default md
   */
  size?: IconSize;
  /**
   * Text label for the avatar
   */
  text: string;
  /**
   * Text color for the avatar
   * @default textDefault
   */
  color?: string;

  /**
   * The shape of the avatar
   * @default circle
   */
  shape?: 'circle' | 'square';

  /**
   * Background color for the avatar
   */
  backgroundColor?: string;

  /**
   * Data test id
   */
  dataTestId?: string;
};

const FontSizeLookup: Record<IconSize, FontSize> = {
  xxs: 'sm',
  xs: 'md',
  sm: 'lg',
  md: 'xl',
  lg: 'xl',
};

export function Avatar({ size, text, color, shape, backgroundColor, dataTestId }: AvatarProps) {
  const sizePx = tokens.size.icon[size || 'md'];
  const fontSize = FontSizeLookup[size || 'md'];
  const fontSizePx = tokens.size.font[fontSize];
  const textColor = color || tokens.color.text.textDefault;
  const shapeType = shape || 'circle';

  const theme = useTheme();

  return (
    <AntDAvatar
      style={{
        minWidth: sizePx,
        alignItems: 'center',
        color: textColor,
        fontSize: fontSizePx,
        backgroundColor,
        border: `1px solid ${theme.tokens.color.components.avatar.avatarBorder}`,
      }}
      size={sizePx}
      shape={shapeType}
      data-testid={dataTestId}
    >
      {text}
    </AntDAvatar>
  );
}
