import { useState } from 'react';

import { PaginationParams } from '@/client/mgmtPlane';
import { Sorter, UpdateParams } from '@/utils/params';
import { fromSorter } from '@/utils/sort';

/**
 * This hook is used to manage/persist query params in memory. Use this hook if you don't want to persist query params in the URL.
 * If you want to persist query params in the URL, use useQueryParams hook.
 * Note: Not persisting query params in the URL means that, if users refresh the page, the query params will be lost.
 * @param defaultFilters - Initial filter values to populate the query params state. If not provided, defaults to an empty object.
 * @returns An object containing:
 *   - filters: Current filter values
 *   - sort: Current sort configuration
 *   - paginationParams: Current pagination state (page and pageSize)
 *   - updateQueryParams: Function to update filters, sort, and pagination values
 */
export function useInMemoryQueryParams<
  Resource,
  TFilters extends Record<string, unknown> | unknown,
  SortableFields = Array<string>,
>(defaultFilters?: TFilters, defaultSort?: SortableFields) {
  const [currentFilters, setCurrentFilters] = useState<Partial<TFilters>>(defaultFilters || {});
  const [currentSort, setCurrentSort] = useState<SortableFields>(defaultSort || ([] as SortableFields));
  const [currentPagination, setCurrentPagination] = useState<PaginationParams>({ number: 1, size: 10 });

  const updateQueryParams: UpdateParams<Resource, TFilters> = (queryParams: {
    filters?: TFilters;
    sort?: Sorter<Resource>[];
    paginationParams?: PaginationParams;
  }) => {
    const { filters, sort, paginationParams } = queryParams;
    if (filters) setCurrentFilters({ ...filters });
    if (paginationParams) setCurrentPagination({ ...paginationParams });
    if (currentSort) {
      const sortFields = fromSorter<SortableFields, Resource>(sort);
      setCurrentSort(sortFields || ([] as SortableFields));
    }
  };

  return {
    filters: currentFilters,
    sort: currentSort,
    paginationParams: currentPagination,
    updateQueryParams,
  };
}
