/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { useQueryClient } from '@tanstack/react-query';

import { useJobNotificationsQuery } from '@/hooks/query/useJobNotificationsQuery';
import { useSubscribeToEvents } from '@/hooks/useSubscribeToEvents';

import { JobBanner } from './JobBanner';

export type JobBannersProps = {
  tenantId?: string;
};

export function JobBanners({ tenantId }: JobBannersProps) {
  const queryClient = useQueryClient();
  const { data: notificationsResponse } = useJobNotificationsQuery(tenantId);
  const jobs = notificationsResponse?.data;

  useSubscribeToEvents(
    [
      'node:operational-state-changed',
      'node-config:assigned',
      'node:update-baseline',
      'job:updated',
      'job:completed',
      'job:timedout',
    ],
    () => {
      queryClient.invalidateQueries({ queryKey: ['job-notifications', tenantId] });
    },
  );

  if (!tenantId || !jobs?.length) return null;

  return (
    <div className="job-banners" data-testid="job-banners">
      {jobs.map((job) => (
        <JobBanner key={job.id} job={job} />
      ))}
    </div>
  );
}
