/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { FormInstance, Modal } from 'antd';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { PatchUser, UserResponse } from '@/client';
import { StatusText } from '@/components/StatusText';
import { useMessage } from '@/hooks/message';
import { useUpdateUserMutation } from '@/hooks/mutation/useUpdateUserMutation';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';

export type UpdateMemberModalProps = {
  children: React.ReactNode;
  form: FormInstance<PatchUser>;
};

export function UpdateMemberModal({ children, form }: UpdateMemberModalProps) {
  const { t } = useMimicTranslation('members');
  const navigate = useNavigate();
  const { userID } = useParams();
  const [open, setOpen] = useState(true);
  const [status, setStatus] = useState<'unconfirmed' | 'pending' | 'confirmed'>('unconfirmed');
  const [message] = useMessage();

  const closeModal = () => {
    setOpen(false);
  };

  const afterClose = () => {
    navigate(-1);
  };

  const { mutate } = useUpdateUserMutation({
    onError: (_, createUserDto) => {
      message.error(
        t('feedback.errorOnUpdate', {
          user: `${createUserDto.firstName} ${createUserDto.lastName} ${createUserDto.email}`,
        }),
      );
    },
    onSuccess: ({ data: userUpdated }: UserResponse) => {
      setStatus('confirmed');

      message.success(
        t('feedback.userUpdated', { user: `${userUpdated.firstName} ${userUpdated.lastName} ${userUpdated.email}` }),
      );

      closeModal();
    },
  });

  async function validateAndSubmit() {
    try {
      setStatus('pending');
      const userParams = await form.validateFields();
      mutate({ requestBody: { ...userParams }, id: userID! });
    } catch (error) {
      setStatus('unconfirmed');
    }
  }

  return (
    <Modal
      title={t('update.title')}
      centered
      afterClose={afterClose}
      open={open}
      onOk={() => validateAndSubmit()}
      onCancel={closeModal}
      confirmLoading={status === 'pending'}
      okButtonProps={{ disabled: status === 'pending' }}
      cancelButtonProps={{ disabled: status === 'pending' }}
      okText={
        <StatusText
          status={status}
          text={{
            unconfirmed: t('update.buttonTextUnconfirmed'),
            pending: t('update.buttonTextPending'),
            confirmed: t('update.buttonTextConfirmed'),
          }}
        />
      }
    >
      {children}
    </Modal>
  );
}
