/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { SetStateAction } from 'react';

import { PaginatedTagsResponse, Tag, TagFilters } from '@/client';
import { PaginatedTable } from '@/components/PaginatedTable';
import { useTagListColumns } from '@/pages/Tags/useTagListColumns';
import { UpdateParams } from '@/utils/params';

export type TagsListPageProps = {
  tagsData: PaginatedTagsResponse;
  onParamsChange: UpdateParams<Tag, TagFilters>;
  setEditTag: React.Dispatch<SetStateAction<Tag | undefined>>;
  setDeleteTag: React.Dispatch<SetStateAction<Tag | undefined>>;
};

export function TagsList({ tagsData, onParamsChange, setEditTag, setDeleteTag }: TagsListPageProps) {
  const searchText = tagsData.meta.filters.name || '';
  const columns = useTagListColumns(searchText, setEditTag, setDeleteTag);

  return <PaginatedTable data={tagsData} rowKey={(tag) => tag.id} columns={columns} onParamsChange={onParamsChange} />;
}
