/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { Job, JobFilters, PaginatedJobsResponse } from '@/client';
import { JobListTable } from '@/pages/Jobs/JobListTable';
import { Card } from '@/primitives/Card';
import { UpdateParams } from '@/utils/params';

export type NodeConfigJobsHistoryProps = {
  tenantID: string;
  jobsData: PaginatedJobsResponse;
  jobFilters: JobFilters;
  updateQueryParams: UpdateParams<Job, Partial<JobFilters>>;
};

export function NodeConfigJobsHistory({
  tenantID,
  jobsData,
  jobFilters,
  updateQueryParams,
}: NodeConfigJobsHistoryProps) {
  return (
    <Card padding="md" gap="sm">
      <JobListTable
        tenantID={tenantID}
        jobsData={jobsData}
        updateQueryParams={updateQueryParams}
        jobFilters={jobFilters}
      />
    </Card>
  );
}
