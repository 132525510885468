/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { BuildOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';

import { NodeLifecycleEvent } from '@/client';
import { GuardedLink } from '@/components/GuardedLink';
import { PaginatedTable } from '@/components/PaginatedTable';
import { QueryWrapper } from '@/components/QueryWrapper';
import { RelativeTime } from '@/components/RelativeTime';
import { usePaginatedLifeCycleEventsQuery } from '@/hooks/query/usePaginatedLifeCycleEventsQuery';
import { MimicTranslationFunction, useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Flex } from '@/primitives/Flex';
import { Text } from '@/primitives/Text';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export type LifecycleEventsProps = {
  nodeId: string;
};

export function LifecycleEvents({ nodeId }: LifecycleEventsProps) {
  const { t } = useMimicTranslation('node');
  const tenantID = useMgmtPlaneStateStore((state) => state.selectedTenantID);
  const nodeLifeCycleEventsQuery = usePaginatedLifeCycleEventsQuery({ nodeId, type: 'queryParams' });

  const columns: ColumnsType<NodeLifecycleEvent> = [
    {
      title: t('events'),
      dataIndex: 'msg',
      key: 'eventType',
      render: (_value, lifecycleEvent) => {
        return eventMessage(lifecycleEvent, tenantID!, t);
      },
    },
    {
      title: t('date'),
      dataIndex: 'eventTimestamp',
      key: 'eventTimestamp',
      render: (date: string) => {
        return <RelativeTime date={date} />;
      },
    },
  ];

  return (
    <QueryWrapper queryResult={nodeLifeCycleEventsQuery}>
      {(nodeLifeCycleEventsData) => (
        <PaginatedTable
          data={nodeLifeCycleEventsData}
          columns={columns}
          rowKey={(node) => node.id}
          style={{ width: '100%' }}
          onParamsChange={nodeLifeCycleEventsQuery.updateQueryParams}
        />
      )}
    </QueryWrapper>
  );
}

function eventMessage(
  lifecycleEvent: NodeLifecycleEvent,
  tenantId: string,
  t: MimicTranslationFunction<'node'>,
): React.ReactNode | string {
  const { eventType, configRevision } = lifecycleEvent;

  if (eventType === 'EVENT_TYPE_LIFECYCLE_NODE_CONFIG_APPLIED') {
    return (
      <Flex gap="xxs" align="center" wrap>
        <Text type="default">{t('eventsTable.appliedConfig')}</Text>
        <GuardedLink to={`/tenants/${tenantId}/node-configs/${configRevision?.nodeConfigID}`} requiredRole="editor">
          <BuildOutlined style={{ marginRight: '4px' }} />
          {configRevision?.nodeConfigName}
        </GuardedLink>
        <Text type="default">·</Text>
        <GuardedLink
          to={`/tenants/${tenantId}/node-configs/${configRevision?.nodeConfigID}/revisions/${configRevision?.revisionNumber}`}
          requiredRole="editor"
        >{`#${configRevision?.revisionNumber}`}</GuardedLink>
      </Flex>
    );
  }

  if (eventType === 'EVENT_TYPE_LIFECYCLE_NODE_CONFIG_REJECTED') {
    return (
      <Flex gap="xxs" align="center" wrap>
        <Text type="default">{t('eventsTable.rejectedConfig')}</Text>
        <GuardedLink to={`/tenants/${tenantId}/node-configs/${configRevision?.nodeConfigID}`} requiredRole="editor">
          <BuildOutlined style={{ marginRight: '2px' }} />
          {configRevision?.nodeConfigName}
        </GuardedLink>
        <Text type="default">·</Text>
        <GuardedLink
          requiredRole="editor"
          to={`/tenants/${tenantId}/node-configs/${configRevision?.nodeConfigID}/revisions/${configRevision?.revisionNumber}`}
        >{`#${configRevision?.revisionNumber}`}</GuardedLink>
      </Flex>
    );
  }

  if (eventType === 'EVENT_TYPE_LIFECYCLE_NODE_CONFIG_PENDING') {
    return (
      <Flex gap="xxs" align="center" wrap>
        <Text type="default">{t('eventsTable.pendingConfig')}</Text>
        <GuardedLink to={`/tenants/${tenantId}/node-configs/${configRevision?.nodeConfigID}`} requiredRole="editor">
          <BuildOutlined style={{ marginRight: '2px' }} />
          {configRevision?.nodeConfigName}
        </GuardedLink>
        <Text type="default">·</Text>
        <Flex gap="none">
          <GuardedLink
            requiredRole="editor"
            to={`/tenants/${tenantId}/node-configs/${configRevision?.nodeConfigID}/revisions/${configRevision?.revisionNumber}`}
          >{`#${configRevision?.revisionNumber}`}</GuardedLink>
          <Text type="default">…</Text>
        </Flex>
      </Flex>
    );
  }

  if (eventType === 'EVENT_TYPE_LIFECYCLE_NODE_CONFIG_OBTAINED') {
    return (
      <Flex gap="xxs" align="center" wrap>
        <Text type="default">{t('eventsTable.obtainedConfig')}</Text>
        <GuardedLink to={`/tenants/${tenantId}/node-configs/${configRevision?.nodeConfigID}`} requiredRole="editor">
          <BuildOutlined style={{ marginRight: '2px' }} />
          {configRevision?.nodeConfigName}
        </GuardedLink>
        <Text type="default">·</Text>
        <Flex gap="none">
          <GuardedLink
            requiredRole="editor"
            to={`/tenants/${tenantId}/node-configs/${configRevision?.nodeConfigID}/revisions/${configRevision?.revisionNumber}`}
          >{`#${configRevision?.revisionNumber}`}</GuardedLink>
          <Text type="default">…</Text>
        </Flex>
      </Flex>
    );
  }

  return lifecycleEvent.msg;
}
