/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Node } from '@/client';
import { QueryWrapper } from '@/components/QueryWrapper';
import { useMessage } from '@/hooks/message';
import { useAssignConfigRevisionMutation } from '@/hooks/mutation/useAssignConfigRevisionMutation';
import { useConfigRevisionByIdQuery } from '@/hooks/query/useConfigRevisionByIdQuery';
import { useNodeConfigByIdQuery } from '@/hooks/query/useNodeConfigByIdQuery';
import { usePaginatedNodesQuery } from '@/hooks/query/usePaginatedNodesQuery';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { ConfigRevisionInfo } from '@/pages/ConfigureNodesJobWizard/ConfigureNodesJobWizardSelectConfigRevision';
import { setJobWizardJobName } from '@/state/jobWizardStore';

import { ConfigureNodesJobWizardFooter } from './ConfigureNodesJobWizardFooter';
import { ConfigureNodesJobWizardReviewJobConfig } from './ConfigureNodesJobWizardReviewJobConfig';
import { ConfigureNodesJobWizardReviewJobNameCard } from './ConfigureNodesJobWizardReviewJobNameCard';
import { ConfigureNodesJobWizardReviewJobTable } from './ConfigureNodesJobWizardReviewJobTable';

export type ConfigureNodesJobWizardReviewJobProps = {
  tenantID: string;
  jobName?: string;
  selectedNodes: Node[];
  selectedConfigRevisionInfo: ConfigRevisionInfo;
  onSubmit: () => void;
};

export function ConfigureNodesJobWizardReviewJob({
  tenantID,
  jobName,
  selectedNodes,
  selectedConfigRevisionInfo,
  onSubmit,
}: ConfigureNodesJobWizardReviewJobProps) {
  const { t } = useMimicTranslation('configureNodesJobWizard');
  const navigate = useNavigate();
  const [message] = useMessage();
  const [submitting, setSubmitting] = useState(false);
  const [name, setName] = useState(jobName || `Configure ${selectedNodes.length} nodes`);
  const [error, setError] = useState(false);
  const { mutate: assignConfigRevision } = useAssignConfigRevisionMutation({
    onSuccess: () => {
      onSubmit();
      setSubmitting(false);
    },
    onError: () => {
      message.error(t('unableToStartJob'));
      setSubmitting(false);
    },
    onMutate: () => {
      setSubmitting(true);
    },
  });

  const predefinedSelectedIds = selectedNodes.map((node) => node.id);
  const predefinedFilters = predefinedSelectedIds
    ? { id: predefinedSelectedIds.join(','), isOperating: undefined }
    : { isOperating: undefined };

  const configQuery = useNodeConfigByIdQuery({ nodeConfigId: selectedConfigRevisionInfo.configId });

  const revisionQuery = useConfigRevisionByIdQuery({
    nodeConfigId: selectedConfigRevisionInfo.configId,
    configRevisionNumber: selectedConfigRevisionInfo.revisionNumber,
  });

  const nodesQuery = usePaginatedNodesQuery({
    params: { filters: predefinedFilters },
  });

  const onPrevious = () => {
    navigate(`#select-config`);
  };

  const onNext = async () => {
    if (!name) {
      setError(true);
      return;
    }

    assignConfigRevision({
      nodeIDs: selectedNodes.map((node) => node.id),
      configID: selectedConfigRevisionInfo.configId,
      revisionNumber: selectedConfigRevisionInfo.revisionNumber,
      jobName: name,
    });
  };

  const setJobName = (newName: string) => {
    setJobWizardJobName(newName);
    setName(newName);
  };

  return (
    <>
      <div className="configure-nodes-job-wizard-content">
        <div style={{ padding: '32px', paddingTop: 0, display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <ConfigureNodesJobWizardReviewJobNameCard
            jobName={name}
            error={error}
            setError={setError}
            setJobName={setJobName}
          />
          <QueryWrapper queryResult={configQuery}>
            {(configData) => (
              <QueryWrapper queryResult={revisionQuery}>
                {(revisionData) => (
                  <ConfigureNodesJobWizardReviewJobConfig
                    tenantID={tenantID}
                    selectedConfig={configData.data}
                    selectedRevision={revisionData.data}
                  />
                )}
              </QueryWrapper>
            )}
          </QueryWrapper>
          <QueryWrapper queryResult={nodesQuery}>
            {(nodesData) => (
              <ConfigureNodesJobWizardReviewJobTable
                tenantID={tenantID}
                nodesData={nodesData}
                onParamsChange={nodesQuery.updateQueryParams}
              />
            )}
          </QueryWrapper>
        </div>
      </div>
      <ConfigureNodesJobWizardFooter
        onPrevious={onPrevious}
        onNext={onNext}
        onNextLabel={t('startJob')}
        onNextIcon={null}
        error={error}
        errorMessage={t('jobNameRequired')}
        loading={submitting}
      />
    </>
  );
}
