/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Node } from '@/client/';
import { Aside } from '@/components/Aside';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Col } from '@/primitives/Col';
import { Row } from '@/primitives/Row';
import { Text } from '@/primitives/Text';
import { tokens } from '@/theme';

export type ExpandedRowProps = {
  node: Node;
};

export function ExpandedRow({ node }: ExpandedRowProps) {
  const { t } = useMimicTranslation('node');
  const colStyle = { fontSize: tokens.size.font.md };

  return (
    <Aside gap="sm">
      <Row>
        <Col span={4} style={colStyle}>
          {t('node_description')}
        </Col>
        <Col span={20} style={colStyle}>
          <Text>{node.description || 'N/A'}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={4} style={colStyle}>
          {t('node_id')}
        </Col>
        <Col span={20} style={colStyle}>
          <Text copyable mono>
            {node.id}
          </Text>
        </Col>
      </Row>
      <Row>
        <Col span={4} style={colStyle}>
          {t('node_config_id')}
        </Col>
        <Col span={20} style={colStyle}>
          <Text copyable mono>
            {node.appliedRevisionState?.nodeConfig?.id || 'N/A'}
          </Text>
        </Col>
      </Row>
      <Row>
        <Col span={4} style={colStyle}>
          {t('ip_address')}
        </Col>
        <Col span={20} style={colStyle}>
          <Text copyable mono>
            {node.systemProfile?.['host.ip'] || 'N/A'}
          </Text>
        </Col>
      </Row>
      <Row>
        <Col span={4} style={colStyle}>
          {t('last_seen')}
        </Col>
        <Col span={20} style={colStyle}>
          <Text>N/A</Text>
        </Col>
      </Row>
      <Row>
        <Col span={4} style={colStyle}>
          {t('registered')}
        </Col>
        <Col span={20} style={colStyle}>
          <Text>
            {new Date(node.dateCreated).toLocaleDateString()} {new Date(node.dateCreated).toLocaleTimeString()}
          </Text>
        </Col>
      </Row>
    </Aside>
  );
}
