/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { useQuery } from '@tanstack/react-query';

import { ApiError, GetTenantsResponse, User } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';

export type GetTenantListQueryProps = {
  currentUser?: User;
};

/**
 * Custom hook that fetches tenants
 *
 * Features:
 * - Fetches tenants
 * - Type-safe API response handling
 *
 * @returns React Query result containing tenants
 *
 * @example
 * ```tsx
 * const { data: tenants } = useTenantListQuery();
 * ```
 */
export function usePaginatedTenantQuery({ currentUser }: GetTenantListQueryProps) {
  return useQuery<GetTenantsResponse, ApiError>({
    queryKey: ['tenants'],
    queryFn: () => mgmtPlaneAPIClient.tenants.getTenants({ page: 1, pageSize: 100 }),
    staleTime: 5 * 1000,
    enabled: currentUser?.role === 'superadmin',
  });
}
