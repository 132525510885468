/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { ActivitiesFilter, Activity, PaginatedActivitiesResponse } from '@/client';
import { PaginatedTable } from '@/components/PaginatedTable';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { useActivitiesColumns } from '@/pages/Activity/useActivitiesColumns';
import { Result } from '@/primitives/Result';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { UpdateParams } from '@/utils/params';

export type ActivitiesListParams = {
  activitiesData: PaginatedActivitiesResponse;
  onParamsChange: UpdateParams<Activity, ActivitiesFilter>;
  isLoading: boolean;
};

export function ActivitiesList({ activitiesData, onParamsChange, isLoading }: ActivitiesListParams) {
  const { t } = useMimicTranslation('activity');
  const tenantID = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  const columns = useActivitiesColumns(tenantID!);

  const showEmpty = !isLoading && !!activitiesData && activitiesData.meta.page.totalItems === 0;
  const showData = !showEmpty;

  return (
    <>
      {showEmpty && (
        <div style={{ backgroundColor: 'white', marginTop: '15px', padding: '100px', width: '100%' }}>
          <Result status="success" title={t('noWorries')} subTitle={t('allClear')} />
        </div>
      )}
      {showData && (
        <PaginatedTable
          data={activitiesData}
          rowKey={(tag) => tag.nodeID}
          columns={columns}
          onParamsChange={onParamsChange}
        />
      )}
    </>
  );
}
