/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Form } from 'antd';
import { useState } from 'react';

import { CreateUser } from '@/client';
import { InviteMemberForm } from '@/pages/InviteMember/InviteMemberForm';
import { InviteMemberModal } from '@/pages/InviteMember/InviteMemberModal';

export function InviteMemberPage() {
  const [form] = Form.useForm<CreateUser>();
  const [selectedRole, setSelectedRole] = useState<string>('editor');

  return (
    <InviteMemberModal setSelectedRole={setSelectedRole} form={form}>
      <InviteMemberForm form={form} selectedRole={selectedRole} setSelectedRole={setSelectedRole} />
    </InviteMemberModal>
  );
}
