/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { ApiError, GetConfigResponse } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { useSubscribeToEvents } from '@/hooks/useSubscribeToEvents';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export type UseNodeConfigByIdQueryProps = {
  nodeConfigId: string;
};

/**
 * Custom hook that fetches and subscribes to node config data updates.
 * Combines React Query for data fetching with WebSocket subscriptions for real-time updates.
 *
 * Features:
 * - Fetches node config data by ID
 * - Auto-invalidates query on relevant WebSocket events
 * - Handles tenant context
 * - Type-safe API response handling
 *
 * @param props.nodeConfigId - Unique identifier of the node config to fetch
 * @returns React Query result containing node config data and query state
 *
 * @example
 * ```tsx
 * const { data: node, isLoading } = useNodeConfigByIdQuery({ nodeConfigId: '123' });
 * ```
 */
export function useNodeConfigByIdQuery({ nodeConfigId }: UseNodeConfigByIdQueryProps) {
  const queryClient = useQueryClient();
  const selectedTenantId = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  useSubscribeToEvents(['node-config:updated', 'node-config-revision:created', 'node-config:assigned'], (payload) => {
    if (!payload.entityIds.includes(nodeConfigId)) return;
    queryClient.invalidateQueries({ queryKey: ['node-config', nodeConfigId] });
  });

  return useQuery<GetConfigResponse, ApiError>({
    queryKey: ['node-config', nodeConfigId],
    queryFn: () => {
      return mgmtPlaneAPIClient.nodeConfigs.getConfig({ configId: nodeConfigId, tenantId: selectedTenantId! });
    },
    enabled: !!selectedTenantId && !!nodeConfigId,
  });
}
