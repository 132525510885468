/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import dayjs from 'dayjs';

import { ConnectivityState, HeartbeatConnectivityState, NodeHeartbeatStates } from '@/client';
import { ConnectivityStateBadge } from '@/components/States/ConnectivityStateBadge';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Flex } from '@/primitives/Flex';
import { Space } from '@/primitives/Space';
import { Text } from '@/primitives/Text';
import { Tooltip } from '@/primitives/Tooltip';

export type ConnectivityTimeProps = {
  state: ConnectivityState;
  lastSeen?: string;
  heartbeatStates?: NodeHeartbeatStates;
  vertical?: boolean;
};

enum HeartbeatType {
  CONFIG = 'last_configuration_heartbeat',
  CREDENTIAL = 'last_credentials_heartbeat',
  UNKNOWN = 'unknown_heartbeat',
}

type MissingHeartbeat = {
  heartbeatType: HeartbeatType;
  date: string;
};

const OFFLINE: HeartbeatConnectivityState = 'offline';

// find the missing heartbeat type and last seen time
function findMissingHeartbeat(lastSeen: string, heartbeatStates?: NodeHeartbeatStates): MissingHeartbeat {
  if (heartbeatStates?.config.state === OFFLINE && heartbeatStates.credential.state === OFFLINE) {
    // Assume the NEWEST heartbeat is the one that is missing.
    // This in unintuitive, but the newest time is the last time the node was seen.
    if (heartbeatStates.config.stateTime > heartbeatStates.credential.stateTime) {
      return { heartbeatType: HeartbeatType.CONFIG, date: heartbeatStates.config.stateTime };
    }
    return { heartbeatType: HeartbeatType.CREDENTIAL, date: heartbeatStates.credential.stateTime };
  }
  if (heartbeatStates?.config.state === OFFLINE) {
    return { heartbeatType: HeartbeatType.CONFIG, date: heartbeatStates.config.stateTime };
  }
  if (heartbeatStates?.credential.state === OFFLINE) {
    return { heartbeatType: HeartbeatType.CREDENTIAL, date: heartbeatStates.credential.stateTime };
  }

  const lastSeenDate = dayjs(lastSeen).format('D MMM YYYY HH:mm:ss');
  return { heartbeatType: HeartbeatType.UNKNOWN, date: lastSeenDate };
}

export function ConnectivityTime({ state, lastSeen, heartbeatStates, vertical }: ConnectivityTimeProps) {
  const { t } = useMimicTranslation('node');

  if (!state || !lastSeen) return '-';

  const { heartbeatType, date } = findMissingHeartbeat(lastSeen, heartbeatStates);
  const time = dayjs(date).utc().format('D MMM YYYY HH:mm:ss');
  const connected = state === 'connected';
  const title = state ? t(`connectivityStateDesc.${state}`) : t('connectivityStateDesc.unknown');
  const subtitle = connected ? null : <Text size="md">{t(heartbeatType)}</Text>;
  const timeText = connected ? 'Just Now' : time;

  const children = vertical ? (
    <Flex vertical gap="xxs" align="flex-end">
      <ConnectivityStateBadge state={state} size="lg" />
      <Text type="subtle" size="md">
        {timeText}
      </Text>
    </Flex>
  ) : (
    <Space>
      <ConnectivityStateBadge state={state} showLabel={false} />
      {timeText}
    </Space>
  );

  const tooltip = (
    <Space dir="vertical" compact>
      <Space>
        <ConnectivityStateBadge state={state} showLabel={false} />
        <Text size="md" strong>
          {title}
        </Text>
      </Space>
      {subtitle}
    </Space>
  );
  // The fragment is unnecessary for the tooltip to work
  /* eslint-disable react/jsx-no-useless-fragment */
  return (
    <Tooltip title={tooltip}>
      <>{children}</>
    </Tooltip>
  );
}
