/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Button } from '@/primitives/Button';
import { Flex } from '@/primitives/Flex';
import Flaming403 from '@/primitives/Icons/Flamingo403.svg?react';
import Flaming404 from '@/primitives/Icons/Flamingo404.svg?react';
import Flaming500 from '@/primitives/Icons/Flamingo500.svg?react';
import { Result } from '@/primitives/Result';
import { Text } from '@/primitives/Text';

export type HTTPErrorProps = {
  httpStatus: '403' | '404' | '500';
};

type ErrorMessages = {
  [key in HTTPErrorProps['httpStatus']]: {
    title: 'forbiddenError' | 'notFoundError' | 'unexpectedError';
    subTitle: 'forbiddenErrorDescriptionOne' | 'notFoundErrorDescription' | 'unexpectedErrorDescription';
    subTitleTwo?: 'forbiddenErrorDescriptionTwo';
    icon?: React.ReactNode;
  };
};

const errorMessages: ErrorMessages = {
  '403': {
    title: 'forbiddenError',
    subTitle: 'forbiddenErrorDescriptionOne',
    subTitleTwo: 'forbiddenErrorDescriptionTwo',
    icon: <Flaming403 fontSize="20em" />,
  },
  '404': {
    title: 'notFoundError',
    subTitle: 'notFoundErrorDescription',
    icon: <Flaming404 fontSize="20em" />,
  },
  '500': {
    title: 'unexpectedError',
    subTitle: 'unexpectedErrorDescription',
    icon: <Flaming500 fontSize="20em" />,
  },
};

export function HTTPError({ httpStatus }: HTTPErrorProps) {
  const { t } = useMimicTranslation('error');
  const navigate = useNavigate();
  const { icon, title, subTitle, subTitleTwo } = errorMessages[httpStatus];

  const titleText = <Text style={{ fontSize: '2.75rem', fontWeight: 600 }}>{t(title)}</Text>;

  const subTitleText = (
    <Flex vertical gap="xxs" align="center">
      <Text data-testid="subTitle-one" type="subtle" size="xl">
        {t(subTitle)}
      </Text>
      {subTitleTwo && (
        <Text data-testid="subTitle-two" type="subtle" size="xl">
          {t(subTitleTwo)}
        </Text>
      )}
      <Button
        dataTestId="go-back-button"
        type="primary"
        style={{ height: '44px', width: '244px', marginTop: '30px' }}
        icon={<ArrowLeftOutlined />}
        onClick={() => navigate(-1)}
      >
        {t('goBack')}
      </Button>
    </Flex>
  );
  return (
    <div data-testid={`http-error-${httpStatus}`}>
      <Result icon={icon} status={!icon ? httpStatus : undefined} title={titleText} subTitle={subTitleText} />
    </div>
  );
}
