/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { ApiError, GetJobResponse } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { useSubscribeToEvents } from '@/hooks/useSubscribeToEvents';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export type UseJobByIdQueryProps = {
  jobId: string;
};

/**
 * Custom hook that fetches and subscribes to job data updates.
 * Combines React Query for data fetching with WebSocket subscriptions for real-time updates.
 *
 * Features:
 * - Fetches job data by ID
 * - Auto-invalidates query on relevant WebSocket events
 * - Handles tenant context
 * - Type-safe API response handling
 *
 * @param props.jobId - Unique identifier of the job to fetch
 * @returns React Query result containing job data and query state
 *
 * @example
 * ```tsx
 * const { data: job, isLoading } = useJobByIdQuery({ jobId: '123' });
 * ```
 */
export function useJobByIdQuery({ jobId }: UseJobByIdQueryProps) {
  const queryClient = useQueryClient();
  const selectedTenantId = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  useSubscribeToEvents(['job:updated', 'job:completed', 'job:timedout'], (payload) => {
    if (!payload.entityIds.includes(jobId)) return;
    queryClient.invalidateQueries({ queryKey: ['jobs', selectedTenantId, jobId] });
  });

  return useQuery<GetJobResponse, ApiError>({
    queryKey: ['jobs', selectedTenantId, jobId],
    queryFn: () => {
      return mgmtPlaneAPIClient.jobs.getJob({ jobId, tenantId: selectedTenantId! });
    },
    enabled: !!selectedTenantId && !!jobId,
  });
}
