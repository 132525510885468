/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { CheckOutlined } from '@ant-design/icons';

import { Text } from '@/primitives/Text';

export type StatusTextProps = {
  status: 'unconfirmed' | 'pending' | 'confirmed';
  text: {
    unconfirmed: string;
    pending: string;
    confirmed: string;
  };
  dataTestId?: string;
};

export function StatusText({ status, text, dataTestId }: StatusTextProps) {
  if (status === 'unconfirmed') {
    return <Text data-testid={dataTestId}>{text.unconfirmed}</Text>;
  }

  if (status === 'pending') {
    return <Text data-testid={dataTestId}>{text.pending}</Text>;
  }
  return (
    <>
      <CheckOutlined data-testid="check-mark" />
      <Text data-testid={dataTestId}>{text.confirmed}</Text>
    </>
  );
}
