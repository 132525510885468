/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { z } from 'zod';
import {
  PaginationParams,
  buildPaginatedResponseSchema,
  sortable,
} from '../../openapi';
import { OPENAPI_NODE_CONNECTIVITY_STATE, OPENAPI_NODE_ID } from '../../types';
import { NodeHeartbeatStates, NodeName } from '../nodes/schemas';

// base schema
export const ActivitySchema = z
  .object({
    nodeID: OPENAPI_NODE_ID,
    nodeName: NodeName,
    nodeConnectivity: OPENAPI_NODE_CONNECTIVITY_STATE,
    nodeHeartbeatStates: NodeHeartbeatStates.optional(),
    hallmarkCount: z.number().int(),
    deflectionsCount: z.number().int(),
    lastSeen: z.date().openapi({ format: 'date-time' }),
  })
  .openapi('Activity');

export const ActivitiesFilterParamsSchema = z
  .object({
    nodeName: NodeName.optional().openapi({ example: 'server-name' }),
  })
  .openapi('ActivitiesFilter', { example: { nodeName: 'server-name' } });

export const ActivitiesSortableFields = sortable(
  'ActivitiesSortableFields',
  [],
);

export const ActivitiesQueryParamsSchema = PaginationParams.merge(
  z.object({
    filters: ActivitiesFilterParamsSchema.optional(),
    sort: ActivitiesSortableFields.optional(),
  }),
);

// response schemas
export const PaginatedActivitiesResponseSchema = buildPaginatedResponseSchema(
  'PaginatedActivitiesResponse',
  ActivitySchema,
  ActivitiesFilterParamsSchema,
  ActivitiesSortableFields,
);
