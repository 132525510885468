/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { LifecycleFilterParamsSchema } from 'openapi-spec/api/routes/node-events/schemas';

import {
  GetLifecycleEventsByNodeData,
  GetLifecycleEventsByNodeResponse,
  LifecycleFilters,
  LifecycleSortableFields,
  NodeLifecycleEvent,
} from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { usePaginatedQuery } from '@/hooks/query/usePaginatedQuery';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { zodSchemaToTypeMap } from '@/utils/filters';

export type UsePaginatedNodeLifeCycleEventsQueryParams = {
  params?: Omit<GetLifecycleEventsByNodeData, 'tenantId'>;
  type?: 'inMemory' | 'queryParams';
  overRideParamFilters?: boolean;
  nodeId: string;
};

/**
 * Custom hook that fetches paginated life cycle events with filtering, sorting and real-time updates.
 * Combines React Query for data fetching with WebSocket subscriptions for real-time updates.
 *
 * @param options Configuration object:
 *   - params: Optional query parameters (filters, pagination, sort)
 *   - type: Storage type - 'inMemory' or 'queryParams' for URL persistence
 *
 * @returns Object containing:
 *   - data: Paginated life cycle events response
 *   - isLoading: Boolean for initial load state
 *   - isFetching: Boolean for any loading state
 *   - isError: Boolean for error state
 *   - error: Error object if request failed
 *   - updateQueryParams: Function to update filters, sort, and pagination
 *
 * Note: tenantId is automatically pulled from the management plane state store
 */
export function usePaginatedLifeCycleEventsQuery({
  params,
  type,
  overRideParamFilters = false,
  nodeId,
}: UsePaginatedNodeLifeCycleEventsQueryParams) {
  const selectedTenantId = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  return usePaginatedQuery<
    GetLifecycleEventsByNodeResponse,
    NodeLifecycleEvent,
    LifecycleFilters,
    LifecycleSortableFields,
    GetLifecycleEventsByNodeData
  >({
    apiCall: (fnParams: GetLifecycleEventsByNodeData) =>
      mgmtPlaneAPIClient.nodeLifecycleEvents.getLifecycleEventsByNode(fnParams),
    filterTypes: zodSchemaToTypeMap(LifecycleFilterParamsSchema.shape),
    overRideParamFilters,
    queryKey: 'node-lifecycle-events',
    realtimeEvents: ['node:credential-rotated', 'node:connectivity-updated', 'node:new-lifecycle-event'],
    params: {
      tenantId: selectedTenantId!,
      nodeId,
      ...params,
    },
    type,
  });
}
