/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Result as AntDResult } from 'antd';
import React from 'react';

export interface ResultProps {
  status?: '403' | '404' | '500' | 'success';
  title: string | React.ReactNode;
  subTitle?: React.ReactNode;
  extra?: React.ReactNode;
  icon?: React.ReactNode;
}

export function Result({ status, title, subTitle, extra, icon }: ResultProps) {
  return <AntDResult icon={icon} status={status} title={title} subTitle={subTitle} extra={extra} />;
}
