/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ClearHallmarks, ClearHallmarksResponseSchema } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';

export type ClearHallmarksMutationConfig = {
  onSuccess?: (data: ClearHallmarksResponseSchema, variables: ClearHallmarks) => void;
  onError?: (error: unknown, variables: ClearHallmarks) => void;
  onSettled?: (data: unknown, error: unknown, variables: unknown) => void;
};

/**
 * Hook for clearing hallmarks by node ID through the management plane API.
 *
 * @param config.onSuccess - Called after successful clearing of hallmarks
 * @param config.onError - Called if clearing fails
 * @param config.onSettled - Called after mutation completes (success or failure)
 *
 * @returns React Query mutation result for clearing hallmarks
 *
 * @example
 * ```tsx
 * const { mutate } = useClearHallmarksMutation({
 *   onSuccess: (data) => console.log('Hallmarks cleared:', data),
 *   onError: (error) => console.error('Clearing hallmarks failed:', error)
 * });
 *
 * // Clear Hallmarks
 * mutate({ reason: 'some reason here' });
 * ```
 */

export function useClearHallmarksMutation(tenantId: string, nodeId: string, config?: ClearHallmarksMutationConfig) {
  const queryClient = useQueryClient();

  return useMutation<ClearHallmarksResponseSchema, unknown, ClearHallmarks>({
    mutationFn: (form: ClearHallmarks) => {
      return mgmtPlaneAPIClient.nodes.clearHallmarks({ tenantId, nodeId, requestBody: form });
    },
    onSuccess: (data, variables) => {
      if (config?.onSuccess) {
        config.onSuccess(data, variables);
      }
      queryClient.invalidateQueries({ queryKey: ['node-hallmarks'] });
      queryClient.invalidateQueries({ queryKey: ['node', tenantId, nodeId] });
      queryClient.invalidateQueries({ queryKey: ['activities', tenantId] });
    },
    onError: config?.onError,
    onSettled: config?.onSettled,
  });
}
