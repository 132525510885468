/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { useMutation } from '@tanstack/react-query';

import { ApiError, RequestedOperationalState, OperationalStateChangeResp } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export type UpdateNodeOperationalStateMutationConfig = {
  onSuccess?: (data: unknown, variables: unknown) => void;
  onError?: (error: unknown, variables: UpdateNodeOperationState) => void;
  onSettled?: (data: unknown, variables: unknown) => void;
  onMutate?: (variables: UpdateNodeOperationState) => void;
};

export type UpdateNodeOperationState = {
  nodeId: string;
  updateOperationalState: RequestedOperationalState;
};

/**
 * Hook for updating user node operational state by ID through the management plane API.
 *
 * @param config.onSuccess - Called after successful operational state update
 * @param config.onError - Called if update fails
 * @param config.onSettled - Called after mutation completes (success or failure)
 *
 * @returns React Query mutation result for updating node operational state
 *
 * @example
 * ```tsx
 * const { mutate } = useUpdateNodeOperationalStateMutation({
 *   onSuccess: (data) => console.log('Operational state updated:', data),
 *   onError: (error) => console.error('Operational state update failed:', error)
 * });
 *
 * // Update node operational state
 * mutate({ nodeId: '123', updateOperationalState: { requestedOperationalState: 'active' } });
 * ```
 */

export function useUpdateNodeOperationalStateMutation({
  onSuccess,
  onError,
  onSettled,
  onMutate,
}: UpdateNodeOperationalStateMutationConfig) {
  const tenantId = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  const { mutate, isError, error } = useMutation<OperationalStateChangeResp, ApiError, UpdateNodeOperationState>({
    mutationFn: ({ nodeId, updateOperationalState }: UpdateNodeOperationState) => {
      return mgmtPlaneAPIClient.nodes.changeNodeOperationalState({
        tenantId: tenantId!,
        id: nodeId,
        requestBody: updateOperationalState,
      });
    },
    onSuccess,
    onError,
    onSettled,
    onMutate,
  });

  return { mutate, isError, error };
}
