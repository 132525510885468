/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { useQuery } from '@tanstack/react-query';

import { ApiError, GetConfigRevisionResponse } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export type UseConfigRevisionByIdQueryProps = {
  nodeConfigId: string;
  configRevisionNumber: number;
};

/**
 * Custom hook that fetches and subscribes to node config revision data updates.
 * Combines React Query for data fetching with WebSocket subscriptions for real-time updates.
 *
 * Features:
 * - Fetches node config revision data by ID
 * - Auto-invalidates query on relevant WebSocket events
 * - Handles tenant context
 * - Type-safe API response handling
 *
 * @param props.nodeConfigId - Unique identifier of the node config revision to fetch
 * @param props.configRevisionNumber - Unique identifier of the node config revision to fetch
 * @returns React Query result containing node config revision data and query state
 *
 * @example
 * ```tsx
 * const { data: node, isLoading } = useNodeConfigByIdQuery({ nodeConfigId: '123', configRevisionNumber: 1 });
 * ```
 */
export function useConfigRevisionByIdQuery({ nodeConfigId, configRevisionNumber }: UseConfigRevisionByIdQueryProps) {
  const selectedTenantId = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  return useQuery<GetConfigRevisionResponse, ApiError>({
    queryKey: ['config-revisions', nodeConfigId, configRevisionNumber],
    queryFn: () => {
      return mgmtPlaneAPIClient.nodeConfigs.getConfigRevision({
        configId: nodeConfigId,
        configRevisionNumber,
        tenantId: selectedTenantId!,
      });
    },
    enabled: !!selectedTenantId && !!nodeConfigId && !!configRevisionNumber,
  });
}
