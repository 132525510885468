/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { ConfigRevision, Node, NodeConfig } from '@/client';
import { NodeConfigsList } from '@/components/ConfigWizardOverlay/NodeConfigsList';
import { usePaginatedNodeConfigsQuery } from '@/hooks/query/usePaginatedNodeConfigsQuery';
import { Card } from '@/primitives/Card';

export type SelectNodeConfigCardProps = {
  node: Node;
  selectedNodeConfig?: NodeConfig;
  onNodeConfigSelect: (nodeConfig?: NodeConfig) => void;
};

export function SelectNodeConfigCard({ node, selectedNodeConfig, onNodeConfigSelect }: SelectNodeConfigCardProps) {
  const configsQuery = usePaginatedNodeConfigsQuery({
    params: {
      filters: { os: node.operatingSystem },
    },
  });

  const getNodeConfigPath = (nodeConfig: NodeConfig) => `/tenants/${node.tenantId}/node-configs/${nodeConfig.id}`;
  const getNodeConfigurationRevisionPath = (nodeConfigRevision: ConfigRevision) =>
    `/tenants/${node.tenantId}/node-configs/${nodeConfigRevision.nodeConfigId}/revisions/${nodeConfigRevision.revisionNumber}`;

  return (
    <Card dataTestId="config-wizard-select-node-config" padding="sm" bordered>
      <NodeConfigsList
        tags={[]} // TODO: adds tags MP-1656
        onFilterAndSortChange={configsQuery.updateQueryParams}
        nodeConfigsQuery={configsQuery}
        getNodeConfigPath={getNodeConfigPath}
        getNodeConfigurationRevisionPath={getNodeConfigurationRevisionPath}
        onNodeConfigSelect={onNodeConfigSelect}
        defaultNodeConfig={selectedNodeConfig}
      />
    </Card>
  );
}
