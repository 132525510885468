/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useMutation } from '@tanstack/react-query';

import { Job, JobResponse, UpdateJob, UpdateJobResponse } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';

export type UpdateJobMutationConfig = {
  onSuccess?: (data: JobResponse, variables: UpdateJobRequest) => void;
  onError?: (error: unknown, variables: UpdateJobRequest) => void;
  onSettled?: (data: unknown, error: unknown, variables: unknown) => void;
  onMutate?: (variables: UpdateJobRequest) => Promise<unknown> | void;
};

export type UpdateJobRequest = {
  job: Job;
  updateJobPayload: UpdateJob;
};

/**
 * Hook for updating job by ID through the management plane API.
 *
 * @param config.onSuccess - Called after successful job update
 * @param config.onError - Called if update fails
 * @param config.onSettled - Called after mutation completes (success or failure)
 *
 * @returns React Query mutation result for updating job
 *
 * @example
 * ```tsx
 * const { mutate } = useUpdateJobMutation({
 *   onSuccess: (data) => console.log('Job updated:', data),
 *   onError: (error) => console.error('Job update failed:', error)
 * });
 *
 * // Update Job
 * mutate({
 *   job,
 *   updateJobPayload: { notificationDismissed: true },
 * });
 * ```
 */

export function useUpdateJobMutation({ onSuccess, onError, onSettled, onMutate }: UpdateJobMutationConfig) {
  return useMutation<UpdateJobResponse, unknown, UpdateJobRequest>({
    mutationFn: (jobRequest: UpdateJobRequest) => {
      return mgmtPlaneAPIClient.jobs.updateJob({
        jobId: jobRequest.job.id,
        requestBody: jobRequest.updateJobPayload,
        tenantId: jobRequest.job.tenantId,
      });
    },
    onSuccess,
    onError,
    onSettled,
    onMutate,
  });
}
