/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { ContentContainer } from '@/components/ContentContainer';
import { PageHeader } from '@/components/PageHeader';
import { QueryWrapper } from '@/components/QueryWrapper';
import { usePaginatedJobsQuery } from '@/hooks/query/usePaginatedJobsQuery';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { JobListTable } from '@/pages/Jobs/JobListTable';
import { Container } from '@/primitives/Container';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export function JobsPage() {
  const tenantID = useMgmtPlaneStateStore((state) => state.selectedTenantID);
  const { t } = useMimicTranslation('jobs');

  const jobsQuery = usePaginatedJobsQuery({
    type: 'queryParams',
  });

  if (!tenantID) return null;
  return (
    <ContentContainer width="100%">
      <Container full style={{ width: '100%' }}>
        <PageHeader title={t('listTitle')} level={2} />
        <QueryWrapper queryResult={jobsQuery}>
          {(jobsData) => (
            <JobListTable
              tenantID={tenantID}
              jobsData={jobsData}
              updateQueryParams={jobsQuery.updateQueryParams}
              jobFilters={jobsQuery.filters}
            />
          )}
        </QueryWrapper>
      </Container>
    </ContentContainer>
  );
}
