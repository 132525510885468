/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useNavigate } from 'react-router-dom';

import { PaginatedUsersResponse, User, UserFilters, UserResponse } from '@/client';
import { PaginatedTable } from '@/components/PaginatedTable';
import { useMessage } from '@/hooks/message';
import { useUpdateUserMutation } from '@/hooks/mutation/useUpdateUserMutation';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Role } from '@/pages/Members/MemberTable';
import { useActiveUsersColumns } from '@/pages/Members/useActiveUsersColumns';
import { useRemovedUsersColumns } from '@/pages/Members/useRemovedUsersColumns';
import { Container } from '@/primitives/Container';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { UpdateParams } from '@/utils/params';

export type MembersListPageProps = {
  membersData: PaginatedUsersResponse;
  filters: UserFilters;
  onParamsChange: UpdateParams<User, UserFilters>;
};

export function MembersList({ membersData, filters, onParamsChange }: MembersListPageProps) {
  const { t } = useMimicTranslation('members');
  const navigate = useNavigate();
  const currentUser = useMgmtPlaneStateStore((state) => state.userProfile);
  const tenantID = useMgmtPlaneStateStore((state) => state.selectedTenantID);
  const [message] = useMessage();

  const { mutate: updateUserMutation } = useUpdateUserMutation({
    onError: (_, updateUserDto) => {
      const displayName = `${updateUserDto.firstName} ${updateUserDto.lastName}`;
      message.error(t('feedback.errorOnUpdate', { user: `${displayName}` }));
    },
    onSuccess: ({ data: userUpdated }: UserResponse) => {
      message.success(t('feedback.userUpdated', { user: `${userUpdated.displayName}` }));
    },
  });

  const updateUserRole = (user: User, role: Role) => {
    updateUserMutation({ ...user, requestBody: { role } });
  };

  const updateUserRemoved = (user: User, removed: boolean) => {
    updateUserMutation({ ...user, requestBody: { removed } });
  };

  const openMemberUpdateModal = (user: User) => {
    navigate(`/tenants/${tenantID}/members/update/${user.id}`);
  };

  const removedUserColumns = useRemovedUsersColumns(currentUser!, updateUserRemoved);
  const activeColumns = useActiveUsersColumns(currentUser!, openMemberUpdateModal, updateUserRole, updateUserRemoved);

  const columns = filters.removed ? removedUserColumns : activeColumns;

  return (
    <Container>
      <PaginatedTable
        dark={filters.removed}
        data-testid="member-table"
        rowKey={(tag) => tag.id}
        data={membersData}
        columns={columns}
        onParamsChange={onParamsChange}
      />
    </Container>
  );
}
