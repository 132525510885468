/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { NodeOperatingSystem } from '@/client';
import { QueryWrapper } from '@/components/QueryWrapper';
import { useConfigRevisionByIdQuery } from '@/hooks/query/useConfigRevisionByIdQuery';
import { usePaginatedNodeConfigsQuery } from '@/hooks/query/usePaginatedNodeConfigsQuery';
import { usePaginatedNodesQuery } from '@/hooks/query/usePaginatedNodesQuery';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Flex } from '@/primitives/Flex';
import { getJobWizardSelectedConfigRevisionInfo, setJobWizardSelectedConfigRevisionInfo } from '@/state/jobWizardStore';

import { ConfigureNodesJobWizardFooter } from './ConfigureNodesJobWizardFooter';
import { ConfigureNodesJobWizardSelectConfigRevisionCode } from './ConfigureNodesJobWizardSelectConfigRevisionCode';
import { ConfigureNodesJobWizardSelectConfigRevisionMain } from './ConfigureNodesJobWizardSelectConfigRevisionMain';
import { ConfigureNodesJobWizardSelectConfigRevisionSideBar } from './ConfigureNodesJobWizardSelectConfigRevisionSideBar';

export type ConfigureNodesJobWizardSelectConfigRevisionProps = {
  tenantID: string;
  selectedNodes: { id: string; operatingSystem: NodeOperatingSystem }[];
};

export type ConfigRevisionInfo = {
  configId: string;
  revisionId: string;
  revisionNumber: number;
};

export function ConfigureNodesJobWizardSelectConfigRevision({
  tenantID,
  selectedNodes,
}: ConfigureNodesJobWizardSelectConfigRevisionProps) {
  const { t } = useMimicTranslation('configureNodesJobWizard');
  const [showConfigRevisionCode, setShowConfigRevisionCode] = useState(false);
  const [error, setError] = useState(false);
  const [errorMesssaage, setErrorMessage] = useState<string | undefined>(undefined);
  const [selectedConfigRevisionInfo, setSelectedConfigRevisionInfo] = useState<ConfigRevisionInfo>(
    getJobWizardSelectedConfigRevisionInfo(),
  );

  const setSelectedConfigurationInfo = (revision: ConfigRevisionInfo) => {
    setSelectedConfigRevisionInfo(revision);
    setJobWizardSelectedConfigRevisionInfo(revision);
  };

  const nodeConfigsQuery = usePaginatedNodeConfigsQuery({});

  const nodeIDs = selectedNodes.map((node) => node.id);

  const nodesQuery = usePaginatedNodesQuery({
    params: {
      filters: { id: nodeIDs.join(','), isOperating: undefined },
      sort: [],
      page: 1,
      pageSize: selectedNodes.length,
    },
  });

  const configRevisionQuery = useConfigRevisionByIdQuery({
    nodeConfigId: selectedConfigRevisionInfo.configId,
    configRevisionNumber: selectedConfigRevisionInfo.revisionNumber,
  });

  const navigate = useNavigate();

  const onPrevious = () => {
    navigate(`#select-nodes`);
  };

  const onNext = () => {
    if (!selectedConfigRevisionInfo?.configId) {
      setError(true);
      setErrorMessage(t('selectARevision'));
      return;
    }
    navigate(`#review-job`);
  };

  return (
    <>
      <div className="configure-nodes-job-wizard-content">
        <div>
          <Flex>
            {!showConfigRevisionCode && (
              <QueryWrapper queryResult={nodesQuery}>
                {(nodesData) => (
                  <ConfigureNodesJobWizardSelectConfigRevisionSideBar
                    tenantID={tenantID}
                    selectedNodes={nodesData.data}
                  />
                )}
              </QueryWrapper>
            )}
            <QueryWrapper queryResult={nodeConfigsQuery}>
              {(nodeConfigsData) => (
                <ConfigureNodesJobWizardSelectConfigRevisionMain
                  selectedNodes={selectedNodes}
                  nodeConfigsData={nodeConfigsData}
                  updateQueryParams={nodeConfigsQuery.updateQueryParams}
                  selectedConfigRevisionInfo={selectedConfigRevisionInfo}
                  setSelectedConfigRevisionInfo={setSelectedConfigurationInfo}
                  setShowConfigCode={setShowConfigRevisionCode}
                  setError={setError}
                />
              )}
            </QueryWrapper>
            {showConfigRevisionCode && (
              <QueryWrapper queryResult={configRevisionQuery}>
                {(configRevisionData) => (
                  <ConfigureNodesJobWizardSelectConfigRevisionCode
                    configRevision={configRevisionData.data}
                    setShowConfigCode={setShowConfigRevisionCode}
                  />
                )}
              </QueryWrapper>
            )}
          </Flex>
        </div>
      </div>
      <ConfigureNodesJobWizardFooter
        onPrevious={onPrevious}
        onNext={onNext}
        onNextLabel={t('reviewJob')}
        onNextIcon={null}
        error={error}
        errorMessage={errorMesssaage}
      />
    </>
  );
}
