/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useMutation } from '@tanstack/react-query';

import { ApiError, PatchUser, UpdateUserResponse, UpdateUserData, UserResponse } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export type UpdateUserMutation = PatchUser & { id: string };

export type UpdateUserMutationConfig = {
  onSuccess?: (data: UserResponse, variables: UpdateUserMutation) => void;
  onError?: (error: unknown, variables: UpdateUserMutation) => void;
  onSettled?: (data: unknown, error: unknown, variables: unknown) => void;
  onMutate?: (variables: UpdateUserMutation) => void;
};

/**
 * Hook for updating user by ID through the management plane API.
 *
 * @param config.onSuccess - Called after successful user update
 * @param config.onError - Called if update fails
 * @param config.onSettled - Called after mutation completes (success or failure)
 *
 * @returns React Query mutation result for updating user
 *
 * @example
 * ```tsx
 * const { mutate } = useUpdateUserMutation({
 *   onSuccess: (data) => console.log('User updated:', data),
 *   onError: (error) => console.error('User update failed:', error)
 * });
 *
 * // Update user
 * mutate({ userId: '9fb763f7-33de-4969-a7a0-b808f4637318' });
 * ```
 */

export function useUpdateUserMutation({ onSuccess, onError, onSettled, onMutate }: UpdateUserMutationConfig) {
  const tenantId = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  const { mutate, isError, error } = useMutation<
    UpdateUserResponse,
    ApiError,
    Pick<UpdateUserData, 'id' | 'requestBody'>
  >({
    mutationFn: ({ id: userId, requestBody }) => {
      return mgmtPlaneAPIClient.users.updateUser({
        tenantId: tenantId!,
        id: userId,
        requestBody,
      });
    },
    onSuccess,
    onError,
    onSettled,
    onMutate,
  });

  return { mutate, isError, error };
}
