/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { useParams } from 'react-router-dom';
import { validate as uuidValidate } from 'uuid';

import { HTTPError } from '@/components/HTTPError';
import { NodeConfigDetailsPage } from '@/pages/NodeConfigDetails/NodeConfigDetailsPage';

export function NodeConfigDetailsRoute() {
  const { nodeConfigId } = useParams<'nodeConfigId'>();

  if (!nodeConfigId || !uuidValidate(nodeConfigId)) {
    return <HTTPError httpStatus="404" />;
  }

  return <NodeConfigDetailsPage nodeConfigId={nodeConfigId} />;
}
