/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { z } from 'zod';
import {
  PaginationParams,
  buildPaginatedResponseSchema,
  sortable,
} from '../../openapi';
import {
  OPENAPI_NODE_CONFIG_ID,
  OPENAPI_NODE_ID,
  TYPE_UUID,
} from '../../types';
import { tenantID } from '../tags/schemas';

export const NodeLifecycleEventsSchema = z
  .object({
    id: TYPE_UUID.openapi({
      'x-go-name': 'ID',
      example: '550e8400-e29b-41d4-a716-446655440000',
    }),
    nodeID: OPENAPI_NODE_ID,
    tenantID: tenantID,
    eventType: z.string(),
    eventTimestamp: z.string().datetime(),
    msg: z.string().optional(),
    configRevision: z
      .object({
        nodeConfigID: OPENAPI_NODE_CONFIG_ID,
        nodeConfigName: z.string(),
        revisionNumber: z.number(),
      })
      .optional(),
  })
  .openapi('NodeLifecycleEvent');

export const LifecycleFilterParamsSchema = z
  .object({
    type: z.string().optional(),
  })
  .openapi('LifecycleFilters');

export const LifecycleSortableFields = sortable('LifecycleSortableFields', []);

export const LifecycleQueryParamsSchema = PaginationParams.merge(
  z.object({
    filters: LifecycleFilterParamsSchema.optional(),
    sort: LifecycleSortableFields.optional(),
  }),
);

export const PaginatedNodeLifecycleResponseSchema =
  buildPaginatedResponseSchema(
    'PaginatedNodeLifecycleEvents',
    NodeLifecycleEventsSchema,
    LifecycleFilterParamsSchema,
    LifecycleSortableFields,
  );
