import { Modal } from 'antd';

import { MimicSyntaxHighlighter } from '@/components/MimicSyntaxHighlighter';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';

export type JobNodeIssuesModalProps = {
  type: 'warnings' | 'errors';
  showModal: boolean;
  closeModal: () => void;
  modalContents: unknown;
};

export function JobNodeIssuesModal({ type, showModal, closeModal, modalContents }: JobNodeIssuesModalProps) {
  const { t } = useMimicTranslation('jobs');
  if (!modalContents) return null;

  return (
    <Modal width="50%" footer={null} title={t(`jobNodeIssuesModal.${type}`)} open={showModal} onCancel={closeModal}>
      <MimicSyntaxHighlighter source={JSON.stringify(modalContents, null, 2)} language="json" />
    </Modal>
  );
}
