/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { NodeConfig } from '@/client';
import { QueryWrapper } from '@/components/QueryWrapper';
import { usePaginatedConfigRevisionsQuery } from '@/hooks/query/usePaginatedConfigRevisionsQuery';
import { usePaginatedJobsQuery } from '@/hooks/query/usePaginatedJobsQuery';
import { usePaginatedNodesQuery } from '@/hooks/query/usePaginatedNodesQuery';
import { usePaginatedTagsQuery } from '@/hooks/query/usePaginatedTagsQuery';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { NodeConfigDetailsRevisions } from '@/pages/NodeConfigDetails/NodeConfigDetailsRevisions';
import { NodeConfigJobsHistory } from '@/pages/NodeConfigDetails/NodeConfigJobsHistory';
import { NodeConfigNodes } from '@/pages/NodeConfigDetails/NodeConfigNodes';
import { Tabs } from '@/primitives/Tabs';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export type NodeConfigDetailsTabsProps = {
  nodeConfig: NodeConfig;
};

export function NodeConfigDetailsTabs({ nodeConfig }: NodeConfigDetailsTabsProps) {
  const { t } = useMimicTranslation('nodeConfigurationDetail');
  const tenantID = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  const nodesQuery = usePaginatedNodesQuery({ params: { filters: { appliedNodeConfigId: nodeConfig.id } } });
  const jobsQuery = usePaginatedJobsQuery({ params: { filters: { nodeConfigId: nodeConfig.id } } });
  const tagsQuery = usePaginatedTagsQuery({});
  const configRevisionsQuery = usePaginatedConfigRevisionsQuery({
    params: { configId: nodeConfig.id },
  });

  const tabs = [
    {
      key: '1',
      label: t('tabs.revisions'),
      children: (
        <QueryWrapper queryResult={configRevisionsQuery}>
          {(configRevisionsData) => (
            <NodeConfigDetailsRevisions
              tenantID={tenantID!}
              nodeConfig={nodeConfig}
              configRevisionsData={configRevisionsData}
            />
          )}
        </QueryWrapper>
      ),
    },
    {
      key: '2',
      label: t('tabs.assignedNodes'),
      children: (
        <QueryWrapper queryResult={nodesQuery}>
          {(nodesData) => (
            <QueryWrapper queryResult={tagsQuery}>
              {(tagsData) => (
                <NodeConfigNodes
                  tenantID={tenantID!}
                  nodeConfig={nodeConfig}
                  nodesData={nodesData}
                  nodesFilters={nodesData.meta.filters}
                  updateQueryParams={nodesQuery.updateQueryParams}
                  tags={tagsData.data}
                />
              )}
            </QueryWrapper>
          )}
        </QueryWrapper>
      ),
    },
    {
      key: '3',
      label: t('tabs.jobsHistory'),
      children: (
        <QueryWrapper queryResult={jobsQuery}>
          {(jobsData) => (
            <NodeConfigJobsHistory
              tenantID={tenantID!}
              jobsData={jobsData}
              jobFilters={jobsData.meta.filters}
              updateQueryParams={jobsQuery.updateQueryParams}
            />
          )}
        </QueryWrapper>
      ),
    },
  ];
  return <Tabs items={tabs} style={{ padding: '16px 0 0 0' }} className="details-tabs" />;
}
