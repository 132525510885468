/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useState } from 'react';

import { Job, JobNode, JobNodeFilters, JobStatus, PaginatedJobNodesResponse } from '@/client';
import { PaginatedTable } from '@/components/PaginatedTable';
import { MimicTranslationFunction, useMimicTranslation } from '@/hooks/useMimicTranslation';
import { JobNodeIssuesModal } from '@/pages/Jobs/JobNodeIssuesModal';
import { JobNodeStatus } from '@/pages/Jobs/JobNodeStatus';
import { Card } from '@/primitives/Card';
import { OsIcon } from '@/primitives/Icons';
import { Link } from '@/primitives/Link';
import { Text } from '@/primitives/Text';
import { Title } from '@/primitives/Title';
import { Tooltip } from '@/primitives/Tooltip';
import { UpdateParams } from '@/utils/params';

export type JobDetailsConfigProps = {
  tenantID: string;
  job: Job;
  jobNodesData: PaginatedJobNodesResponse;
  onParamsChange: UpdateParams<JobNode, JobNodeFilters>;
};

export function JobDetailsTable({ tenantID, job, jobNodesData, onParamsChange }: JobDetailsConfigProps) {
  const { t } = useMimicTranslation('jobs');
  const [showWarningsModal, setShowWarningsModal] = useState<boolean>(false);
  const [warningsModalContents, setWarningsModalContent] = useState<JobNode['warnings']>();
  const [showErrorsModal, setShowErrorsModal] = useState<boolean>(false);
  const [errorsModalContents, setErrorsModalContent] = useState<JobNode['errors']>();

  const openWarningsModalWithDetails = (details: JobNode['warnings']) => {
    setWarningsModalContent(details);
    setShowWarningsModal(true);
  };

  const closeWarningsModal = () => {
    setShowWarningsModal(false);
    setWarningsModalContent(undefined);
  };

  const openErrorsModalWithDetails = (details: JobNode['errors']) => {
    setErrorsModalContent(details);
    setShowErrorsModal(true);
  };

  const closeErrorsModal = () => {
    setShowErrorsModal(false);
    setErrorsModalContent(undefined);
  };

  const columns = getJobColumns(tenantID, job, t, openWarningsModalWithDetails, openErrorsModalWithDetails);

  return (
    <Card padding="md" gap="sm">
      <Title level={3} style={{ fontSize: '17px', fontWeight: '700' }}>
        {t('jobDetailsTableTitle')}
      </Title>
      <PaginatedTable
        columns={columns}
        data={jobNodesData}
        rowKey={(jobNode) => jobNode.id}
        onParamsChange={onParamsChange}
      />
      <JobNodeIssuesModal
        type="warnings"
        showModal={showWarningsModal}
        closeModal={closeWarningsModal}
        modalContents={warningsModalContents}
      />
      <JobNodeIssuesModal
        type="errors"
        showModal={showErrorsModal}
        closeModal={closeErrorsModal}
        modalContents={errorsModalContents}
      />
    </Card>
  );
}

function getJobColumns(
  tenantID: string,
  job: Job,
  t: MimicTranslationFunction<'jobs'>,
  openWarningsModalWithDetails: (details: JobNode['warnings']) => void,
  openErrorsModalWithDetails: (details: JobNode['errors']) => void,
) {
  const columns: ColumnType<JobNode>[] = [
    {
      title: <span data-testid="hostName">{t('jobNodes.hostName')}</span>,
      dataIndex: 'hostname',
      sorter: true,
      render: (hostname: string, jobNode: JobNode) => {
        return (
          <Link
            to={`/tenants/${tenantID}/nodes/${jobNode.nodeId}`}
            style={{ fontSize: 'inherit', fontFamily: 'DM Mono' }}
            dataTestId="node-link"
          >
            <Text type="link" size="md">
              {hostname}
            </Text>
          </Link>
        );
      },
    },
    {
      title: (
        <span data-testid="nodeVersion">
          {t('jobNodes.nodeVersion', { startDate: `${dayjs(job.dateCreated).format('D MMM YYYY')}` })}
        </span>
      ),
      dataIndex: 'nodeVersion',
      sorter: true,
      render: (nodeVersion: string) => {
        return (
          <Text type="default" size="md">
            {nodeVersion}
          </Text>
        );
      },
    },
    {
      title: <span data-testid="os">{t('jobNodes.os')}</span>,
      dataIndex: 'operatingSystem',
      sorter: true,
      render: (os: JobNode['operatingSystem'], jobNode: JobNode) => {
        return (
          <Tooltip title={`${os} ${jobNode.operatingSystem}`} placement="right">
            <div data-testid="operating-system-icon">
              <OsIcon name={os} />
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: <span data-testid="status">{t('jobNodes.status')}</span>,
      dataIndex: 'status',
      sorter: true,
      render: (status: JobStatus, jobNode: JobNode) => {
        const showWarningsButton = !!jobNode.warnings;
        const openWarningsModal = () => {
          openWarningsModalWithDetails(jobNode.warnings);
        };
        const openErrorsModal = () => {
          openErrorsModalWithDetails(jobNode.errors);
        };
        return (
          <JobNodeStatus
            status={status}
            openWarningsModal={openWarningsModal}
            openErrorsModal={openErrorsModal}
            showWarningsButton={showWarningsButton}
          />
        );
      },
    },
  ];

  return columns;
}
