/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Form, FormInstance } from 'antd';

import { PatchUser, UserResponse } from '@/client';
import { ValidationError } from '@/components/Forms/ValidationError';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Input } from '@/primitives/Input';

const requiredMarkAfter = (label: React.ReactNode) => label;

export type UpdateMemberFormParams = {
  form: FormInstance<PatchUser>;
  userData: UserResponse;
};

export function UpdateMemberForm({ form, userData }: UpdateMemberFormParams) {
  const { t } = useMimicTranslation('members');
  const { t: tGlobal } = useMimicTranslation('global');

  return (
    <Form
      validateTrigger="onBlur"
      form={form}
      layout="vertical"
      requiredMark={requiredMarkAfter}
      data-testid="update-member-form"
    >
      <Form.Item
        name="firstName"
        label={t('firstName')}
        initialValue={userData.data.firstName}
        rules={[
          { required: true, message: <ValidationError>{tGlobal('forms.required')}</ValidationError> },
          { type: 'string', message: <ValidationError>{tGlobal('forms.required')}</ValidationError> },
        ]}
      >
        <Input dataTestId="member-first-name" allowClear />
      </Form.Item>
      <Form.Item
        name="lastName"
        label={t('lastName')}
        initialValue={userData.data.lastName}
        rules={[
          { required: true, message: <ValidationError>{tGlobal('forms.required')}</ValidationError> },
          { type: 'string', message: <ValidationError>{tGlobal('forms.required')}</ValidationError> },
        ]}
      >
        <Input dataTestId="member-last-name" allowClear />
      </Form.Item>
      <Form.Item
        name="email"
        label={t('email')}
        initialValue={userData.data.email}
        rules={[
          { required: true, message: <ValidationError>{t('invite.enverValidEmail')}</ValidationError> },
          { min: 1, message: <ValidationError>{t('invite.enverValidEmail')}</ValidationError> },
          { type: 'email', message: <ValidationError>{t('invite.enverValidEmail')}</ValidationError> },
        ]}
      >
        <Input dataTestId="member-email" allowClear />
      </Form.Item>
    </Form>
  );
}
