/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Layout as AntDLayout } from 'antd';

const { Header: AntDHeader } = AntDLayout;

const style = {
  paddingTop: '0.6rem',
  paddingLeft: '1.5rem',
  paddingRight: '1.5rem',
  backgroundColor: 'transparent',
};

export type HeaderProps = {
  children: React.ReactNode;
};

export function Header({ children }: HeaderProps) {
  return <AntDHeader style={style}>{children}</AntDHeader>;
}
