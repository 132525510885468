import { Badge as AntDBadge } from 'antd';

import { FontSize, tokens } from '@/theme';

export type BadgeProps = {
  /**
   * The text to display in the badge
   */
  text: string | number | React.ReactNode;
  /**
   * Data test id
   */
  dataTestId?: string;
  bgColor: string;
  color?: string;
  minWidth?: string;
  minHeight?: string;
  borderRadius?: string;
  size?: FontSize;
  style?: React.CSSProperties;
};

export function Badge({
  text,
  dataTestId,
  bgColor,
  color,
  minHeight,
  borderRadius,
  minWidth,
  size,
  style,
}: BadgeProps) {
  const fontSize = size ? tokens.size.font[size] : undefined;

  return (
    <AntDBadge
      data-testid={dataTestId}
      color={bgColor}
      count={text}
      style={{
        minHeight,
        borderRadius,
        minWidth,
        fontSize,
        padding: '0 0.75rem',
        color: color || tokens.color.text.textDefault,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...style,
      }}
      showZero
    />
  );
}
