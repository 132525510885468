/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { ColumnType } from 'antd/es/table';

import { Node, NodeFilters, PaginatedNodesResponse } from '@/client';
import { PaginatedTable } from '@/components/PaginatedTable';
import { OperationalState } from '@/components/States/OperationalState';
import { MimicTranslationFunction, useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Card } from '@/primitives/Card';
import { OsIcon } from '@/primitives/Icons';
import { Link } from '@/primitives/Link';
import { Text } from '@/primitives/Text';
import { Title } from '@/primitives/Title';
import { Tooltip } from '@/primitives/Tooltip';
import { UpdateParams } from '@/utils/params';

export type ConfigureNodesJobWizardReviewJobTableProps = {
  tenantID: string;
  nodesData: PaginatedNodesResponse;
  onParamsChange: UpdateParams<Node, Partial<NodeFilters>>;
};

export function ConfigureNodesJobWizardReviewJobTable({
  tenantID,
  nodesData,
  onParamsChange,
}: ConfigureNodesJobWizardReviewJobTableProps) {
  const { t } = useMimicTranslation('configureNodesJobWizard');

  const columns = getNodesColumns(tenantID, t);

  return (
    <Card padding="md" gap="sm">
      <Title level={3} style={{ fontSize: '17px', fontWeight: '700' }}>
        {t('nodes')}
      </Title>
      <PaginatedTable rowKey="id" columns={columns} data={nodesData} onParamsChange={onParamsChange} />
    </Card>
  );
}

function getNodesColumns(tenantID: string, t: MimicTranslationFunction<'configureNodesJobWizard'>) {
  const columns: ColumnType<Node>[] = [
    {
      title: <span data-testid="hostName">{t('hostName')}</span>,
      dataIndex: 'hostname',
      sorter: true,
      render: (hostname: string, node: Node) => {
        return (
          <Link
            to={`/tenants/${tenantID}/nodes/${node.id}`}
            style={{ fontSize: 'inherit', fontFamily: 'DM Mono' }}
            dataTestId="node-link"
          >
            <Text type="link" size="md" mono>
              {hostname}
            </Text>
          </Link>
        );
      },
    },
    {
      title: <span data-testid="nodeVersion">{t('nodeVersion')}</span>,
      dataIndex: 'nodeVersion',
      sorter: true,
      render: (nodeVersion: string) => {
        return (
          <Text type="default" size="md">
            {nodeVersion}
          </Text>
        );
      },
    },
    {
      title: <span data-testid="os">{t('os')}</span>,
      dataIndex: 'operatingSystem',
      render: (os: Node['operatingSystem'], node: Node) => {
        return (
          <Tooltip title={`${os} ${node.operatingSystem}`} placement="right">
            <div data-testid="operating-system-icon">
              <OsIcon name={os} />
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: <span data-testid="operationalState">{t('operationalState')}</span>,
      dataIndex: 'appliedOperationalState',
      sorter: true,
      render: (_, node: Node) => {
        return <OperationalState state={node.appliedRevisionState!.operationalState} />;
      },
    },
  ];

  return columns;
}
