/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useContext } from 'react';

import { ThemeProviderContext } from '@/primitives/Theme';

export const useTheme = () => {
  const context = useContext(ThemeProviderContext);

  if (context === undefined) throw new Error('useTheme must be used within a Theme');

  return context;
};
