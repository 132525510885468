/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { z } from 'zod';
import {
  PaginationParams,
  baseSchema,
  buildPaginatedResponseSchema,
  buildResponseSchema,
  sortable,
} from '../../openapi';
import { TYPE_UUID } from '../../types';

export const UserEmail = z
  .string()
  .email()
  .openapi({ example: 'john.wilkinson@mimic.com' });

export const UserRemoved = z.boolean().openapi({ example: false });

// base schema
export const BaseUserSchema = z.object({
  id: TYPE_UUID.openapi({
    'x-go-name': 'ID',
    example: '550e8400-e29b-41d4-a716-446655440000',
  }),
  displayName: z.string().openapi({ example: 'John Wilkinson' }),
  firstName: z.string().openapi({ example: 'John' }),
  lastName: z.string().openapi({ example: 'Wilkinson' }),
  email: UserEmail,
  tenantID: z
    .string()
    .uuid()
    .openapi({ example: '550e8400-e29b-41d4-a716-446655440000' }),
  role: z
    .enum(['viewer', 'editor', 'admin', 'superadmin'])
    .openapi({ example: 'viewer' }),
  removed: UserRemoved,
});
export const UserSchema = BaseUserSchema.merge(baseSchema).openapi('User');

// mutation schemas
export const CreateUserSchema = z
  .object({
    firstName: z.string().min(1).max(100).openapi({ example: 'John' }),
    lastName: z.string().min(1).max(100).openapi({ example: 'Wilkinson' }),
    email: UserEmail,
    role: z.enum(['viewer', 'editor', 'admin']).openapi({ example: 'viewer' }),
  })
  .required({
    firstName: true,
    lastName: true,
    email: true,
    role: true,
  })
  .openapi('CreateUser');

export const PatchUserSchema = z
  .object({
    firstName: z
      .string()
      .min(1)
      .max(100)
      .optional()
      .openapi({ example: 'John' }),
    lastName: z
      .string()
      .min(1)
      .max(100)
      .optional()
      .openapi({ example: 'Wilkinson' }),
    role: z
      .enum(['viewer', 'editor', 'admin', 'superadmin'])
      .openapi({ example: 'viewer' })
      .optional(),
    email: UserEmail.optional(),
    removed: UserRemoved.optional(),
  })
  .openapi('PatchUser');

// response schemas
export const UserResponseSchema = buildResponseSchema(
  'UserResponse',
  UserSchema,
);

export const UserSortableFields = sortable('UserSortableFields', [
  'displayName',
]);

export const UserFilterParamsSchema = z
  .object({
    removed: z.boolean().optional().openapi({ example: true }),
  })
  .openapi('UserFilters', { example: { removed: true } });

export const PaginatedUsersResponseSchema = buildPaginatedResponseSchema(
  'PaginatedUsersResponse',
  UserSchema,
  UserFilterParamsSchema,
  UserSortableFields,
);

export const UserQueryParamsSchema = PaginationParams.merge(
  z.object({
    filters: UserFilterParamsSchema.optional(),
    sort: UserSortableFields.optional(),
  }),
);
