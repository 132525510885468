/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import * as Sentry from '@sentry/react';
import React from 'react';

import { HTTPError } from '@/components/HTTPError';
import { useMessage } from '@/hooks/message';
import { MessageInstance } from '@/state/mgmtPlaneStore';
import { MimicApiError } from '@/utils/errors';

type MimicErrorBoundaryClassComponentProps = Required<MimicErrorBoundaryProps> & { message: MessageInstance };
type MimicErrorBoundaryState = { unkownError?: Error; apiError?: MimicApiError };

class MimicErrorBoundaryClassComponent extends React.Component<
  MimicErrorBoundaryClassComponentProps,
  MimicErrorBoundaryState
> {
  constructor(props: MimicErrorBoundaryClassComponentProps) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error: unknown) {
    Sentry.captureException(error);
    if (error instanceof MimicApiError && error.body.message) {
      return { apiError: error, unkownError: undefined };
    }
    return { unkownError: error, apiError: undefined };
  }

  componentDidCatch() {
    const { apiError } = this.state;
    const { message } = this.props;
    if (apiError && apiError.body?.message) {
      message.error(apiError.body.message);
    }
  }

  render() {
    const { unkownError } = this.state;
    const { children, fallback } = this.props;
    if (unkownError) {
      return fallback;
    }

    return children;
  }
}

export type MimicErrorBoundaryProps = {
  children: React.ReactNode;
  fallback?: React.ReactNode;
};
export function MimicErrorBoundary({ children, fallback }: MimicErrorBoundaryProps) {
  const [message] = useMessage();
  const defaultFallbackComponent = <HTTPError httpStatus="500" />;
  return (
    <MimicErrorBoundaryClassComponent fallback={fallback || defaultFallbackComponent} message={message}>
      {children}
    </MimicErrorBoundaryClassComponent>
  );
}
