/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import { CountLabel } from '@/components/CountLabel';
import { GuardedButton } from '@/components/GuardedButton';
import { PageHeader } from '@/components/PageHeader';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Container } from '@/primitives/Container';
import { Segmented, SegmentedValue } from '@/primitives/Segmented';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

// eslint-disable-next-line react-refresh/only-export-components
export enum UserState {
  REMOVED = 'removed',
  ACTIVE = 'active',
}

export type MembersHeaderProps = {
  activeFilterValue: SegmentedValue;
  onChangeActiveFilter: (value: SegmentedValue) => void;
  activeTotal: number;
  removedTotal: number;
  isLoading: boolean;
};

export function MembersHeader({
  activeFilterValue,
  onChangeActiveFilter,
  activeTotal,
  removedTotal,
  isLoading,
}: MembersHeaderProps) {
  const { t } = useMimicTranslation('members');
  const tenantID = useMgmtPlaneStateStore((state) => state.selectedTenantID);
  const navigate = useNavigate();

  const openInviteModal = () => {
    navigate(`/tenants/${tenantID}/members/invite`);
  };

  return (
    <Container>
      <PageHeader title={t('title')} text={t('subtitle')}>
        <GuardedButton requiredRole="admin" type="primary" onClick={openInviteModal} dataTestId="invite-new-member">
          <PlusOutlined /> {t('newMember')}
        </GuardedButton>
      </PageHeader>
      <Segmented
        dataTestId="removed-switcher"
        defaultValue={UserState.ACTIVE}
        value={activeFilterValue}
        onChange={onChangeActiveFilter}
        options={[
          {
            label: <CountLabel text="Active" count={activeTotal} loading={isLoading} data-testid="segment-active" />,
            value: UserState.ACTIVE,
          },
          {
            label: <CountLabel text="Removed" count={removedTotal} loading={isLoading} data-testid="segment-removed" />,
            value: UserState.REMOVED,
          },
        ]}
      />
    </Container>
  );
}
