/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

import { ApiError, GetUserProfileResponse } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';

/**
 * Custom hook that fetches a user profile
 *
 * Features:
 * - Fetches a user profile
 * - Type-safe API response handling
 *
 * @returns React Query result containing user profile info
 *
 * @example
 * ```tsx
 * const { data: userProfile } = useUserProfileQuery();
 * ```
 */
export function useUserProfileQuery(retry = false) {
  return useQuery<GetUserProfileResponse, ApiError>({
    queryKey: ['userProfile'],
    queryFn: async () => {
      const profileResponse = await mgmtPlaneAPIClient.users.getUserProfile();

      Sentry.setUser({
        id: profileResponse.data.id,
        name: profileResponse.data.displayName,
        email: profileResponse.data.email,
      });

      return profileResponse;
    },
    retry,
  });
}
