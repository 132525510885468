/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import * as Sentry from '@sentry/react';
import { useState } from 'react';

import { ConfigRevision, Node, NodeFilters } from '@/client';
import { ConfigWizardOverlay } from '@/components/ConfigWizardOverlay/ConfigWizardOverlay';
import { ContentContainer } from '@/components/ContentContainer';
import { QueryWrapper } from '@/components/QueryWrapper';
import { useMessage } from '@/hooks/message';
import { useAssignConfigRevisionMutation } from '@/hooks/mutation/useAssignConfigRevisionMutation';
import { usePaginatedNodesQuery } from '@/hooks/query/usePaginatedNodesQuery';
import { usePaginatedTagsQuery } from '@/hooks/query/usePaginatedTagsQuery';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { NodeFilter, NodesHeader } from '@/pages/Nodes/NodesHeader';
import { NodesList } from '@/pages/Nodes/NodesList';
import { Container } from '@/primitives/Container';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { defineTypes } from '@/utils/filters';
import { UpdateParams } from '@/utils/params';

export const NodesFilterTypes = defineTypes<NodeFilters>({
  id: 'string',
  name: 'string',
  hostname: 'string',
  tags: 'string',
  operatingSystem: 'string',
  isOperating: 'boolean',
  isArchived: 'boolean',
  appliedNodeConfigId: 'string',
  assignedBy: 'string',
  hasBaseline: 'boolean',
});

export function NodesPage() {
  const [message] = useMessage();
  const { t } = useMimicTranslation('node');
  const [selectedNode, setSelectedNode] = useState<Node | undefined>();
  const selectedTenantID = useMgmtPlaneStateStore((state) => state.selectedTenantID);
  const [nodeState, setNodeState] = useState<NodeFilter>(NodeFilter.CONFIGURED);

  const configuredNodesQuery = usePaginatedNodesQuery({
    type: 'queryParams',
    params: { filters: { isOperating: true } },
  });

  const pendingNodesQuery = usePaginatedNodesQuery({
    type: 'queryParams',
    params: { filters: { isOperating: false } },
  });

  const tagsQuery = usePaginatedTagsQuery({});

  const nodesQuery = nodeState === NodeFilter.CONFIGURED ? configuredNodesQuery : pendingNodesQuery;

  const updateQueryParams: UpdateParams<Node, NodeFilters> = (...params) => {
    pendingNodesQuery.updateQueryParams(...params);
    configuredNodesQuery.updateQueryParams(...params);
  };

  const configuredCount = configuredNodesQuery.data?.meta.page.totalItems;
  const pendingCount = pendingNodesQuery.data?.meta.page.totalItems;

  const onAssignConfiguration = (node: Node) => {
    setSelectedNode(node);
  };

  const { mutate: assignConfigRevision } = useAssignConfigRevisionMutation({
    onError: (error) => {
      message.error(t('feedback.assignNodeConfigError'));
      Sentry.captureException(error);
    },
  });

  const onAssign = (nodeToAssign: Node, configRevision: ConfigRevision) => {
    assignConfigRevision({
      nodeIDs: [nodeToAssign.id],
      configID: configRevision.nodeConfigId!,
      revisionNumber: configRevision.revisionNumber,
    });

    setSelectedNode(undefined);
  };

  const onCancel = () => {
    setSelectedNode(undefined);
  };

  const getNodeConfigurationPath = (nodeConfigId: string) =>
    `/tenants/${selectedTenantID}/node-configs/${nodeConfigId}`;

  return (
    <>
      <ContentContainer width="100%" padding="xl">
        <Container>
          <NodesHeader
            nodesState={nodeState}
            setNodeState={setNodeState}
            configuredCount={configuredCount}
            pendingCount={pendingCount}
            configuredNodesIsLoading={configuredNodesQuery.isLoading}
            pendingNodesIsLoading={pendingNodesQuery.isLoading}
          />
          <QueryWrapper queryResult={tagsQuery}>
            {(tagsData) => (
              <QueryWrapper queryResult={nodesQuery}>
                {(nodesData) => (
                  <NodesList
                    tenantID={selectedTenantID!}
                    tags={tagsData.data}
                    onParamsChange={updateQueryParams}
                    nodesState={nodeState}
                    nodesData={nodesData}
                    onAssignConfiguration={onAssignConfiguration}
                    getNodeConfigurationPath={getNodeConfigurationPath}
                  />
                )}
              </QueryWrapper>
            )}
          </QueryWrapper>
        </Container>
      </ContentContainer>
      <ConfigWizardOverlay node={selectedNode} onCancel={onCancel} onAssign={onAssign} />
    </>
  );
}
