/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { TableProps } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Node } from '@/client';
import { QueryWrapper } from '@/components/QueryWrapper';
import { usePaginatedNodesQuery } from '@/hooks/query/usePaginatedNodesQuery';
import { usePaginatedTagsQuery } from '@/hooks/query/usePaginatedTagsQuery';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { NodesColumns } from '@/pages/Nodes/getNodesColumns';
import { NodesTable } from '@/pages/Nodes/NodesTable';
import { Title } from '@/primitives/Title';
import { setJobWizardSelectedNodes } from '@/state/jobWizardStore';

import { ConfigureNodesJobWizardFooter } from './ConfigureNodesJobWizardFooter';

export type ConfigureNodesJobWizardSelectNodesProps = {
  tenantID: string;
  selectedNodes: Node[];
};

export function ConfigureNodesJobWizardSelectNodes({
  tenantID,
  selectedNodes,
}: ConfigureNodesJobWizardSelectNodesProps) {
  const { t } = useMimicTranslation('configureNodesJobWizard');
  const { t: tNodes } = useMimicTranslation('nodes');
  const navigate = useNavigate();

  const predefinedSelectedIds = selectedNodes?.length ? selectedNodes.map((node) => node.id) : undefined;
  const predefinedFilters = predefinedSelectedIds
    ? { id: predefinedSelectedIds.join(','), isOperating: undefined }
    : { isOperating: undefined };

  const [sameOs, setSameOs] = useState<boolean>(true);
  const [selectedIds, setSelectedIds] = useState<string[]>(predefinedSelectedIds || []);
  const [showDifferentOsError, setShowDifferentOsError] = useState<boolean | undefined>();

  const selectNodeErrorMessage = !selectedIds.length ? t('selectANode') : undefined;
  const notSameOsErrorMessage = showDifferentOsError ? tNodes('banner.differentOSError') : undefined;
  const notSameOsWarningMessage = !sameOs ? tNodes('banner.differentOSWarning') : undefined;
  const errorMessage = selectNodeErrorMessage || notSameOsErrorMessage || notSameOsWarningMessage;

  useEffect(() => {
    if (!selectedNodes?.length) return;
    setSameOs(new Set(selectedNodes.map((node) => node.operatingSystem)).size === 1);
  }, [selectedNodes, setSameOs]);

  const onNext = () => {
    if (!selectedIds.length) return;
    if (!sameOs) {
      setShowDifferentOsError(true);
      return;
    }
    navigate(`#select-config`);
  };

  const nodesQuery = usePaginatedNodesQuery({
    params: { filters: predefinedFilters },
  });

  // TODO lazy load tags (MP-2350)
  const tagsQuery = usePaginatedTagsQuery({ params: { page: 1, pageSize: 100 } });

  const visibleColumns: NodesColumns[] = [
    'hostname',
    'tags',
    'appliedOperationalState',
    'connectivityState',
    'operatingSystem',
    'lastSeen',
    'appliedConfiguration',
  ];

  const getNodeConfigurationPath = (nodeConfigId: string) => `/tenants/${tenantID}/node-configs/${nodeConfigId}`;

  const rowSelection: TableProps<Node>['rowSelection'] = {
    type: 'checkbox',
    preserveSelectedRowKeys: true,
    selectedRowKeys: selectedIds,
    onChange: (_selectedKeys: React.Key[], selectedRows: Node[]) => {
      const selectedOses = selectedRows.map((node) => node.operatingSystem);
      const uniqueOses = new Set(selectedOses);
      const newSameOsVal = uniqueOses.size === 1 || selectedRows.length === 0;
      setSameOs(newSameOsVal);
      const newlySelectedIds = selectedRows.map((node) => node.id);
      setSelectedIds(newlySelectedIds);
      setJobWizardSelectedNodes(selectedRows);
      if (newSameOsVal) {
        setShowDifferentOsError(false);
      }
    },
    getCheckboxProps: (node: Node) => ({
      name: node.name,
    }),
  };

  return (
    <>
      <div className="configure-nodes-job-wizard-content">
        <div style={{ padding: '32px', paddingTop: 0, display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Title level={4}>{t('selectNodes')}</Title>
          <QueryWrapper queryResult={nodesQuery}>
            {(nodesData) => (
              <QueryWrapper queryResult={tagsQuery}>
                {(tagsData) => (
                  <NodesTable
                    nodesData={nodesData}
                    onParamsChange={nodesQuery.updateQueryParams}
                    columns={visibleColumns}
                    tags={tagsData.data}
                    tenantID={tenantID}
                    getNodeConfigurationPath={getNodeConfigurationPath}
                    selectedNodeIds={selectedIds}
                    rowSelection={rowSelection}
                    hideFilters={!!predefinedSelectedIds}
                  />
                )}
              </QueryWrapper>
            )}
          </QueryWrapper>
        </div>
      </div>
      <ConfigureNodesJobWizardFooter
        onNext={onNext}
        onNextLabel={t('selectConfig')}
        onNextIcon={null}
        error={!!errorMessage}
        errorMessage={errorMessage}
      />
    </>
  );
}
