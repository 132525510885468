/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { ExclamationCircleOutlined, FileUnknownOutlined, LockOutlined } from '@ant-design/icons';

import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Flex } from '@/primitives/Flex';
import { Result } from '@/primitives/Result';
import { Text } from '@/primitives/Text';
import { tokens } from '@/theme';

import './SectionHTTPError.css';

export type SectionHTTPErrorProps = {
  httpStatus: '403' | '404' | '500';
};

type ErrorMessages = {
  [key in SectionHTTPErrorProps['httpStatus']]: {
    title: 'forbiddenError' | 'notFoundContentError' | 'unexpectedError';
    subTitle: 'forbiddenErrorDescriptionOne' | 'notFoundContentErrorDescription' | 'unexpectedErrorDescription';
    subTitleTwo?: 'forbiddenErrorDescriptionTwo';
    icon?: React.ReactNode;
  };
};

const errorMessages: ErrorMessages = {
  '403': {
    title: 'forbiddenError',
    subTitle: 'forbiddenErrorDescriptionOne',
    subTitleTwo: 'forbiddenErrorDescriptionTwo',
    icon: <LockOutlined style={{ color: tokens.color.ink.ink03, fontSize: '2.5rem' }} />,
  },
  '404': {
    title: 'notFoundContentError',
    subTitle: 'notFoundContentErrorDescription',
    icon: <FileUnknownOutlined style={{ color: tokens.color.ink.ink03, fontSize: '2.5rem' }} />,
  },
  '500': {
    title: 'unexpectedError',
    subTitle: 'unexpectedErrorDescription',
    icon: <ExclamationCircleOutlined style={{ color: tokens.color.ink.ink03, fontSize: '2.5rem' }} />,
  },
};

export function SectionHTTPError({ httpStatus }: SectionHTTPErrorProps) {
  const { t } = useMimicTranslation('error');
  const { icon, title, subTitle, subTitleTwo } = errorMessages[httpStatus];

  const titleText = (
    <Text size="lg" type="subtle">
      {t(title)}
    </Text>
  );

  const subTitleText = (
    <Flex vertical gap="xxs" align="center">
      <Text data-testid="subTitle-one" type="subtle" size="sm">
        {t(subTitle)}
      </Text>
      {subTitleTwo && (
        <Text data-testid="subTitle-two" type="subtle" size="sm">
          {t(subTitleTwo)}
        </Text>
      )}
    </Flex>
  );
  return (
    <div data-testid={`http-error-${httpStatus}`}>
      <Result icon={icon} status={!icon ? httpStatus : undefined} title={titleText} subTitle={subTitleText} />
    </div>
  );
}
