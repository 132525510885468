/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CheckCircleFilled,
  CopyOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { Space } from 'antd';
import { ReactNode } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import { useMessage } from '@/hooks/message';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Button } from '@/primitives/Button';
import { Card } from '@/primitives/Card';
import { Code } from '@/primitives/Code';
import { Container } from '@/primitives/Container';
import { Input } from '@/primitives/Input';
import { Steps } from '@/primitives/Steps';
import { Title } from '@/primitives/Title';
import { Tooltip } from '@/primitives/Tooltip';
import { Step, useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

function HeroTitle({ children }: { children: ReactNode }) {
  // size/space/smallSpaceMD = 16px
  const marginBottom = 16;
  return (
    <Title level={1} style={{ margin: 0, marginBottom }}>
      {children}
    </Title>
  );
}

function HeaderTitle({ children }: { children: ReactNode }) {
  return (
    <Title level={2} style={{ margin: 0 }}>
      {children}
    </Title>
  );
}

function Subtitle({ children }: { children: ReactNode }) {
  // size/space/smallSpaceSM = 12px
  const marginBottom = 12;
  return (
    <Title level={5} style={{ margin: 0, marginBottom }}>
      {children}
    </Title>
  );
}

function BodyText({ children }: { children: ReactNode }) {
  const fontSize = 19;
  return <p style={{ maxWidth: 650, margin: 0, fontSize }}>{children}</p>;
}

function StepCard({ title, center, children }: { title?: ReactNode; center?: boolean; children: ReactNode }) {
  const containerStyles = center ? { alignItems: 'center' } : {};
  return (
    <Card size="xxl" padding="xxl" title={title}>
      <Container gap="xl" style={{ ...containerStyles, width: '100%' }}>
        {children}
      </Container>
    </Card>
  );
}

export function CLISetupPage() {
  const { t } = useMimicTranslation('cliSetup');
  const { t: globalT } = useMimicTranslation('global');
  const { step, nextStep, prevStep } = useMgmtPlaneStateStore();
  const steps: string[] = [t('step1.stepName'), t('step2.stepName'), t('step3.stepName')];
  const currentStep = step - 1;

  const navigate = useNavigate();
  const skipSetup = () => navigate('/activity');
  const finishSetup = () => navigate('/activity');

  const [message] = useMessage({ namespace: 'bottom' });

  const cliCommand = t('step2.command');
  // TODO: generate this token
  const authToken = 'abcdefghijklmnop';

  const copyText = (text: string) => {
    navigator.clipboard.writeText(text).catch(() => {
      message.error(t('failedCopy'));
    });
  };

  const showMessage = (content: string) => {
    return message.feedback(content);
  };

  const copyCommandToClipboard = async () => {
    await copyText(cliCommand);
    await showMessage(t('step2.commandCopied'));
  };

  const copyTokenToClipboard = async () => {
    await copyText(authToken);
    await showMessage(t('step3.tokenCopied'));
  };

  if (step === Step.Welcome) {
    return (
      <StepCard title={<HeroTitle>{t('step0.title')}</HeroTitle>}>
        <BodyText>{t('step0.body')}</BodyText>
        <Button type="primary" style={{ alignSelf: 'stretch' }} onClick={nextStep}>
          {t('step0.start')} <ArrowRightOutlined />
        </Button>
        <Button type="link" style={{ alignSelf: 'stretch' }} onClick={skipSetup}>
          {t('skipSetup')}
        </Button>
      </StepCard>
    );
  }
  if (step === Step.Download) {
    return (
      <StepCard title={<HeaderTitle>{t('title')}</HeaderTitle>}>
        <Steps steps={steps} step={currentStep} />
        <Container gap="xxs">
          <Subtitle>
            {t('step1.stepNumber')} {t('step1.stepName')}
          </Subtitle>
          <BodyText>{t('step1.body')}</BodyText>
        </Container>
        <Container horizontal gap="md" style={{ alignSelf: 'stretch' }}>
          <Button type="primary" style={{ flexGrow: 1 }}>
            {t('step1.downloadLinux')}
          </Button>
          <Button type="primary" style={{ flexGrow: 1 }}>
            {t('step1.downloadWindows')}
          </Button>
        </Container>
        <Button type="default" style={{ alignSelf: 'stretch' }} onClick={nextStep}>
          {globalT('next')} <ArrowRightOutlined />
        </Button>
      </StepCard>
    );
  }
  if (step === Step.Verify) {
    return (
      <StepCard title={<HeaderTitle>{t('title')}</HeaderTitle>}>
        <Steps steps={steps} step={currentStep} />
        <Container gap="xs">
          <Subtitle>
            {t('step2.stepNumber')} {t('step2.stepName')}
          </Subtitle>
          <BodyText>{t('step2.body')}</BodyText>
          <BodyText>
            <Code>{cliCommand}</Code>
            <Button type="link" onClick={copyCommandToClipboard}>
              <CopyOutlined /> {globalT('copy')}
            </Button>
          </BodyText>
          <BodyText>
            {t('step2.body2')} <Code>{t('step2.commandSuccess')}</Code>.
          </BodyText>
        </Container>
        <Container horizontal style={{ justifyContent: 'space-between', alignSelf: 'stretch' }}>
          <Button type="text" onClick={prevStep}>
            <ArrowLeftOutlined /> {globalT('back')}
          </Button>
          <Button type="primary" onClick={nextStep}>
            {globalT('next')} <ArrowRightOutlined />
          </Button>
        </Container>
      </StepCard>
    );
  }
  if (step === Step.Authenticate) {
    return (
      <StepCard title={<HeaderTitle>{t('title')}</HeaderTitle>}>
        <Steps steps={steps} step={currentStep} />
        <Container gap="xxs" style={{ alignSelf: 'stretch' }}>
          <Subtitle>
            {t('step3.stepNumber')} {t('step3.stepName')}
          </Subtitle>
          <BodyText>{t('step3.body')}</BodyText>
          <Space.Compact style={{ alignSelf: 'flex-start' }}>
            <Input
              value={authToken}
              suffix={
                <Tooltip title="TODO">
                  <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                </Tooltip>
              }
            />
            <Button type="default" onClick={copyTokenToClipboard}>
              <CopyOutlined /> {globalT('copy')}
            </Button>
          </Space.Compact>
        </Container>
        <Container horizontal style={{ justifyContent: 'space-between', alignSelf: 'stretch' }}>
          <Button type="text" onClick={prevStep}>
            <ArrowLeftOutlined /> {globalT('back')}
          </Button>
          <Button type="primary" onClick={nextStep}>
            {globalT('finish')}
          </Button>
        </Container>
      </StepCard>
    );
  }
  if (step === Step.Complete) {
    const checkColor = '#5C911C';
    const checkSize = '104px';
    return (
      <StepCard center>
        <CheckCircleFilled style={{ fontSize: checkSize, color: checkColor }} />
        <Container style={{ alignItems: 'center' }}>
          <HeaderTitle>{t('step4.title')}</HeaderTitle>
          <BodyText>
            {t('step4.body')}
            &nbsp;
            <NavLink target="_blank" to="/mimic-cli/download">
              {t('step4.settingsCliSetup')}
            </NavLink>
          </BodyText>
        </Container>
        <Button type="primary" onClick={finishSetup}>
          {t('step4.continue')} <ArrowRightOutlined />
        </Button>
      </StepCard>
    );
  }
}
