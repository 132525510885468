/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { ArrowRightOutlined, CloseOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import { useEffect, useState } from 'react';

import { ConfigRevision, Node, NodeConfig } from '@/client';
import { CardCollapse } from '@/components/CardCollapse';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Button } from '@/primitives/Button';

import './ConfigWizardOverlay.css';
import { ConfigWizardOverlayFooter } from './ConfigWizardOverlayFooter';
import { ConfigWizardOverlayNodeDetails } from './ConfigWizardOverlayNodeDetails';
import { SelectConfigRevisionCard } from './SelectConfigRevisionCard';
import { SelectedConfigRevisionCard } from './SelectedConfigRevisionCard';
import { SelectedNodeConfigCard } from './SelectedNodeConfigCard';
import { SelectNodeConfigCard } from './SelectNodeConfigCard';

type StepsNames = 'selectConfiguration' | 'selectRevision' | 'reviewConfigurations';

type Steps = {
  [key in StepsNames]: {
    overlayFooter: JSX.Element;
    overlayBody: JSX.Element;
  };
};

export type ConfigWizardOverlayProps = {
  node?: Node;
  defaultNodeConfig?: NodeConfig;
  defaultConfigRevision?: ConfigRevision;
  onCancel: () => void;
  onAssign: (node: Node, configRevision: ConfigRevision) => void;
};

export function ConfigWizardOverlay({
  node,
  defaultNodeConfig,
  defaultConfigRevision,
  onAssign,
  onCancel,
}: ConfigWizardOverlayProps) {
  const { t } = useMimicTranslation('configWizardOverlay');
  const [currentStep, setCurrentStep] = useState<StepsNames>(
    defaultNodeConfig ? 'selectRevision' : 'selectConfiguration',
  );
  const [selectedNodeConfig, setSelectedNodeConfig] = useState<NodeConfig | undefined>(defaultNodeConfig);
  const [selectedNodeConfigRevision, setSelectedNodeConfigRevision] = useState<ConfigRevision | undefined>(
    defaultConfigRevision,
  );

  const goToStep1 = () => {
    setCurrentStep('selectConfiguration');
    setSelectedNodeConfigRevision(undefined);
  };

  // reset overlay state when node changes
  useEffect(() => {
    setCurrentStep(defaultNodeConfig ? 'selectRevision' : 'selectConfiguration');
    setSelectedNodeConfigRevision(defaultConfigRevision);
  }, [node, defaultNodeConfig, defaultConfigRevision]);

  if (!node) return null;

  const goToStep2 = () => setCurrentStep('selectRevision');
  const goToStep3 = () => setCurrentStep('reviewConfigurations');

  const onNodeConfigSelect = (nodeConfig?: NodeConfig) => {
    setSelectedNodeConfig(nodeConfig);
    setSelectedNodeConfigRevision(undefined);
  };

  const stepsDetails: Steps = {
    selectConfiguration: {
      overlayFooter: (
        <ConfigWizardOverlayFooter
          onCancel={onCancel}
          onPrevious={undefined}
          onNext={goToStep2}
          onNextDisabled={!selectedNodeConfig}
          onNextLabel={t('next')}
          onNextIcon={<ArrowRightOutlined />}
        />
      ),
      overlayBody: (
        <SelectNodeConfigCard
          node={node}
          selectedNodeConfig={selectedNodeConfig}
          onNodeConfigSelect={onNodeConfigSelect}
        />
      ),
    },
    selectRevision: {
      overlayFooter: (
        <ConfigWizardOverlayFooter
          onCancel={onCancel}
          onPrevious={goToStep1}
          onNext={goToStep3}
          onNextDisabled={!selectedNodeConfigRevision}
          onNextLabel={t('next')}
          onNextIcon={<ArrowRightOutlined />}
        />
      ),
      overlayBody: (
        <CardCollapse>
          <SelectedNodeConfigCard onChangeClick={goToStep1} nodeConfig={selectedNodeConfig!} />
          <SelectConfigRevisionCard
            node={node}
            nodeConfig={selectedNodeConfig!}
            selectedConfigRevision={selectedNodeConfigRevision}
            onConfigRevisionSelect={setSelectedNodeConfigRevision}
          />
        </CardCollapse>
      ),
    },
    reviewConfigurations: {
      overlayFooter: (
        <ConfigWizardOverlayFooter
          onCancel={onCancel}
          onPrevious={goToStep2}
          onNext={() => {
            onAssign(node, selectedNodeConfigRevision!);
          }}
          onNextDisabled={false}
          onNextLabel={t('assign')}
          onNextIcon={<ArrowRightOutlined />}
        />
      ),
      overlayBody: (
        <CardCollapse>
          <SelectedNodeConfigCard onChangeClick={goToStep1} nodeConfig={selectedNodeConfig!} />
          <SelectedConfigRevisionCard configRevision={selectedNodeConfigRevision!} onChangeClick={goToStep2} />
        </CardCollapse>
      ),
    },
  };
  return (
    <Drawer
      placement="top"
      title={t(currentStep)}
      open={!!node}
      height="100vh"
      onClose={onCancel}
      closeIcon={null}
      extra={<CloseModalButton onClick={onCancel} />}
      footer={stepsDetails[currentStep].overlayFooter}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <ConfigWizardOverlayNodeDetails node={node} />
        {stepsDetails[currentStep].overlayBody}
      </div>
    </Drawer>
  );
}

function CloseModalButton({ onClick }: { onClick: () => void }) {
  return (
    <Button dataTestId="close-config-wizard-overlay" type="text" onClick={onClick}>
      <CloseOutlined />
    </Button>
  );
}
