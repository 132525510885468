/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useMutation } from '@tanstack/react-query';

import { PatchTag, ApiError, NodeTagResponse } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export type UpdateTagMutation = PatchTag & { id: string };

export type AddTagMutationConfig = {
  onSuccess?: (data: NodeTagResponse, params: AddNodeTag) => void;
  onError?: (error: ApiError, params: AddNodeTag) => void;
  onSettled?: (data: NodeTagResponse | undefined, error: ApiError | null, params: AddNodeTag) => void;
  onMutate?: (params: AddNodeTag) => Promise<unknown> | void;
};

export type AddNodeTag = {
  nodeId: string;
  tagName: string;
};

/**
 * Hook for adding tag to node by ID through the management plane API.
 *
 * @param config.onSuccess - Called after successful adding tag update
 * @param config.onError - Called if adding fails
 * @param config.onSettled - Called after mutation completes (success or failure)
 *
 * @returns React Query mutation result for adding tag to a node
 *
 * @example
 * ```tsx
 * const { mutate } = useAddTagMutation({
 *   onSuccess: (data) => console.log('Tag added:', data),
 *   onError: (error) => console.error('Tag added failed:', error)
 * });
 *
 * // Add Tag
 * mutate({ nodeId: '9fb763f7-33de-4969-a7a0-b808f4637318', tagName: 'test-tag-0001' });
 * ```
 */

export function useAddTagMutation({ onSuccess, onError, onSettled, onMutate }: AddTagMutationConfig) {
  const tenantId = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  const { mutateAsync, isError, error } = useMutation<NodeTagResponse, ApiError, AddNodeTag>({
    mutationFn: (params: AddNodeTag) => {
      return mgmtPlaneAPIClient.nodes.addNodeTag({
        tenantId: tenantId!,
        id: params.nodeId,
        tagName: params.tagName,
      });
    },
    onSuccess,
    onError,
    onSettled,
    onMutate,
  });

  return { mutateAsync, isError, error };
}
