/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { StopOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';

import { User } from '@/client';
import { RelativeTime } from '@/components/RelativeTime';
import { canPerformAction } from '@/hooks/useAuthorization';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Button } from '@/primitives/Button';
import { Space } from '@/primitives/Space';

export function useRemovedUsersColumns(
  currentUser: User,
  onChangeRemoved: (user: User, removed: boolean) => void,
): ColumnsType<User> {
  const { t } = useMimicTranslation('members');

  const removedUsersColumns: ColumnsType<User> = [
    {
      title: t('member'),
      dataIndex: 'displayName',
      key: 'displayName',
      render: (_, record) => {
        if (record.removed) {
          return (
            <Space minHeight="40px">
              <StopOutlined />
              {record.displayName}
            </Space>
          );
        }
        return record.displayName;
      },
    },
    {
      title: t('email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('columns.role'),
      dataIndex: 'role',
      key: 'role',
      render: (role: User['role']) => t(`roles.${role}`),
    },
    {
      title: t('columns.dateCreated'),
      dataIndex: 'dateCreated',
      key: 'dateCreated',
      render: (dateCreated: User['dateCreated']) => <RelativeTime date={dateCreated} />,
    },
  ];

  if (!canPerformAction('admin', currentUser)) return removedUsersColumns;

  return [
    ...removedUsersColumns,
    {
      title: '',
      dataIndex: '',
      key: 'actions',
      align: 'right',
      render: (_, record) => {
        return (
          <Button type="default" onClick={() => onChangeRemoved(record, false)} dataTestId="restore-button">
            {t('actions.restore')}
          </Button>
        );
      },
    },
  ];
}
