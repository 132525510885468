/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { CountLabel } from '@/components/CountLabel';
import { PageHeader } from '@/components/PageHeader';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Segmented, SegmentedValue } from '@/primitives/Segmented';

// eslint-disable-next-line react-refresh/only-export-components
export enum NodeFilter {
  CONFIGURED = 'configured',
  PENDING = 'pending',
}

export type NodesHeaderProps = {
  nodesState: NodeFilter.CONFIGURED | NodeFilter.PENDING;
  setNodeState: (nodeState: NodeFilter) => void;
  configuredCount: number | undefined;
  pendingCount: number | undefined;
  configuredNodesIsLoading: boolean;
  pendingNodesIsLoading: boolean;
};

export function NodesHeader({
  nodesState,
  setNodeState,
  configuredCount,
  pendingCount,
  configuredNodesIsLoading,
  pendingNodesIsLoading,
}: NodesHeaderProps) {
  const { t } = useMimicTranslation('nodes');

  const onChangeActiveFilter = (value: SegmentedValue) => {
    if (value === NodeFilter.CONFIGURED) {
      setNodeState(NodeFilter.CONFIGURED);
    } else if (value === NodeFilter.PENDING) {
      setNodeState(NodeFilter.PENDING);
    }
  };

  const activeFilterValue = nodesState === NodeFilter.CONFIGURED ? NodeFilter.CONFIGURED : NodeFilter.PENDING;

  return (
    <PageHeader title={t('title')} level={2}>
      <Segmented
        dataTestId="pending-switcher"
        defaultValue={NodeFilter.CONFIGURED}
        value={activeFilterValue}
        onChange={onChangeActiveFilter}
        options={[
          {
            label: (
              <CountLabel
                text={t('configured')}
                count={configuredCount!}
                loading={configuredNodesIsLoading}
                data-testid="segment-confirmed"
              />
            ),
            value: NodeFilter.CONFIGURED,
          },
          {
            label: (
              <CountLabel
                text={t('pending')}
                count={pendingCount!}
                loading={pendingNodesIsLoading}
                data-testid="segment-pending"
              />
            ),
            value: NodeFilter.PENDING,
          },
        ]}
      />
    </PageHeader>
  );
}
