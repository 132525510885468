/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { SyncOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { Space } from 'antd';
import { useState } from 'react';

import { PageHeader } from '@/components/PageHeader';
import { RelativeTime } from '@/components/RelativeTime';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Button } from '@/primitives/Button';
import { Text } from '@/primitives/Text';

export function ActivityHeader() {
  const { t } = useMimicTranslation('activity');
  const [dateUpdated, setDateUpdated] = useState(new Date());
  const queryClient = useQueryClient();

  const updateData = () => {
    setDateUpdated(new Date());
    queryClient.invalidateQueries({ queryKey: ['activities'] });
  };

  return (
    <PageHeader
      title={t('title')}
      level={2}
      text={
        <>
          <Text>
            {t('lastUpdated')} <RelativeTime date={dateUpdated} />
          </Text>
          <Space.Compact size="small" style={{ fontSize: 'inherit' }}>
            <Button type="link" onClick={updateData}>
              <SyncOutlined /> {t('actions.refresh')}
            </Button>
          </Space.Compact>
        </>
      }
    />
  );
}
