/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { ArchType, OsType } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { ContentContainer } from '@/components/ContentContainer';
import { useMessage } from '@/hooks/message';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { CLIDownload } from '@/pages/CLIDownload/CLIDownload';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export function CLIDownloadPage() {
  const { t } = useMimicTranslation('cliSetup');
  const tenantId = useMgmtPlaneStateStore((state) => state.selectedTenantID);
  const [message] = useMessage();

  const onDownload = async (osType: OsType, archType: ArchType = 'x86_64') => {
    // TODO: Give the user the ability to select the architecture type of the
    // CLI in addition to its operating system type.
    try {
      const { data: cliDownload } = await mgmtPlaneAPIClient.cli.cliDownload({
        tenantId: tenantId!,
        archType,
        osType,
      });

      message.success(
        t('download.start', {
          osType: t(`download.${cliDownload.OsType}`),
          archType: t(`download.${cliDownload.ArchType}`),
        }),
      );

      window.open(cliDownload.downloadUrl);
    } catch (error) {
      message.error(
        t('download.error', {
          osType: t(`download.${osType}`),
          archType: t(`download.${archType}`),
        }),
      );
    }
  };

  return (
    <ContentContainer>
      <CLIDownload onDownload={onDownload} tenantID={tenantId!} />
    </ContentContainer>
  );
}
