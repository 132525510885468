/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import type { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';

import { NodeHallmark } from '@/client';
import { PaginatedTable } from '@/components/PaginatedTable';
import { QueryWrapper } from '@/components/QueryWrapper';
import { usePaginatedNodeHallmarksQuery } from '@/hooks/query/usePaginatedNodeHallmarksQuery';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';

export type HallmarksProps = {
  nodeId: string;
};

export function Hallmarks({ nodeId }: HallmarksProps) {
  const { t } = useMimicTranslation('node');
  const hallmarksQuery = usePaginatedNodeHallmarksQuery({ params: { nodeId }, type: 'queryParams' });

  const columns: ColumnsType<NodeHallmark> = [
    {
      title: t('hallmarksTable.mimicID'),
      dataIndex: 'mimicID',
      key: 'mimicID',
    },
    {
      title: t('hallmarksTable.mimicDescription'),
      dataIndex: 'mimicDescription',
      key: 'mimicDescription',
    },
    {
      title: t('hallmarksTable.occurrences'),
      dataIndex: 'occurrences',
      key: 'occurrences',
    },
    {
      title: t('hallmarksTable.firstSeen'),
      dataIndex: 'firstSeen',
      key: 'firstSeen',
      render: (date: string) => dayjs(date).utc().format('DD MMM YYYY HH:mm:ss'),
    },
    {
      title: t('hallmarksTable.lastSeen'),
      dataIndex: 'lastSeen',
      key: 'lastSeen',
      render: (date: string) => dayjs(date).utc().format('DD MMM YYYY HH:mm:ss'),
    },
  ];

  return (
    <QueryWrapper queryResult={hallmarksQuery}>
      {(hallmarksData) => (
        <PaginatedTable
          data={hallmarksData}
          columns={columns}
          rowKey={(hallmark) => hallmark.mimicID}
          style={{ width: '100%' }}
          onParamsChange={hallmarksQuery.updateQueryParams}
        />
      )}
    </QueryWrapper>
  );
}
