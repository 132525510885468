/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { useParams } from 'react-router-dom';
import { validate as uuidValidate } from 'uuid';

import { HTTPError } from '@/components/HTTPError';
import { JobDetailsPage } from '@/pages/JobDetails/JobDetailsPage';

export function JobDetailsRoute() {
  const { jobId } = useParams<'jobId'>();

  if (!jobId || !uuidValidate(jobId)) {
    return <HTTPError httpStatus="404" />;
  }

  return <JobDetailsPage jobId={jobId} />;
}
