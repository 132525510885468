/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { ContentContainer } from '@/components/ContentContainer';
import { PageHeader } from '@/components/PageHeader';
import { QueryWrapper } from '@/components/QueryWrapper';
import { useSystemInfoQuery } from '@/hooks/query/useSystemInfoQuery';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Container } from '@/primitives/Container';
import { Flex } from '@/primitives/Flex';
import { Text } from '@/primitives/Text';

export function SystemInfoPage() {
  const { t } = useMimicTranslation('systemInfo');

  const systemInfoQuery = useSystemInfoQuery();

  return (
    <ContentContainer>
      <Container>
        <PageHeader title={t('title')} />
        <Flex gap="xxs" vertical>
          <Text size="md" strong>
            {t('mgmtPlaneVersion')}
          </Text>
          <QueryWrapper queryResult={systemInfoQuery}>
            {(systemInfoData) => (
              <Text copyable mono data-testid="management-plane-release">
                {systemInfoData.data.mgmtPlaneRelease}
              </Text>
            )}
          </QueryWrapper>
        </Flex>
      </Container>
    </ContentContainer>
  );
}
