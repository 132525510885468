/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { ColumnsType } from 'antd/es/table';

import { Activity } from '@/client';
import { ConnectivityTime } from '@/components/States/ConnectivityTime';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Badge } from '@/primitives/Badge';
import { Link } from '@/primitives/Link';
import { badge, tokens } from '@/theme';

export function useActivitiesColumns(tenantID: string): ColumnsType<Activity> {
  const { t } = useMimicTranslation('activity');

  const columns: ColumnsType<Activity> = [
    {
      title: t('table.hostName'),
      dataIndex: 'nodeName',
      key: 'nodeName',
      render: (nodeName: string, record: Activity) => (
        <Link
          to={`/tenants/${tenantID}/nodes/${record.nodeID}`}
          dataTestId="node-link"
          style={{ fontSize: 'inherit', fontFamily: 'DM Mono' }}
        >
          {nodeName || t('notAvailable')}
        </Link>
      ),
    },
    {
      title: t('table.tags'),
      dataIndex: 'tags',
      key: 'tags',
    },
    {
      title: t('table.detection'),
      dataIndex: 'hallmarkCount',
      key: 'hallmarkCount',
      render: (count: number) => <Badge text={count} bgColor={badge.badgeYellowSolidBg} />,
    },
    {
      title: t('table.deflections'),
      dataIndex: 'deflectionsCount',
      key: 'deflectionsCount',
      render: (count) => {
        const bgColor = count > 0 ? badge.badgeRedSolidBg : badge.badgeDarkGraySubtleBg;
        const color = count > 0 ? tokens.color.text.textInverse : tokens.color.text.textDefault;
        const label = count > 0 ? t('table.deflectionsActive') : t('table.deflectionsInactive');
        return <Badge dataTestId="deflections-info" text={label} bgColor={bgColor} color={color} />;
      },
    },
    {
      title: t('table.connectivity'),
      dataIndex: 'lastSeen',
      key: 'lastSeen',
      render: (_, record) => {
        return (
          <ConnectivityTime
            state={record.nodeConnectivity}
            lastSeen={record.lastSeen}
            heartbeatStates={record.nodeHeartbeatStates}
          />
        );
      },
    },
  ];

  return columns;
}
