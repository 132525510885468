/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { ArrowRightOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { FormEventHandler, useEffect, useState } from 'react';

import { validationErrors } from '@/components/Forms/validationErrors';
import { MimicLogo } from '@/components/MimicLogo';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Button } from '@/primitives/Button';
import { Card } from '@/primitives/Card';
import { Input } from '@/primitives/Input';
import { Space } from '@/primitives/Space';
import { Text } from '@/primitives/Text';
import { Title } from '@/primitives/Title';

export type LogInProps = {
  triggerLogIn: (emailAddress: string) => void;
  accountNotFound: string | null;
  emailMismatch: string | null;
  unexpectedError: boolean;
};

type LoginFormFields = {
  email: string;
};

export function LogIn({ triggerLogIn, accountNotFound, emailMismatch, unexpectedError }: LogInProps) {
  const { t } = useMimicTranslation('logIn');
  const [submitEnabled, setSubmitEnabled] = useState<boolean>(false);
  const [form] = Form.useForm();

  const emailAddress = Form.useWatch('email', form);

  useEffect(() => {
    setSubmitEnabled(!!emailAddress);
  }, [emailAddress]);

  useEffect(() => {
    if (accountNotFound) {
      form.setFields(validationErrors<LoginFormFields>({ email: t('accountNotFound') }));
      form.setFieldValue('email', accountNotFound);
    }
    if (emailMismatch) {
      form.setFields(validationErrors<LoginFormFields>({ email: t('emailMismatch') }));
      form.setFieldValue('email', emailMismatch);
    }
    if (unexpectedError) {
      form.setFields(validationErrors<LoginFormFields>({ email: t('unexpectedError') }));
      form.setFieldValue('email', '');
    }
  }, [accountNotFound, emailMismatch, unexpectedError, form, t]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = () => {
    triggerLogIn(emailAddress);
  };

  return (
    <Card size="xl" padding="xxl">
      <Space dir="vertical" size="lg" width="100%">
        <MimicLogo width="8rem" height="3rem" />
        <Space dir="vertical" size="sm">
          <Title style={{ margin: 0 }}>{t('welcome')}</Title>
          <Text size="xl" lineHeight="1.75rem">
            {t('instructions')}
          </Text>
        </Space>
        <Form form={form} onFinish={handleSubmit} autoComplete="off">
          <Form.Item name="email" initialValue={emailAddress} style={{ marginBottom: '1.5rem' }}>
            <Input
              placeholder={t('email')}
              autoFocus
              dataTestId="email-input"
              style={{
                padding: '0.5625rem 0.875rem',
                fontSize: '1.1875rem',
                lineHeight: '1.75rem',
                backgroundColor: '#ffffff',
              }}
              allowClear
            />
          </Form.Item>
          <Button
            className="login-button"
            type="primary"
            htmlType="submit"
            dataTestId="trigger-log-in"
            block
            style={{ height: '48px' }}
            disabled={!submitEnabled}
          >
            {t('continue')}
            <ArrowRightOutlined />
          </Button>
        </Form>
      </Space>
    </Card>
  );
}
