/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { ColumnsType } from 'antd/es/table';

import { User } from '@/client';
import { RelativeTime } from '@/components/RelativeTime';
import { canPerformAction } from '@/hooks/useAuthorization';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { MemberActionDropdown } from '@/pages/Members/MemberActionDropdown';
import { Role } from '@/pages/Members/MemberTable';
import { Space } from '@/primitives/Space';

export function useActiveUsersColumns(
  currentUser: User,
  onUpdateClick: (user: User) => void,
  onChangeRole: (user: User, role: Role) => void,
  onChangeRemoved: (user: User, removed: boolean) => void,
): ColumnsType<User> {
  const { t } = useMimicTranslation('members');

  const activeUsersColumns: ColumnsType<User> = [
    {
      title: t('member'),
      dataIndex: 'displayName',
      key: 'displayName',
    },
    {
      title: t('email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('columns.role'),
      dataIndex: 'role',
      key: 'role',
      render: (role: User['role']) => t(`roles.${role}`),
    },
    {
      title: t('columns.dateCreated'),
      dataIndex: 'dateCreated',
      key: 'dateCreated',
      render: (dateCreated: User['dateCreated']) => (
        <Space minHeight="40px">
          <RelativeTime date={dateCreated} />
        </Space>
      ),
    },
  ];

  if (!canPerformAction('admin', currentUser)) return activeUsersColumns;

  return [
    ...activeUsersColumns,
    {
      title: '',
      dataIndex: '',
      key: 'actions',
      render: (_, record) => {
        return (
          <MemberActionDropdown
            user={record}
            currentUser={currentUser}
            onUpdateClick={onUpdateClick}
            onChangeRole={onChangeRole}
            onChangeRemoved={onChangeRemoved}
          />
        );
      },
    },
  ];
}
