/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { z } from 'zod';

type SupportedFilterType = boolean | string | number | Array<string> | Array<number>;
type Filter = Record<string, SupportedFilterType>;

// Note: since we can't infer types of each parameter we must
// have a type map to parse correctly.
// All filter fields are assumed to be nullable and will be undefined
// if not present in query params.
export function getFilters<T>(searchParams: URLSearchParams, types: TypeMap<T>, scope?: string): T {
  const filters: Filter = {};

  Object.entries<QueryParamTypes>(types).forEach(([k, type]) => {
    const filtersFields = scope ? `${scope}Filters` : 'filters';
    const val = searchParams.get(`${filtersFields}[${k}]`);
    if (val != null) {
      switch (type) {
        case 'boolean':
          filters[k] = val === 'true';
          break;
        case 'number':
          filters[k] = Number(val);
          break;
        case 'string':
          filters[k] = val;
          break;
        default:
          break;
      }
    }
  });

  return filters as T;
}

export function setFilters<T extends object>(searchParams: URLSearchParams, filters: T, scope?: string): void {
  const f = filters as Filter;
  const filtersFields = scope ? `${scope}Filters` : 'filters';

  Object.keys(filters).forEach((k) => {
    const val = f[k];
    const emptyArray = Array.isArray(val) && val.length === 0;
    if (val !== null && val !== '' && val !== undefined && !emptyArray) {
      searchParams.set(`${filtersFields}[${k}]`, String(f[k]));
    } else {
      searchParams.delete(`${filtersFields}[${k}]`);
    }
  });
}

export type QueryParamTypes = 'string' | 'boolean' | 'number';
export type TypeMap<T> = { [key in keyof T]-?: QueryParamTypes };

export function defineTypes<T>(types: TypeMap<T>): TypeMap<T> {
  return types;
}

export function zodToQueryParamType(zodType: Zod.ZodType): QueryParamTypes {
  // Type assertion to access the internal typeName
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-underscore-dangle
  const { typeName } = zodType._def as any;

  if (typeName === 'ZodOptional') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return zodToQueryParamType((zodType as unknown as z.ZodOptional<any>).unwrap());
  }

  switch (typeName) {
    case 'ZodString':
      return 'string';
    case 'ZodNumber':
      return 'number';
    case 'ZodBoolean':
      return 'boolean';
    case 'ZodEnum':
      return 'string';
    default:
      throw new Error(`Unsupported type: ${typeName}`);
  }
}

export function zodSchemaToTypeMap<T>(shape: Record<string, unknown>): TypeMap<T> {
  return Object.fromEntries(
    Object.entries(shape).map(([key, value]) => [key, zodToQueryParamType(value as Zod.ZodType)]),
  ) as TypeMap<T>;
}
