/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { ConfigRevision, Node, NodeConfig } from '@/client';
import { ConfigRevisionsList } from '@/components/ConfigWizardOverlay/ConfigRevisionsList';
import { usePaginatedConfigRevisionsQuery } from '@/hooks/query/usePaginatedConfigRevisionsQuery';
import { Card } from '@/primitives/Card';

export type SelectConfigRevisionCardProps = {
  node: Node;
  nodeConfig: NodeConfig;
  selectedConfigRevision?: ConfigRevision;
  onConfigRevisionSelect: (nodeConfig: ConfigRevision) => void;
};

export function SelectConfigRevisionCard({
  node,
  nodeConfig,
  selectedConfigRevision,
  onConfigRevisionSelect,
}: SelectConfigRevisionCardProps) {
  const configRevisionsQuery = usePaginatedConfigRevisionsQuery({
    params: {
      configId: nodeConfig.id,
    },
  });

  const getConfigRevisionPath = (configRevision: ConfigRevision) =>
    `/tenants/${node.tenantId}/node-configs/${configRevision.nodeConfigId}/revisions/${configRevision.revisionNumber}`;

  return (
    <Card dataTestId="config-wizard-select-node-config" padding="sm" bordered>
      <ConfigRevisionsList
        onFilterAndSortChange={configRevisionsQuery.updateQueryParams}
        configRevisionsQuery={configRevisionsQuery}
        getConfigRevisionPath={getConfigRevisionPath}
        onConfigRevisionSelect={onConfigRevisionSelect}
        defaultConfigRevision={selectedConfigRevision}
      />
    </Card>
  );
}
