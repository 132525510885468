/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Node } from '@/client';
import { IncomingData } from '@/components/Node/IncomingData';
import { ConnectivityStateBadge } from '@/components/States/ConnectivityStateBadge';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';

export type ConnectivityInfoProps = {
  disabled: boolean;
  isLoading: boolean;
  connectivityState: Node['connectivityState'];
};

export function ConnectivityInfo({ disabled, isLoading, connectivityState }: ConnectivityInfoProps) {
  const { t } = useMimicTranslation('node');

  return (
    <IncomingData
      disabled={disabled}
      marginBottom={0}
      label={t('connectivity')}
      loading={isLoading}
      bold
      labelMarginBottom="8px"
    >
      <ConnectivityStateBadge disabled={disabled} state={connectivityState} size="xl" />
    </IncomingData>
  );
}
