/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { ReactNode, useState } from 'react';

import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { CopyCode } from '@/pages/CLIAuth/CopyCode';
import { GenerateToken } from '@/pages/CLIAuth/GenerateToken';
import { Code } from '@/primitives/Code';
import { Container } from '@/primitives/Container';
import { Divider } from '@/primitives/Divider';
import { FailureIcon, SuccessIcon } from '@/primitives/Icons';
import { Link } from '@/primitives/Link';
import { Text } from '@/primitives/Text';
import { Title } from '@/primitives/Title';

type GenerateCallback = () => Promise<string | undefined>;
type CopyTextCallback = (text: string) => void;

type ResultParams = {
  icon?: ReactNode;
  children?: ReactNode;
};

function Result({ icon, children }: ResultParams) {
  return (
    <Container gap="xxs">
      <Container gap="sm" horizontal>
        <Text>{icon}</Text>
        <Container gap="xxs">{children}</Container>
      </Container>
    </Container>
  );
}

export type CLIAuthParams = {
  tenantID: string;
  generateToken: GenerateCallback;
  copyToken: CopyTextCallback;
  copyCommand: CopyTextCallback;
  email: string;
};

export function CLIAuth({ tenantID, generateToken, copyToken, copyCommand, email }: CLIAuthParams) {
  const [token, setToken] = useState('');
  const { t } = useMimicTranslation('cliSetup');
  const { t: globalT } = useMimicTranslation('global');
  return (
    <Container padding="lg" gap="xl">
      <Title level={2}>{t('auth.title')}</Title>
      <Container gap="xl">
        <Container gap="sm">
          <Title level={5}>
            {t('auth.step1.number')} {t('auth.step1.name')}
          </Title>
          <Text size="xl">
            {t('auth.step1.instructions')} <CopyCode text={t('auth.step1.command')} copy={copyCommand} />
          </Text>
          <Container gap="xs">
            <Result icon={<SuccessIcon />}>
              <Text>
                {t('auth.step1.successPart1')} <Code noBackground>{t('auth.step1.successExample')}</Code>
                {t('auth.step1.successPart2')}
              </Text>
            </Result>
            <Result icon={<FailureIcon />}>
              <Text>
                {t('auth.step1.failurePart1')} <Code noBackground>{t('auth.step1.failureExample')}</Code>
                {t('auth.step1.failurePart2')}
              </Text>
              <Text data-testid="cli-download-link">
                <Link to={`/tenants/${tenantID}/mimic-cli/download`}>{t('auth.downloadLinkText')}</Link>
              </Text>
            </Result>
          </Container>
        </Container>
        <Container gap="sm">
          <Title level={5}>
            {t('auth.step2.number')} {t('auth.step2.name')}
          </Title>
          <Text size="xl">
            {t('auth.step2.instructions')} <CopyCode text={t('auth.step2.command')} copy={copyCommand} />
          </Text>
          <Text size="lg">{t('auth.step2.description')}</Text>
          <GenerateToken generate={generateToken} copy={copyToken} setToken={setToken} token={token} />
          <Container gap="sm">
            {/* Should be title xs */}
            <Text strong>{globalT('example')}</Text>
            <Code multiline dataTestId="login-command-helper">
              {t('auth.step2.example.loginCommand', { email, token })}
            </Code>
          </Container>
        </Container>
      </Container>
      <Divider />
      <Text size="lg">
        {t('auth.help.description')} <CopyCode text={t('auth.help.command')} copy={copyCommand} />
      </Text>
    </Container>
  );
}
