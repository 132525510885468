/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { SearchOutlined } from '@ant-design/icons';
import { TableProps as AntDTableProps, InputRef, TableColumnType, TablePaginationConfig } from 'antd';
import { ColumnType } from 'antd/es/table';
import { SortOrder } from 'antd/es/table/interface';
import React from 'react';

import { Pagination } from '@/client';
import { FilterDropdown } from '@/components/FilterDropdown';
import { PaginationInfo } from '@/components/Pagination/PaginationInfo';
import { Table } from '@/primitives/Table';
import { buildTablePaginator } from '@/utils/pagination';
import { UpdateParams } from '@/utils/params';

import './PaginatedTable.css';

export type PaginatedTableProps<Resource> = {
  data: PaginatedResource<Resource>;
  dark?: boolean;
  columns: AntDTableProps['columns'];
  rowKey?: AntDTableProps['rowKey'];
  onParamsChange?: UpdateParams<Resource>;
  onRow?: AntDTableProps['onRow'];
  expandable?: AntDTableProps['expandable'];
  style?: AntDTableProps['style'];
  ['data-testid']?: string;
  dataSourceMapper?: (data: Resource) => Resource;
  rowSelection?: AntDTableProps['rowSelection'];
  primaryAction?: React.ReactNode;
  selectedIds?: string[];
  selectionWarning?: string;
  selectionError?: string;
};

export type PaginatedResourceMeta = {
  page: Pagination;
  filters: object | unknown;
  sort: Array<string>;
};

export type PaginatedResource<Resource> = {
  data: Array<Resource>;
  meta?: PaginatedResourceMeta;
  pagination?: Pagination;
};

const sortDirections = ['ascend', 'descend', 'ascend'] as SortOrder[];

export function PaginatedTable<Resource>({
  data,
  dark,
  columns,
  rowKey,
  onParamsChange,
  onRow,
  expandable,
  style,
  'data-testid': testDataId,
  dataSourceMapper,
  rowSelection,
  primaryAction,
  selectedIds,
  selectionWarning,
  selectionError,
}: PaginatedTableProps<Resource>) {
  const dataSource = data.data || [];
  const sort = parseSort(data.meta?.sort).at(0);
  const onChange = buildTablePaginator(onParamsChange);
  const searchInput = React.useRef<InputRef>(null);

  const filteredColumns = data.meta?.filters || {};

  const antdColumns: AntDTableProps['columns'] = columns?.map((column: ColumnType<object>) => {
    const { dataIndex } = column;
    if (!dataIndex) return column;

    let columnWithSortAndFilter: ColumnType<object> = {
      ...column,
    };

    if (column.sorter && sort?.field === dataIndex) {
      columnWithSortAndFilter = {
        ...columnWithSortAndFilter,
        sortOrder: sort.order,
        sortDirections,
      };
    }

    if (column.filterMode === 'menu' && !column.filters) {
      columnWithSortAndFilter = {
        ...columnWithSortAndFilter,
        ...getColumnSearchProps(dataIndex, filteredColumns, searchInput),
      };
    }

    if (!!column.filterMode || !!column.filters) {
      const filteredValue = filteredColumns[dataIndex as keyof typeof filteredColumns];

      columnWithSortAndFilter = {
        ...columnWithSortAndFilter,
        filteredValue: filteredValue ? [filteredValue] : [],
      };
    }

    return columnWithSortAndFilter;
  });

  const pagination = data?.meta?.page;

  const antPagination: TablePaginationConfig = {
    current: pagination?.currentPage,
    pageSize: pagination?.pageSize,
    total: pagination?.totalItems,
    position: ['bottomLeft'],
  };

  const mapper = dataSourceMapper || ((queryData) => queryData);

  return (
    <div className="paginated-table-container">
      <PaginationInfo
        pagination={pagination}
        primaryAction={primaryAction}
        selectedIds={selectedIds}
        selectionWarning={selectionWarning}
        selectionError={selectionError}
      />
      <Table
        dark={dark}
        loading={!data}
        dataSource={dataSource.map(mapper)}
        columns={antdColumns}
        rowKey={rowKey}
        onChange={onChange}
        onRow={onRow}
        expandable={expandable}
        pagination={antPagination}
        style={{ ...style }}
        data-testid={testDataId}
        rowSelection={rowSelection}
      />
    </div>
  );
}

function parseSort(sort?: string[]) {
  if (!sort) {
    return [];
  }
  return sort.map((s) => {
    const dir = s[0];
    const field = s.slice(1);
    const order: SortOrder = dir === '+' ? 'ascend' : 'descend';
    return { field, order };
  });
}

function getColumnSearchProps<Resource>(
  dataIndex: ColumnType<Resource>['dataIndex'],
  filters: PaginatedResourceMeta['filters'],
  searchInput: React.RefObject<InputRef>,
): TableColumnType<Resource> {
  const filterKey = dataIndex as keyof typeof filters;
  // due to lists with empty filters, zod will generate an unknown type
  const filteredValue = typeof filters === 'object' && filters !== null ? filters[filterKey] : undefined;
  return {
    filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => {
      return (
        <FilterDropdown
          value={filteredValue}
          handleClose={close}
          searchInput={searchInput}
          dataIndex={dataIndex}
          handleSearch={(value?: string) => {
            if (value) setSelectedKeys([value]);
            if (!value && clearFilters) {
              setSelectedKeys([]);
              clearFilters();
            }
            confirm();
          }}
        />
      );
    },
    filterIcon: () => (
      <SearchOutlined
        data-testid={`show-filter-dropdown-${dataIndex}`}
        style={{ color: filteredValue ? '#1677ff' : undefined }}
      />
    ),
    filteredValue,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          if (searchInput.current) {
            searchInput.current.select();
          }
        }, 100);
      }
    },
    filtered: !!filteredValue,
  };
}
