/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { ColumnsType } from 'antd/es/table';

import { Notification } from '@/client';
import { ContentContainer } from '@/components/ContentContainer';
import { PageHeader } from '@/components/PageHeader';
import { PaginatedTable } from '@/components/PaginatedTable';
import { QueryWrapper } from '@/components/QueryWrapper';
import { RelativeTime } from '@/components/RelativeTime';
import { usePaginatedNotificationsQuery } from '@/hooks/query/usePaginatedNotificationsQuery';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { NotificationContent } from '@/pages/Notifications/NotificationContent';
import { Container } from '@/primitives/Container';
import { TextBadge } from '@/primitives/TextBadge';

export function NotificationsPage() {
  const { t } = useMimicTranslation('notifications');
  const notificationsQuery = usePaginatedNotificationsQuery({ type: 'queryParams' });

  const columns: ColumnsType<Notification> = [
    {
      title: t('columns.notification'),
      dataIndex: 'title',
      key: 'title',
      render: (_title: string, notification: Notification) => {
        return <NotificationContent notification={notification} />;
      },
    },
    {
      title: t('columns.urgency'),
      dataIndex: 'subjectData',
      key: 'urgency',
      align: 'left',
      render: (_, n: Notification) => {
        const urgencyLevel = urgency(n);
        if (urgencyLevel === 'critical') {
          return <TextBadge status="error">Critical</TextBadge>;
        }
        return <TextBadge status="info">Informational</TextBadge>;
      },
    },
    {
      title: t('columns.timestamp'),
      dataIndex: 'dateOfEvent',
      key: 'dateOfEvent',
      render: (dateOfEvent) => {
        return <RelativeTime date={dateOfEvent} />;
      },
    },
    {
      title: t('columns.channels'),
      dataIndex: 'subjectData',
      key: 'channels',
      render: (_, n: Notification) => {
        const urgencyLevel = urgency(n);
        if (urgencyLevel === 'critical') {
          return `External`;
        }
        return `-`;
      },
    },
  ];

  return (
    <ContentContainer>
      <Container>
        <PageHeader title={t('title')} level={2} />
        <QueryWrapper queryResult={notificationsQuery}>
          {(notificationsData) => (
            <PaginatedTable
              data={notificationsData}
              rowKey={(tag) => tag.id}
              columns={columns}
              onParamsChange={notificationsQuery.updateQueryParams}
            />
          )}
        </QueryWrapper>
      </Container>
    </ContentContainer>
  );
}

function urgency(notification: Notification) {
  if (notification.type === 'HALLMARK' || notification.type === 'DEFLECTION') {
    return 'critical';
  }
  return 'informational';
}
